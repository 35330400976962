import { Button, Container, Heading } from "@chakra-ui/react";
import React, { useState } from "react";
import { FaTrash } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { Backtest, BacktestingMetaDTO } from "../../backtesting-common-frontend";
import { runBacktest } from "../../backtesting-common-frontend/http-utilities/http-utilities/backtests/backtests-backend.service";
import { cloneDeep } from "../../backtesting-common-frontend/shared/utilites/object.utilities";
import { StatusDisplayDTO } from "../../backtesting-common-frontend/status/error-handling";
import { addBacktestQueue, setRunningBacktest, updateMessage } from "../../store/backtests/backtests";
import { AppState } from '../../store/store';

export default function BacktestQueue() {
    const dispatch = useDispatch();
    const backtestsQueue = useSelector((state: AppState) => state.backtests.backtestsQueue);
    const [ backtests, setBacktests ] = useState<Backtest[]>([]);

    React.useEffect(() => {
        setBacktests(cloneDeep(backtestsQueue));
    }, [ backtestsQueue ]);

    const runAll = async() => {
        const meta = new BacktestingMetaDTO();
        meta.calculateReturnsLast = true;
        meta.returnPortfolio = false;
        meta.takeFirstValue = false;
        meta.takeTestFromBacktest = false;
        meta.useCachedTimeSeries = [];
        const status = new StatusDisplayDTO("Queue started", "success");
        dispatch(updateMessage(status));
        dispatch(setRunningBacktest(true));
        const promises = backtests.map((backtest) => {
            return runBacktest(backtest._id, meta, true, false).catch(() => null);
        });
        dispatch(setRunningBacktest(false));
        await Promise.all(promises);
        dispatch(addBacktestQueue([]));
    };

    if (backtests.length === 0) {
        return null;
    }
    // create elements for each progress
    const progressElements = backtests.map((aProgress, index) => {
        return (
            <Container key={index} mb={2} w={'full'} p={4}>
                <Heading size="sm" color="white.500" mb={2}>
                   #{index.toString()} {aProgress.name}
                    <FaTrash onClick={() => {
                        const newBacktests = backtests.filter((_, i) => i !== index);
                        dispatch(addBacktestQueue(newBacktests));
                        dispatch(updateMessage(new StatusDisplayDTO("Removed backtest from queue", "success")));
                    }} />
                </Heading>
            </Container>
        );
    });
    return (
        <div style={{ width: '50vh', 
            position: 'fixed', // Changed from 'absolute' to 'fixed'
            bottom: '0px', // Changed from calculating top to simply setting bottom to 0
            left: '0px', // Keep as is to stay at the right
            backgroundColor: '#3b3831', 
            zIndex: 1000000000,
        }}>
            {progressElements}
            <Button m={4} onClick={runAll}>Run all</Button>
        </div>
    );
}
