import { ChakraProvider, ColorModeProvider, extendTheme } from '@chakra-ui/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

import { MathJaxContext } from 'better-react-mathjax';
import { MultiSelectTheme } from 'chakra-multiselect';
import { Provider } from 'react-redux';
import Navigation from './navigation';
import store from './store/store';

const theme = extendTheme({
    components: {
        MultiSelect: MultiSelectTheme,
        //     // Button: {
        //     //     defaultProps: {
        //     //         size: 'xl',
        //     //         fontSize: 'xl',
        //     //     },
        //     // },
        //     // Input: {
        //     //     defaultProps: {
        //     //         size: 'xl',
        //     //     },
        //     // },
        //     Select: {
        //         defaultProps: {
        //             size: 'xl',
        //         },
        //     },
        //     // Textarea: {
        //     //     defaultProps: {
        //     //         size: 'xl',
        //     //     },
        //     // },
        Box: {
            defaultProps: {
                size: 'xl',
            },
        },
        Container: {
            defaultProps: {
                size: 'xl',
            },
        },
        //     Accordion: {
        //         defaultProps: {
        //             size: 'xl',
        //         },
        //     },
        //     Alert: {
        //         defaultProps: {
        //             size: 'xl',
        //         },
        //     },
        //     Avatar: {
        //         defaultProps: {
        //             size: 'xl',
        //         },
        //     },
        //     Badge: {
        //         defaultProps: {
        //             size: 'xl',
        //         },
        //     },
        //     // Checkbox: {
        //     //     defaultProps: {
        //     //         size: 'xl',
        //     //     },
        //     // },
        //     // CloseButton: {
        //     //     defaultProps: {
        //     //         size: 'xl',
        //     //     },
        //     // },
        //     Code: {
        //         defaultProps: {
        //             size: 'xl',
        //         },
        //     },
        //     Divider: {
        //         defaultProps: {
        //             size: 'xl',
        //         },
        //     },
        //     Drawer: {
        //         defaultProps: {
        //             size: 'xl',
        //         },
        //     },
        //     Editable: {
        //         defaultProps: {
        //             size: 'xl',
        //         },
        //     },
        //     FormControl: {
        //         defaultProps: {
        //             size: 'xl',
        //         },
        //     },
        //     FormLabel: {
        //         defaultProps: {
        //             size: 'xl',
        //         },
        //     },
        //     Heading: {
        //         defaultProps: {
        //             size: 'xl',
        //         },
        //     },
        //     IconButton: {
        //         defaultProps: {
        //             size: 'xl',
        //         },
        //     },
        //     Kbd: {
        //         defaultProps: {
        //             size: 'xl',
        //         },
        //     },
        //     Link: {
        //         defaultProps: {
        //             size: 'xl',
        //         },
        //     },
        //     List: {
        //         defaultProps: {
        //             size: 'xl',
        //         },
        //     },
        //     Menu: {
        //         defaultProps: {
        //             size: 'xl',
        //         },
        //     },
        //     Modal: {
        //         defaultProps: {
        //             size: 'xl',
        //         },
        //     },
        //     NumberInput: {
        //         defaultProps: {
        //             size: 'xl',
        //         },
        //     },
        //     PinInput: {
        //         defaultProps: {
        //             size: 'xl',
        //         },
        //     },
        //     Popover: {
        //         defaultProps: {
        //             size: 'xl',
        //         },
        //     },
        //     Progress: {
        //         defaultProps: {
        //             size: 'xl',
        //         },
        //     },
        //     // Radio: {
        //     //     defaultProps: {
        //     //         size: 'xl',
        //     //     },
        //     // },
        //     Skeleton: {
        //         defaultProps: {
        //             size: 'xl',
        //         },
        //     },
        //     Slider: {
        //         defaultProps: {
        //             size: 'xl',
        //         },
        //     },
        //     Spinner: {
        //         defaultProps: {
        //             size: 'xl',
        //         },
        //     },
        //     Stat: {
        //         defaultProps: {
        //             size: 'xl',
        //         },
        //     },
        //     Switch: {
        //         defaultProps: {
        //             size: 'xl',
        //         },
        //     },
        // Table: {
        //     defaultProps: {
        //         size: 'xl',
        //     },
        // },
    //     Tabs: {
    //         defaultProps: {
    //             size: 'xl',
    //         },
    //     },
    //     Tag: {
    //         defaultProps: {
    //             size: 'xl',
    //         },
    //     },
    //     Tooltip: {
    //         defaultProps: {
    //             size: 'xl',
    //         },
    //     },
    },
    // breakpoints: {
    //     sm: '300px',
    //     md: '5000px',
    //     lg: '6000px',
    //     xl: '5000px',
    //     '2xl': '6020px',
    // },
    
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <MathJaxContext>
        <ChakraProvider theme={theme}>
            <ColorModeProvider 
                options={{
                    initialColorMode: 'dark',
                }}>
                <Provider store={store}>
                    <React.StrictMode>
                        <Navigation />
                    </React.StrictMode>
                </Provider>
            </ColorModeProvider>
        </ChakraProvider>
    </MathJaxContext>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
