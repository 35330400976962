/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/default-param-last */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import { Aggregate } from "mongoose";
import { Backtest, CCollectionTypes } from "../..";
import * as chatgpt from "../../../chat-gpt/chat-gpt";
import { companies } from "../../database/R-DB/timeseries/companies";
import { cryptocurrencies } from "../../database/R-DB/timeseries/cryptocurrencies";
import { NewsMentions, newsmentions } from "../../database/newsmentions";
import { FinancialSentiment, NewsSentiment, newssentiments } from "../../database/newssentiments";
import { env } from "../../env";
import { getDateFromLabel, getDateFromStep } from "../../http-utilities/http-utilities/dates/dates.http.service";
import { sendProgress } from "../../http-utilities/http-utilities/progress/progress.backend.service.";
import { TimeSeriesManager } from "../../managers/time-series-manager";
import { okNumber } from "../../shared/utilites/number.utilities";
import { cloneDeep } from "../../shared/utilites/object.utilities";
import { average } from "../../shared/utilites/transform";
import { InProgressDTO } from "../../status/inprogress-dto";
import { TimeSeriesHelperValue } from "../../timeseries/timeseries-models";
import { commodities } from "./../../database/R-DB/timeseries/commoditites";
import economics from "./../../database/R-DB/timeseries/economics";
import indexes from "./../../database/R-DB/timeseries/indexes";
import news from "./../../database/R-DB/timeseries/news";
import swedishcompanies from "./../../database/R-DB/timeseries/swedishcompanies";
import swedishnews from "./../../database/R-DB/timeseries/swedishnews";
import { timeseries } from "./../../database/user-DB/backend/timeseries/timeseries";
import timeserieshelpervalues from "./../../database/user-DB/backend/timeseries/timeserieshelpervalues";
import timeseriesmenu from "./../../database/user-DB/backend/timeseriesmenu";
import { ImpreemDate } from "./../../date/impreem-date";
import { MenuCategory } from "./../../menu/dtos/menu-dtos";
import { CategorizeType, GeneralCategorize, TimeSeriesDTO } from "./../../methods";
import { DataPoint, Periodicity, convertPeriodictyToStep, determinePeriodicity, periodictyToYear } from "./../../shared/periodicity";
import { groupArrayIntoGroups, makeObjectArrayToArrayOfArrayHelper } from "./../../shared/utilites/array.utilities";
import { cleanDateArray, getDateFromLabelUtilities, getDateFromStepUtilities, isSortedByDate, leftJoinByDate, sortByDate } from "./../../shared/utilites/dates.utilities";
import { getStandardDeviation, growth, growthSMA, linearRegression, linearRegressionWithPValue, makeRelativeTimeSeriesObjects, normalizationObjects } from "./../../shared/utilites/math.utilities";
import { capitalizeFirstLetter, isRegexValid, removeSomeBadCharacters } from "./../../shared/utilites/string.utilities";
import { StrategyDTO } from "./../../strategies/strategy";
import { DatesService } from "./../../timeseries/dates/dates.service";
import { NewsWord, NewsWordDTO } from "./../../timeseries/news-words/news-words";
import { ta } from "./../../transformations/transformations";
import { TransformationTypeKey } from "./../../transformations/transformations-dtos";

const talkWithChatGPT4 = chatgpt.talkWithChatGPT4;
const localUrl = env.localhost;

export class TransformationMeta {
    public extraTimeSeries: TimeSeriesDTO[] | null = [];
}

export class ObservationsDTO {
    public obs: TimeSeriesHelperValue[];

    public id: number;
}

export const timeSeriesNeedsComparision = [ "beta" ];

export function deepClone<T>(t: T): T {
    return JSON.parse(JSON.stringify(t)) as T;
}

const isRouter = true;
export const isSwedishMarket = false;

export const ADD_POSITION_IN_CUSTOM_TIME_SERIES_LABEL = "Use_TimeSeries_Add_Position";

export const EXTRA_TS = "_Extra_TS";

export class TransformationService {

    private datesService: DatesService = new DatesService();

    public async getTransformationTimeSeriesByAllTimeSeriesUsed(
        backtest: Backtest,
        allTimeseriesUsed: TimeSeriesDTO[],
        currentDate: ImpreemDate,
        symbols: string[],
        lag: number,
        horizon: number = null,
        periodicity: Periodicity,
        explicitStartDate?: ImpreemDate,
        skipIncludeComparision?: boolean,
        inProgressDTO?: InProgressDTO): Promise<TimeSeriesHelperValue[][]> {
        // check if any time series has transformationKey included "beta"
        if (!skipIncludeComparision) {
            await this.includeComparisionSeries(allTimeseriesUsed, backtest);
        }
        let transformedSeriesMatrix: TimeSeriesHelperValue[][] = [];
        try {
            const allTimeSeriesFromDbInSession: TimeSeriesHelperValue[] = [];
            let endDate: ImpreemDate = null;
            let startDate: ImpreemDate = null;
            startDate = explicitStartDate;
            endDate = currentDate;
            console.time("getTransformationTimeSeriesByAllTimeSeriesUsed");
            if (inProgressDTO) {
                inProgressDTO.atStep = 2;
                inProgressDTO.description = "Getting all time series used in test and aligning them.";
                sendProgress(inProgressDTO, backtest.client._id.toString());
            }
            const extraTs = allTimeseriesUsed.map(e => e.timeseries).flat().filter(e => e != null);
            const extraSymbols = extraTs.map(e => e.ID);
            const ts = await this.getAllTimeSeriesUsedInTestBySelected(allTimeseriesUsed.concat(extraTs), currentDate, lag, horizon, symbols.concat(extraSymbols), allTimeSeriesFromDbInSession, explicitStartDate, endDate, startDate);
            // const ts: TimeSeriesHelperValue[] = [];
            // for (let i = 0; i < groups.length; i++) {
            //     const group = groups[i];
            //     const tsGroup = await this.getAllTimeSeriesUsedInTestBySelected(allTimeseriesUsed, currentDate, lag, horizon, group, allTimeSeriesFromDbInSession, explicitStartDate, endDate, startDate);
            //     ts.push(...tsGroup);
            // }
            console.timeEnd("getTransformationTimeSeriesByAllTimeSeriesUsed");
            console.time("align");
            const alignedTimeSeries = await this.datesService.align(ts, endDate, periodicity, allTimeseriesUsed);
            console.timeEnd("align");
            console.time("getTransformationSeries");
            if (inProgressDTO) {
                inProgressDTO.atStep = 3;
                inProgressDTO.description = "Transforming all time series used in test.";
                sendProgress(inProgressDTO, backtest.client._id.toString());
            }
            transformedSeriesMatrix = await this.transformAllTimeSeriesBasedOnTransformationUniqueResultsFromDB(allTimeseriesUsed, alignedTimeSeries, periodicity);
            // find shortest time series
            const shortestTimeSeries = transformedSeriesMatrix.reduce((prev, curr) => {
                return prev.length < curr.length ? prev : curr;
            });
            const len = shortestTimeSeries.length;
            transformedSeriesMatrix = transformedSeriesMatrix.map(e => {
                e = e.slice(e.length - len);
                return e;
            });
            console.timeEnd("getTransformationSeries");
        } catch (error) {
            console.log(error);
        }
        return transformedSeriesMatrix;
    }

    public async getComparisionTimeSeries(comparisonIndex: string[], comparisionTimeSeries: TimeSeriesDTO[]): Promise<void> {
        if (comparisonIndex.length > 0) {
            const findTimeSeries = await timeseriesmenu.findOne({ categoryTitle: "Indexes" }).exec() as unknown as MenuCategory;
            if (findTimeSeries) {
                for (let i = 0; i < comparisonIndex.length; i++) {
                    const ts = findTimeSeries.items.find(t => t.ID === comparisonIndex[i]);
                    if (ts) {
                        comparisionTimeSeries.push(ts);
                    }
                }
            }
        }
    }

    public async getTimeSeriesAndTransformation(
        backtest: Backtest,
        timeSeriesInfo: TimeSeriesDTO,
        symbols: string[],
        currentDate: ImpreemDate,
        strategy: StrategyDTO,
        timeBackDateSpecified: ImpreemDate = null,
        periodicity: Periodicity): Promise<TimeSeriesDTO[]> {
        const extraTs = timeSeriesInfo?.timeseries ?? [];
        const extraSymbols = extraTs.map(e => e.ID);
        const timeSeries = await this.getTimeSeriesFromDatabase([ timeSeriesInfo, ...extraTs ], symbols.concat(extraSymbols), currentDate, strategy, timeBackDateSpecified);
        const aligned = await this.datesService.align(timeSeries.flat(), currentDate, periodicity, []);
        const alignedTimeSeries = aligned.flat();
        try {
            // check if there is more than one transformationKey in timeSeries
            const grouped = makeObjectArrayToArrayOfArrayHelper(_.groupBy(alignedTimeSeries, "transformationKey")) as TimeSeriesHelperValue[][];
            let timeseriesGrouped: TimeSeriesHelperValue[][] = [];
            for (let i = 0; i < grouped.length; i++) {
                const groupedSecond = makeObjectArrayToArrayOfArrayHelper(_.groupBy(grouped[i], "symbol")) as TimeSeriesHelperValue[][];
                timeseriesGrouped.push(...groupedSecond);
            }
            const ts: TimeSeriesDTO[] = [];
            const resTimeSeriesHelperValues = await this.transformAllTimeSeriesBasedOnTransformationUniqueResultsFromDB([ timeSeriesInfo ], timeseriesGrouped, periodicity);
            timeseriesGrouped = timeseriesGrouped.filter(e => !extraTs.map(d => d.transformationKey).includes(e[0].transformationKey));
            for (let i = 0; i < timeseriesGrouped.length; i++) {
                let newTS: TimeSeriesDTO = null;
                const values = timeseriesGrouped[i];
                const hasTransformationKey = timeseriesGrouped[i][0].transformationKey;
                const useKey = hasTransformationKey ?? timeSeriesInfo.transformationKey;
                const key = useKey.split("-")[0] as TransformationTypeKey;
                const timeSeriesHelper: TimeSeriesHelperValue[] = this.appendTimeSeriesKey(values, hasTransformationKey ? {
                    timeSeriesKey: timeseriesGrouped[i][0].timeSeriesKey,
                    transformationKey: timeseriesGrouped[i][0].transformationKey, parameters: timeSeriesInfo.parameters,
                } : timeSeriesInfo);
                const tsDto = new TimeSeriesDTO();
                tsDto.transformationKey = timeSeriesHelper[0].transformationKey;
                tsDto.timeSeriesKey = timeSeriesHelper[0].timeSeriesKey;
                tsDto.parameters = timeSeriesHelper[0].parameters;
                tsDto.transformations = timeSeriesInfo.transformations;
                tsDto.collectionInDb = timeSeriesInfo.collectionInDb;
                tsDto.normalization = timeSeriesInfo.normalization;

                // check if timeSeriesInfo.transformationKey is included in timeSeriesNeedsComparision
                const needsComparision = timeSeriesNeedsComparision.includes(key);
                const meta: TransformationMeta = new TransformationMeta();
                if (needsComparision && timeSeriesInfo?.parameters?.comparisonSeries == null) {
                    const tsHelper: TimeSeriesDTO[] = [];
                    await this.getIndexes(backtest.comparisonIndex, tsHelper);
                    meta.extraTimeSeries.push(...tsHelper);
                } else if (needsComparision && timeSeriesInfo?.parameters?.comparisonSeries != null) {
                    const extraTransformationKey = timeSeriesInfo.parameters.comparisonSeries as string;
                    const extraTs = await this.getAnyTimeSeries(
                        extraTransformationKey,
                        backtest,
                        currentDate,
                        strategy,
                        timeBackDateSpecified
                    );
                    meta.extraTimeSeries.push(...extraTs);
                }
                const isCompanyTs = TimeSeriesManager.isCompanyTimeSeries(timeSeriesInfo);
                const graphValues: TimeSeriesHelperValue[] = resTimeSeriesHelperValues.find(x => x[0].transformationKey === useKey && (isCompanyTs ? x[0].symbol === values[0].symbol : true));
                newTS = new TimeSeriesDTO();
                const name = timeSeriesInfo.collectionInDb === CCollectionTypes.googletrends || timeSeriesInfo.collectionInDb === CCollectionTypes.newsWord ?
                    "Search term: " + capitalizeFirstLetter(timeseriesGrouped[i][0].timeSeriesKey.split("-")[1]) :
                    timeSeriesInfo.display;
                newTS.transformationKey = tsDto.transformationKey;
                newTS.timeSeriesKey = tsDto.timeSeriesKey;
                newTS.parameters = tsDto.parameters;
                newTS.normalization = tsDto.normalization;
                newTS.display = name;
                newTS.collectionInDb = timeSeriesInfo.collectionInDb;
                newTS.periodicity = timeSeriesInfo.periodicity;
                newTS.field = timeSeriesInfo.field;
                newTS.column = timeSeriesInfo.column;
                newTS.date = timeSeriesInfo.date;
                newTS.transformations = timeSeriesInfo.transformations;
                newTS.ID = timeSeriesInfo.ID ?? graphValues[0].symbol;
                newTS.graphValue = graphValues.map(e => {
                    const x = new TimeSeriesHelperValue();
                    x.value = e.value;
                    x.date = e.date;
                    return x;
                });
                ts.push(newTS);
            }
            return ts;
        } catch (error) {
            console.log(error);
        }
        return [];
    }

    public async getTimeSeriesFromDatabase(transformationInfo: TimeSeriesDTO[], symbols: string[], currentDate: ImpreemDate, strategy: StrategyDTO, timeBackDateSpecified: ImpreemDate = null): Promise<TimeSeriesHelperValue[][]> {
        const timeSeriesInfo = transformationInfo[0];
        const query = { $match: {} };
        let strictQuery: any = {};
        let timeBackDate: ImpreemDate = null;
        let timeBackSpecifiedDate: ImpreemDate = null;
        try {
            let maxLag = Math.max(...strategy.intersection.map(e => (e.parameters as any)?.lag ? (e.parameters as any).lag : null));
            if (!isFinite(maxLag) || maxLag == null) {
                maxLag = 0;
            }
            const timeBack = currentDate.step - convertPeriodictyToStep(timeSeriesInfo.periodicity) * 30 - maxLag * convertPeriodictyToStep(timeSeriesInfo.periodicity);
            let timeBackSpecified = timeBackDateSpecified?.step - convertPeriodictyToStep(timeSeriesInfo.periodicity) * 30 - maxLag * convertPeriodictyToStep(timeSeriesInfo.periodicity);
            if (timeBackSpecified < 0) {
                timeBackSpecified = 0;
            }
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (isRouter) {
                timeBackDate = await getDateFromStepUtilities(timeBack);
                timeBackSpecifiedDate = timeBackDateSpecified ? await getDateFromStepUtilities(timeBackSpecified) : timeBackDateSpecified;
            } else {
                timeBackDate = await getDateFromStep(timeBack, localUrl);
                timeBackSpecifiedDate = timeBackDateSpecified ? await getDateFromStepUtilities(timeBackSpecified) : timeBackDateSpecified;
            }
            strictQuery = { $lte: moment(currentDate.label).endOf("day").format("YYYY-MM-DD HH:mm:ss"),
                $gte: timeBackDateSpecified ? moment(timeBackSpecifiedDate.label).endOf("day").format("YYYY-MM-DD HH:mm:ss") :
                    moment(timeBackDate.label).endOf("day").format("YYYY-MM-DD HH:mm:ss") };
            query.$match[`${timeSeriesInfo.field}.${timeSeriesInfo.date}`] = strictQuery;
        } catch (error) {
            // empty
        }
        return await Promise.all(transformationInfo.map(ts => this.getTimeSeries(ts, [], symbols, query, strictQuery)));
    }

    public async getTransformationSeries(
        transformationInfo: TimeSeriesDTO,
        timeSeries: TimeSeriesHelperValue[],
        periodicity: Periodicity,
        extras?: TransformationMeta): Promise<TimeSeriesHelperValue[]> {
        let transformedSeries: TimeSeriesHelperValue[] = timeSeries;
        const transformationsKeys = transformationInfo?.transformations ?? [];
        const keys: string[] = transformationsKeys;
        if (keys?.length === 0) {
            keys.push("none");
        }
        for (let v = 0; v < transformationsKeys.length; v++) {
            const currentKey = transformationsKeys[v];
            try {
                switch (currentKey) {
                case "none": {
                    transformedSeries = timeSeries;
                    break;
                }
                case "std": {
                    const cb = async(series: TimeSeriesHelperValue[], parameters: any) => {
                        const numberArray = this.std(series, parameters.n);
                        return numberArray;
                    };
                    transformedSeries = await this.reForwardTransformation(transformationInfo, transformedSeries, cb);
                    break;
                }
                case "beta": {
                    if (transformationInfo?.transformationKey?.includes("BETA_DONE")) {
                        break;
                    }
                    const comparisionSeries = extras?.extraTimeSeries;
                    if (!comparisionSeries || comparisionSeries.length !== 1) {
                        break;
                    }
                    const cb = async(numberArray: TimeSeriesHelperValue[], parameters: any) => {
                        const valuesCs = comparisionSeries[0].graphValue;
                        const final = leftJoinByDate(numberArray.map(e => {
                            e.d = new Date(e.date);
                            return e;
                        }), valuesCs.map(e => {
                            e.d = new Date(e.date);
                            return e;
                        }), true);
                        const betaTransformation = this.beta(final, parameters.n != null && parameters.n != 0 ? parameters.n : periodictyToYear(periodicity) * 5, parameters.periodicity as Periodicity);
                        return betaTransformation;
                    };
                    transformedSeries = await this.reForwardTransformation(transformationInfo, transformedSeries, cb);
                    break;
                }
                case "sma": {
                    const cb = async(series: TimeSeriesHelperValue[], parameters: any) => {
                        const numberArray = this.sma(series, parameters?.n ?? 150);
                        return numberArray;
                    };
                    transformedSeries = await this.reForwardTransformation(transformationInfo, transformedSeries, cb);
                    break;
                }
                case "ema": {
                    const cb = async(series: TimeSeriesHelperValue[], parameters: any) => {
                        const numberArray = await ta.ema(series.map(e => e.value), 0.05 + 0.15 * ((parameters.n - 1) / 99));
                        this.afterTransformation(numberArray, series);
                        return series;
                    };
                    transformedSeries = await this.reForwardTransformation(transformationInfo, transformedSeries, cb);
                    break;
                }
                case "growth": {
                    const cb = async(series: TimeSeriesHelperValue[], parameters: any) => {
                        const numberArray = growth(series.map(e => e.value), 1);
                        this.afterTransformation(numberArray, series);
                        return series;
                    };
                    transformedSeries = await this.reForwardTransformation(transformationInfo, transformedSeries, cb);
                    break;
                }
                case "growth1years": {
                    const cb = async(series: TimeSeriesHelperValue[], parameters: any) => {
                        const numberArray = growthSMA(series.map(e => e.value), periodicity === "day" ? 250 : periodicity === "week" ? 52 : 12);
                        this.afterTransformation(numberArray, series);
                        return series;
                    };
                    transformedSeries = await this.reForwardTransformation(transformationInfo, transformedSeries, cb);
                    break;
                }
                case "growth2years": {
                    const cb = async(series: TimeSeriesHelperValue[], parameters: any) => {
                        const numberArray = growthSMA(series.map(e => e.value), periodicity === "day" ? 250 * 2 : periodicity === "week" ? 52 * 2 : 12 * 2);
                        this.afterTransformation(numberArray, series);
                        return series;
                    };
                    transformedSeries = await this.reForwardTransformation(transformationInfo, transformedSeries, cb);
                    break;
                }
                case "growth3years": {
                    const cb = async(series: TimeSeriesHelperValue[], parameters: any) => {
                        const numberArray = growthSMA(series.map(e => e.value), periodicity === "day" ? 250 * 3 : periodicity === "week" ? 52 * 3 : 12 * 3);
                        this.afterTransformation(numberArray, series);
                        return series;
                    };
                    transformedSeries = await this.reForwardTransformation(transformationInfo, transformedSeries, cb);
                    break;
                }
                case "diff": {
                    const cb = async(series: TimeSeriesHelperValue[], parameters: any) => {
                        const numberArray: TimeSeriesHelperValue[] = [];
                        // do a reverse loop of series
                        for (let i = series.length - 1; i >= 0; i--) {
                            if (i - 1 < 0) {
                                continue;
                            }
                            const diff = series[i].value - series[i - 1].value;
                            numberArray.unshift({ ...series[i], value: diff });
                        }
                        return numberArray;
                    };
                    transformedSeries = await this.reForwardTransformation(transformationInfo, transformedSeries, cb);
                    break;
                }
                case "wma": {
                    const cb = async(series: TimeSeriesHelperValue[], parameters: any) => {
                        const numberArray = await ta.wma(series.map(e => e.value), parameters.n);
                        this.afterTransformation(numberArray, series);
                        return series;
                    };
                    transformedSeries = await this.reForwardTransformation(transformationInfo, transformedSeries, cb);
                    break;
                }
                case "kama": {
                    const cb = async(series: TimeSeriesHelperValue[], parameters: any) => {
                        const numberArray = await ta.kama(series.map(e => e.value), parameters.fastEmaN < 5 ? 5 : Math.round(parameters.fastEmaN), Math.round(1 + 19 * ((parameters.slowEmaN - 1) / 99)), Math.round(parameters.n));
                        this.afterTransformation(numberArray, series);
                        return series;
                    };
                    transformedSeries = await this.reForwardTransformation(transformationInfo, transformedSeries, cb);
                    break;
                }
                case "macd": {
                    const cb = async(series: TimeSeriesHelperValue[], parameters: any) => {
                        const numberArray = await ta.macd(series.map(e => e.value), parameters.nFast, parameters.nSlow);
                        this.afterTransformation(numberArray, series);
                        return series;
                    };
                    transformedSeries = await this.reForwardTransformation(transformationInfo, transformedSeries, cb);
                    break;
                }
                case "rsi": {
                    const cb = async(series: TimeSeriesHelperValue[], parameters: any) => {
                        const numberArray = await ta.rsi(series.map(e => e.value), Math.round(parameters.n < 5 ? 5 : parameters.n));
                        this.afterTransformation(numberArray, series);
                        return series;
                    };
                    transformedSeries = await this.reForwardTransformation(transformationInfo, transformedSeries, cb);
                    break;
                }
                case "ticks": {
                    const cb = async(series: TimeSeriesHelperValue[], parameters: any) => {
                        const numberArray = this.ticks(series.map(e => e.value), parameters.interval);
                        const n = this.afterTransformation(numberArray, series);
                        return n;
                    };
                    transformedSeries = await this.reForwardTransformation(transformationInfo, transformedSeries, cb);
                    break;
                }
                case "fractional": {
                    const cb = async(series: TimeSeriesHelperValue[], parameters: any) => {
                        const numberArray = this.fractionalDifference(series.map(e => e.value), 1 + 49 * ((parameters.n - 1) / 99), parameters.interval);
                        this.afterTransformation(numberArray, series);
                        return series;
                    };
                    transformedSeries = await this.reForwardTransformation(transformationInfo, transformedSeries, cb);
                    break;
                }
                }
            } catch (error) {
                // empty
            }
        }

        const needsModification = transformedSeries.some(e => isNaN(e.value) || !isFinite(e.value));
        
        if (needsModification) {
            transformedSeries = transformedSeries.map(e => {
                if (isNaN(e.value) || !isFinite(e.value)) {
                    return { ...e, value: null };
                }
                return e;
            });
        }
        
        // do this in the main loop
        // if (transformationInfo?.categorizeType != null && transformationInfo?.categorizeType?.isOnFly) {
        //     transformedSeries = this.categorizeService.categorize(transformationInfo, transformedSeries);
        // }
        const keysParts = transformationInfo?.transformationKey?.split("-");
        const isToplist = keysParts.includes("isToplist");
        // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
        if (transformationInfo?.categorizeType != null && transformationInfo?.categorizeType?.isOnFly || isToplist) {
            transformedSeries  = transformedSeries.map(e => {
                e.shouldCategorized = true;
                return e;
            });
        }

        // Scale data always after transformation
        if (!transformationInfo?.transformationKey?.includes(EXTRA_TS)) {
            if (transformationInfo?.normalization === "std") {
                for (let t = 0; t < transformedSeries.length; t++) {
                    transformedSeries = makeRelativeTimeSeriesObjects(transformedSeries, "value");
                }
            } else if (transformationInfo?.normalization === "normal") {
                for (let t = 0; t < transformedSeries.length; t++) {
                    transformedSeries = normalizationObjects(transformedSeries, "value");
                }
            }
        }

        const multiplyValue = transformationInfo.multiply;
        if (multiplyValue != null) {
            for (let t = 0; t < transformedSeries.length; t++) {
                transformedSeries = transformedSeries.map(e => {
                    e.value = e.value * multiplyValue;
                    return e;
                });
            }
        }

        return transformedSeries;
    }

    private validStandAloneTimeSeries(transformationInfo: TimeSeriesDTO) {
        return transformationInfo.collectionInDb === CCollectionTypes.economics || transformationInfo.collectionInDb === CCollectionTypes.indexes ||
            transformationInfo.collectionInDb === CCollectionTypes.googletrends
            || transformationInfo.collectionInDb === CCollectionTypes.commodities
            || transformationInfo.collectionInDb === CCollectionTypes.uploaded
            || transformationInfo.collectionInDb === CCollectionTypes.newsWord
            || transformationInfo.transformationKey.includes("BETA_DONE");
    }

    private afterTransformation(numberArray: number[], series: TimeSeriesHelperValue[]) {
        let removeFrom = 0;
        let nIndex = numberArray.length;
        for (let i = series.length - 1; i >= 0; i--) {
            if (nIndex === -1) {
                removeFrom = i;
                break;
            }
            series[i].value = numberArray[nIndex - 1];
            nIndex--;
        }
        const n = series.slice(removeFrom + 1, series.length - 1);
        return n;
    }

    private std(values: TimeSeriesHelperValue[], n: number): TimeSeriesHelperValue[] {
        const stds: TimeSeriesHelperValue[] = [];
        if (n > 40) {
            n = 40;
        }
        if (n < 1) {
            n = 1;
        }
        for (let i = n; i < values.length; i++) {
            const data = values.slice(i - n, i);
            const std = getStandardDeviation(data.map(e => e.value));
            stds.push({ ...values[i], value: std });
        }
        return stds;
    }

    // Write a Simple Moving Average function with parameters: TimeSeriesHelperValue[] and n: number
    private sma(values: TimeSeriesHelperValue[], n: number): TimeSeriesHelperValue[] {
        const smas: TimeSeriesHelperValue[] = [];
        if (n < 1) {
            n = 1;
        }
        for (let i = n; i < values.length; i++) {
            const data = values.slice(i - n, i);
            const sma = average(data.map(e => e.value));
            smas.push({ ...values[i], value: sma });
        }
        return smas;
    }

    private beta(values: TimeSeriesHelperValue[][], n: number, periodicity: Periodicity): TimeSeriesHelperValue[] {
        const betas: TimeSeriesHelperValue[] = [];
        if (n < 1) {
            n = 1;
        }
        const interval = convertPeriodictyToStep(periodicity ?? "day");
        // do a reverse loop of values
        for (let i = values[0].length; i >= n; i--) {
            if (i % interval !== 0 || i == values[0].length) {
                continue;
            }
            const data = values[0].slice(i - n, i);
            const data2 = values[1].slice(i - n, i);
            let growth1 = growth(data.map(e => e.value));
            let growth2 = growth(data2.map(e => e.value));
            // replace NAN and Infinity with 0
            growth1 = growth1.map(e => okNumber(e) ? e : 0);
            growth2 = growth2.map(e => okNumber(e) ? e : 0);
            const beta = linearRegression(growth2, growth1);
            betas.unshift({ ...values[0][i], value: beta[0] });
        }
        return betas;
    }

    private alpha(values: TimeSeriesHelperValue[][], n: number): TimeSeriesHelperValue[] {
        const betas: TimeSeriesHelperValue[] = [];
        if (n < 1) {
            n = 1;
        }
        for (let i = n; i < values[0].length; i++) {
            const data = values[0].slice(i - n, i);
            const data2 = values[1].slice(i - n, i);
            const growth1 = growth(data.map(e => e.value));
            const growth2 = growth(data2.map(e => e.value));
            const beta = linearRegressionWithPValue(growth2, growth1);
            betas.push({ ...values[0][i], value: beta.intercept ?? -1, meta: beta.pValueIntercept ?? 1 });
        }
        return betas;
    }

    private ticks(numbers: number[], interval: number) {
        const tickBars: number[] = [];
        for (let i = 1; i < numbers.length - interval + 1; i++) {
            let intervalSum = 0;
            for (let j = i; j < i + interval; j++) {
                intervalSum += Math.sign((numbers[j] - numbers[j - 1]) / numbers[j - 1]);
            }
            tickBars.push(intervalSum);
        }
        return tickBars;
    }

    private fractionalDifference(data: number[], diffFactor: number, windowSize: number) {
        const derivative: number[] = [];
        for (let i = 0; i < data.length; i++) {
            let sum = 0;
            for (let j = 0; j < windowSize; j++) {
                if (i - j < 0) {
                    continue;
                }
                sum += (data[i - j] - data[i - j - 1]) * (diffFactor - j) * -1;
            }
            derivative.push(sum / this.gamma(windowSize - diffFactor));
        }
        return derivative;
    }

    private gamma(x: number) {
        let y = 1;
        for (let i = 2; i <= x; i++) {
            y *= i;
        }
        return y;
    }

    private async reForwardTransformation(
        timeSeriesInfo: TimeSeriesDTO,
        timeSeries: TimeSeriesHelperValue[],
        cb: (series: TimeSeriesHelperValue[], parameters: any) => Promise<TimeSeriesHelperValue[]>) {
        const transformation = await cb(timeSeries, timeSeriesInfo?.parameters ?? {});
        return transformation;
    }

    private convertToDailyTimeSeries(newsTextArray: NewsWord[], wordKey: string, timeSeriesInfo: TimeSeriesDTO, endDateSpecific: ImpreemDate) {
        if (newsTextArray.length === 0) {
            return [];
        }

        /*
         *  Set the start and end dates for the time series
         * const startDate = new Date("2021-03-02");
         */

        // find the latest date in the newsTextArray
        const startDate = newsTextArray.reduce((minDate, item) => {
            const itemDate = new Date(item.publishedDate);
            return itemDate < minDate ? itemDate : minDate;
        }, new Date("2021-03-02"));

        const endDate = new Date(endDateSpecific?.label ?? moment().format("YYYY-MM-DD"));

        // Initialize the daily time series with counts of 0
        const timeSeries: TimeSeriesHelperValue[] = [];
        const currentDate = new Date(startDate);
        currentDate.setHours(16, 0, 0, 0);
        let previousDate = new Date("2021-03-01");
        previousDate.setHours(16, 0, 0, 0);

        while (currentDate <= moment(endDate).add(1, "days").toDate()) {
            const dateString = currentDate.toISOString().split("T")[0];
            const articleCount = newsTextArray.filter(
                // eslint-disable-next-line @typescript-eslint/no-loop-func
                item => {
                    const itemDate = new Date(item.publishedDate);
                    return (
                        itemDate.getTime() > previousDate.getTime() &&
                        itemDate.getTime() <= currentDate.getTime()
                    );
                }
            ).length;
            const obs = new TimeSeriesHelperValue();
            obs.date = dateString;
            obs.value = articleCount;
            obs.d = currentDate;
            obs.buy = false;
            const transformationKey = timeSeriesInfo.transformationKey.split("-")[0];
            obs.transformationKey = transformationKey + "-newsword-" + wordKey;
            obs.timeSeriesKey = "newsword-" + wordKey;
            obs.parameters = {};
            obs.symbol = null;
            timeSeries.push(obs);
            previousDate = new Date(currentDate);
            previousDate.setHours(16, 0, 0, 0);
            currentDate.setDate(currentDate.getDate() + 1);
            currentDate.setHours(16, 0, 0, 0);
        }

        return timeSeries;
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    private async getTimeSeries(timeSeriesInfo: TimeSeriesDTO, timeSeries: TimeSeriesHelperValue[], symbols: string[], query: { $match: {} }, strictQuery: any): Promise<TimeSeriesHelperValue[]> {
        if (timeSeriesInfo.transformationKey == null) return [];
        let isCategorized = timeSeriesInfo?.categorizeType != null && !timeSeriesInfo?.categorizeType?.isOnFly;
        // get last part of transformationKey in timeSeriesInfo.transformationKey
        const keys = timeSeriesInfo.transformationKey.split("-");
        const key = keys[keys.length - 1];
        let useCategory: CategorizeType = null;
        if (key === "isToplist") {
            // REMOVE???
            // ????
            const sublistNumber = keys[keys.length - 2];
            const betaCategorize = new GeneralCategorize();
            betaCategorize.key = "sublist";
            betaCategorize.categorizeType = [ "end", sublistNumber ];
            const categorizeType = new CategorizeType();
            categorizeType.key = "beta";
            categorizeType.meta = betaCategorize;
            useCategory = categorizeType;
            isCategorized = true;
        }
        switch (timeSeriesInfo.collectionInDb) { 
        case CCollectionTypes.avgfinancialratiosIncomeStatementQuarter: {
            const values = await timeserieshelpervalues.find({ symbol: timeSeriesInfo.ID, meta: CCollectionTypes.avgfinancialratiosIncomeStatementQuarter, date: strictQuery }).exec() as unknown as TimeSeriesHelperValue[];
            timeSeries = this.cleanMultipleSymbolTimeSeries(values);
            break;
        }
        case CCollectionTypes.avgfinancialratiosRatiosQuarter: {
            const values = await timeserieshelpervalues.find({ symbol: timeSeriesInfo.ID, meta: CCollectionTypes.avgfinancialratiosRatiosQuarter, date: strictQuery }).exec() as unknown as TimeSeriesHelperValue[];
            timeSeries = this.cleanMultipleSymbolTimeSeries(values);
            break;
        }
        case CCollectionTypes.newsSentiments: {
            const values = await newssentiments.find({ symbol: { $in: symbols }, date: strictQuery }).exec() as unknown as (NewsSentiment | FinancialSentiment)[];
            const v = values.map(e => {
                const n = new TimeSeriesHelperValue();
                n.d = new Date(e.date);
                n.date = e.date;
                n.symbol = e.symbol;
                n.value = timeSeriesInfo.column === "sentiment" ? (e as NewsSentiment).sentiment : (e as FinancialSentiment).finacialSentiment;
                n.buy = false;
                n.transformationKey = timeSeriesInfo.transformationKey;
                n.timeSeriesKey = timeSeriesInfo.timeSeriesKey;
                n.parameters = {};
                return n;
            });
            timeSeries = this.cleanMultipleSymbolTimeSeries(v);
            break;
        }
        case CCollectionTypes.newsMentions: {
            if (!isCategorized) {
                const values = await newsmentions.find({ symbol: { $in: symbols }, date: strictQuery }).exec() as unknown as NewsMentions[];
                const v = values.map(e => {
                    const n = new TimeSeriesHelperValue();
                    n.d = new Date(e.date);
                    n.date = e.date;
                    n.symbol = e.symbol;
                    n.value = e.mentions;
                    n.buy = false;
                    n.transformationKey = timeSeriesInfo.transformationKey;
                    n.timeSeriesKey = timeSeriesInfo.timeSeriesKey;
                    n.parameters = {};
                    return n;
                });
                timeSeries = this.cleanMultipleSymbolTimeSeries(v);
            } else {
                const paramters = useCategory ?? timeSeriesInfo.categorizeType;
                const metaInfo = paramters.meta as GeneralCategorize;
                const specific = metaInfo.categorizeType as string[];
                const select = specific[1] != null ? +specific[1] : 0;
                const interval1 = metaInfo?.interval?.[0] ?? 0;
                const interval2 = metaInfo?.interval?.[1] ?? 0;
                const results = await newsmentions.aggregate([
                    { $match: { symbol: { $in: symbols }, date: strictQuery } },
                    {
                        $addFields: {
                            convertedDate: {
                                $dateFromString: {
                                    dateString: "$date",
                                    format: "%Y-%m-%d",
                                },
                            },
                        },
                    },
                    {
                        $group: {
                            _id: {
                                symbol: "$symbol",
                                year: { $year: "$convertedDate" },
                                month: { $month: "$convertedDate" },
                                day: { $dayOfMonth: "$convertedDate" },
                            },
                            mentionsCount: { $sum: "$mentions" },
                        },
                    },
                    {
                        $group: {
                            _id: {
                                year: "$_id.year",
                                month: "$_id.month",
                                day: "$_id.day",
                            },
                            symbols: { 
                                $push: {
                                    symbol: "$_id.symbol",
                                    mentionsCount: "$mentionsCount",
                                },
                            },
                        },
                    },
                    {
                        $project: {
                            companies: {
                                $switch: {
                                    branches: [
                                        {
                                            case: { $eq: [ metaInfo.key, "absolute" ] },
                                            then: {
                                                $filter: {
                                                    input: "$symbols",
                                                    as: "symbols",
                                                    cond: specific[0] === "up" ?
                                                        { $gt: [ "$$symbols.mentionsCount", select ] } :
                                                        { $lt: [ "$$symbols.mentionsCount", select ] },
                                                },
                                            },
                                        },
                                        {
                                            case: { $eq: [ metaInfo.key, "sublist" ] },
                                            then: {
                                                $slice: [
                                                    {
                                                        $sortArray: {
                                                            input: "$symbols",
                                                            sortBy: { mentionsCount: specific[0] === "end" ? -1 : 1 },
                                                        },
                                                    },
                                                    0,
                                                    select,
                                                ],
                                            },
                                        },
                                        {
                                            case: { $eq: [ metaInfo.key, "interval" ] },
                                            then: {
                                                $slice: [
                                                    {
                                                        $sortArray: {
                                                            input: "$symbols",
                                                            sortBy: { mentionsCount: specific[0] === "end" ? -1 : 1 },
                                                        },
                                                    },
                                                    interval1,
                                                    interval2 - interval1 <= 0 ? 1 : interval2 - interval1,
                                                ],
                                            },
                                        },
                                    ],
                                    default: "$symbols", // Fallback if none of the conditions are met
                                },
                            },
                        },
                    },
                ]).exec() as unknown as NewsMentions[];
                const ts = results.map((e: any) => {
                    const date = moment(`${e._id.year as number}-${e._id.month as number}-${e._id.day as number}`).toDate();
                    const ts1 = e.companies.map((f: any) => {
                        const n = new TimeSeriesHelperValue();
                        n.d = date;
                        n.date = moment(date).format("YYYY-MM-DD");
                        n.symbol = f.symbol;
                        n.value = 1;
                        n.buy = false;
                        n.transformationKey = timeSeriesInfo.transformationKey;
                        n.timeSeriesKey = timeSeriesInfo.timeSeriesKey;
                        n.parameters = {};
                        return n;
                    });
                    return ts1;
                });
                const endDate = new Date(strictQuery.$lte);
                const startDate = new Date(strictQuery.$gte);
                const isSublist = metaInfo.key === "sublist" || metaInfo.key === "interval";
                const joined = await this.toppListAdjusted(ts.flat(), startDate, endDate, isSublist, metaInfo, select);
                return joined.flat();
            }
            break;
        }
        case CCollectionTypes.newsWord: {
            const parameters = timeSeriesInfo.parameters as NewsWordDTO;
            const wordKeys = Array.isArray(parameters.keywords) ? parameters.keywords : [ parameters.keywords ];

            // try {
            //     const prompt = "## Keywords ##" + JSON.stringify(wordKeys) + "## Keywords ##. Between the ## are Keywords. If you agree that the keywords are companies, company symbols, topics related to various economic, financial, security, geopolitical, and environmental crises, including policy measures, market phenomena, natural disasters, and sustainability efforts then write anywhere 1, if you do not agree write 0.";
            //     const resultChatGPT = await talkWithChatGPT4(prompt) as ChatMessage;
            //     const text = resultChatGPT.text;

            //     if (text.includes("0")) {
            //         return [];
            //     }
            // } catch (error) {
            //     console.log(error);
            //     return [];   
            // }

            const promiseLists: Aggregate<any[]>[] = [];

            const addDateCondition = strictQuery ? { publishedDate: strictQuery } : {};

            wordKeys.forEach(wordKey => {
                const split = wordKey.split(",").map(word => word.split(" ")).flat();

                const regexQueries = split.map(keyword => {
                    const cleanedKeyword = removeSomeBadCharacters(keyword).trim();
                    const isTextRegexValid = isRegexValid(cleanedKeyword);
                    const isTitleRegexValid = isRegexValid(cleanedKeyword);

                    if (!isTextRegexValid || !isTitleRegexValid) {
                        return null;
                    }

                    return {
                        title: { $regex: cleanedKeyword.toLowerCase(), $options: "i" },
                    };
                }).filter(t => t != null);

                if (regexQueries.length > 0) {
                    const pipeline = [
                        {
                            $match: {
                                $and: regexQueries,
                                ...addDateCondition,
                            },
                        },
                        { $project: { _id: 0, text: 1, publishedDate: 1, title: 1 } },
                    ];

                    const newsTextArray = news.aggregate(pipeline); // { maxTimeMS: 15000 };
                    promiseLists.push(newsTextArray);
                }
            });

            const newsTextArrays = await Promise.all(promiseLists) as NewsWord[][];
                
            const endDateSpecific = await getDateFromLabel(moment(strictQuery.$lte).format("YYYY-MM-DD"), localUrl);

            const timeSeriesArrays = newsTextArrays.map((newsTextArray, i) => {
                return sortByDate(cleanDateArray(this.convertToDailyTimeSeries(newsTextArray, wordKeys[i], timeSeriesInfo, endDateSpecific), "date"), "date");
            });

            timeSeries = timeSeriesArrays.flat();

            break;
        }
        case CCollectionTypes.uploaded: {
            const values = await timeserieshelpervalues.find({ meta: timeSeriesInfo._id }).exec() as unknown as TimeSeriesHelperValue[];
            timeSeries = sortByDate(cleanDateArray(JSON.parse(JSON.stringify(values)) as TimeSeriesHelperValue[], "date"), "date");
            break;
        }
        case CCollectionTypes.commodities: {
            const useId = timeSeriesInfo.ID == null ? timeSeriesInfo.transformationKey.split("-")[2] : timeSeriesInfo.ID;
            const date = await getDateFromLabel(moment().format("YYYY-MM-DD"), localUrl);
            await this.seedCommoditiesPrice([ useId ], date);
            const values = await commodities.find({ symbol: useId, date: strictQuery, volume: { $gt: 0 } }).exec() as unknown as any[];
            const ts: TimeSeriesHelperValue[] = values.map(e => {
                return {
                    date: e.date,
                    value: e.adjClose,
                    symbol: e.symbol,
                    transformationKey: timeSeriesInfo.transformationKey,
                } as TimeSeriesHelperValue;
            });
            timeSeries = sortByDate(cleanDateArray(ts, "date"), "date");
            break;
        }
        case CCollectionTypes.economics: {
            const id = timeSeriesInfo.ID != null ? timeSeriesInfo.ID : timeSeriesInfo.transformationKey.split("-")[2];
            timeSeries = await economics.aggregate([
                { $match: { ID: id } }, { $unwind: "$data" }, query,
                { $project: { value: "$data.value", date: "$data.date" } },
            ]).exec() as TimeSeriesHelperValue[];
            timeSeries = sortByDate(cleanDateArray(timeSeries.map(e => {
                e.value = +e.value;
                return e;
            }), "date"), "date");
            break;
        }
        case CCollectionTypes.indexes || timeSeriesInfo.timeSeriesKey.includes("indexes"): {
            const id = timeSeriesInfo.ID != null ? timeSeriesInfo.ID : timeSeriesInfo.transformationKey.split("-")[2];
            const date = await getDateFromLabel(moment().format("YYYY-MM-DD"), localUrl);
            await this.seedIndexesPrice([ id ], date);
            timeSeries = await indexes.aggregate([
                { $match: { symbol: id } }, { $unwind: "$price" }, query,
                { $project: { value: "$price.adjClose", date: "$price.date" } },
            ]).exec() as TimeSeriesHelperValue[];
            timeSeries = sortByDate(cleanDateArray(timeSeries, "date"), "date");
            break;
        }
        case CCollectionTypes.cryptocurrencies: {
            const useId = timeSeriesInfo.ID == null ? timeSeriesInfo.transformationKey.split("-")[2] : timeSeriesInfo.ID;
            const values = await cryptocurrencies.find({ symbol: useId, date: strictQuery }).exec() as unknown as any[];
            const ts: TimeSeriesHelperValue[] = values.map(e => {
                return {
                    date: e.date,
                    value: e.adjClose,
                    symbol: e.symbol,
                    transformationKey: timeSeriesInfo.transformationKey,
                } as TimeSeriesHelperValue;
            });
            timeSeries = sortByDate(cleanDateArray(ts, "date"), "date");
            break;
        }
        case CCollectionTypes.news:
        case CCollectionTypes.swedishnews: {
            if (isSwedishMarket) {
                timeSeries = await swedishnews.aggregate([
                    { $match: { symbol: { $in: symbols } } }, { $unwind: "$" + timeSeriesInfo.field }, query,
                    { $project: { value: "$" + timeSeriesInfo.field + "." + timeSeriesInfo.column, date: "$" + timeSeriesInfo.field + "." + timeSeriesInfo.date, symbol: "$symbol" } },
                ]).exec() as TimeSeriesHelperValue[];
            } else {
                query.$match[`${timeSeriesInfo.field}.${timeSeriesInfo.date}`] = { $lte: moment(new Date()).format("YYYY-MM-DD") };
                timeSeries = await news.aggregate([
                    { $match: { symbol: { $in: symbols } } }, { $unwind: "$" + timeSeriesInfo.field }, query,
                    { $project: { value: "$" + timeSeriesInfo.field + "." + timeSeriesInfo.column, date: "$" + timeSeriesInfo.field + "." + timeSeriesInfo.date, symbol: "$symbol" } },
                ]).exec() as TimeSeriesHelperValue[];
            }
            break;
        }
        default: {
            const badSymbols = [ "KXIN", "RGA", "RZA", "MULN", "IMVT", "COMS", "NAKD", "XELA", "CENN", "FREY", "ARVL", "BYSI", "SLDP", "FRLN", "ACER", "TXMD", "FYBR" ];
            symbols = symbols.filter(e => !badSymbols.includes(e));
            if (isCategorized) {
                const paramters = timeSeriesInfo.categorizeType;
                const metaInfo = paramters.meta as GeneralCategorize;
                if (timeSeriesInfo?.transformationKey?.includes("BETA_DONE")) {
                    const specific = metaInfo?.categorizeType as string[] ?? [ "end" ];
                    const select = specific?.[1] != null ? +specific[1] : 1000;
                    const result = await timeseries.aggregate([
                        {
                            $match: {
                                ID: { $in: symbols },
                                transformationKey: /BETA_DONE/i,
                            },
                        },
                        {
                            $lookup: {
                                from: "timeserieshelpervalues",
                                localField: "graphValue",
                                foreignField: "_id",
                                as: "graphValue",
                            },
                        },
                        { $unwind: "$graphValue" },
                        {
                            $project: {
                                symbol: "$ID",
                                value: "$graphValue.value",
                                date: {
                                    $dateFromString: {
                                        dateString: "$graphValue.date",
                                        format: "%Y-%m-%d",
                                    },
                                },
                            },
                        },
                        {
                            $group: {
                                _id: {
                                    month: { $month: "$date" },
                                    year: { $year: "$date" },
                                },
                                topSymbols: { $push: "$$ROOT" },
                            },
                        },
                        metaInfo.key === "absolute" ? 
                            {
                                $project: {
                                    _id: 0,
                                    year: "$_id.year",
                                    month: "$_id.month",
                                    topSymbols: {
                                        $filter: {
                                            input: {
                                                $sortArray: {
                                                    input: "$topSymbols",
                                                    sortBy: { value: specific[0] === "up" ? 1 : -1 },
                                                },
                                            },
                                            as: "item",
                                            cond: {
                                                $cond: {
                                                    if: { $eq: [ specific[0], "up" ] },
                                                    then: { $gt: [ "$$item.value", select ] },
                                                    else: { $lt: [ "$$item.value", select ] },
                                                },
                                            },
                                        },
                                    },
                                },
                            } :                            
                            {
                                $project: {
                                    _id: 0,
                                    year: "$_id.year",
                                    month: "$_id.month",
                                    topSymbols: {
                                        $slice: [
                                            {
                                                $sortArray: {
                                                    input: "$topSymbols",
                                                    sortBy: { value: specific[0] === "end" ? -1 : 1 },
                                                },
                                            },
                                            select, 
                                        ],
                                    },
                                },
                            },
                    ]).exec();
                        
                    const endDate = new Date(strictQuery.$lte);
                    const startDate = new Date(strictQuery.$gte);
                    timeSeries = result.map(e => {
                        return e.topSymbols.map(p => {
                            const n = new TimeSeriesHelperValue();
                            n.symbol = p.symbol;
                            n.value = 1;
                            n.date = moment(p.date).endOf("month").format("YYYY-MM-DD");
                            n.d = p.date;
                            return n;
                        }).filter(x => x.d.getTime() >= startDate.getTime() && x.d.getTime() <= endDate.getTime());
                    }).flat();
                    return timeSeries;
                } else {
                    const ts = await companies.aggregate([
                        { $match: { symbol: { $in: [ "AAPL" ] } } }, { $unwind: "$" + timeSeriesInfo.field }, query,
                        { $project: { value: "$" + timeSeriesInfo.field + "." + timeSeriesInfo.column, date: "$" + timeSeriesInfo.field + "." + timeSeriesInfo.date, symbol: "$symbol" } },
                    ]).exec() as TimeSeriesHelperValue[];
                    ts.shift();
                    const peri = determinePeriodicity(ts.map(e => new DataPoint(new Date(e.date), e.value)).sort((a, b) => a.date.getTime() - b.date.getTime())) as Periodicity;
                    const everyNth = peri === "day" || peri === "week" ? 60 : 1;
                    const paramters = timeSeriesInfo.categorizeType;
                    const key = paramters.key;

                    const metaInfo = paramters.meta as GeneralCategorize;
                    const specific = metaInfo.categorizeType as string[];
                    const select = specific[1] != null ? +specific[1] : 0;
                    const isSublist = metaInfo.key === "sublist" || metaInfo.key === "interval";
                    const isMarketCap = timeSeriesInfo.timeSeriesKey.includes("market-capitalization");
                    let extra = specific[0] === "end" ? { "volAvg": { $gte: 30000 } } : {};
                    if (isSublist && isMarketCap) {
                        extra = { ...extra, "mktCap": { $gte: 100000000 } } as any;
                    }
                    const interval1 = metaInfo?.interval?.[0] ?? 0;
                    const interval2 = metaInfo?.interval?.[1] ?? 0;
                    const result = await companies.aggregate([
                        {
                            $match: {
                                "isEtf": false,
                                "isFund": false,
                                "isAdr": false,
                                "exchangeShortName": { $in: [ "NASDAQ", "NYSE" ] },
                                "symbol": { $in: symbols },
                                ...extra,
                            },
                        },
                        {
                            $group: {
                                _id: "$companyName", // Group by companyName
                                doc: { $first: "$$ROOT" }, // Take the first document encountered for each companyName
                            },
                        },
                        {
                            $replaceRoot: { newRoot: "$doc" }, // Replace the root to flatten the structure
                        },
                        {
                            $unwind: {
                                path: `$${timeSeriesInfo.field}`,
                                includeArrayIndex: "arrayIndex",
                            },
                        },
                        {
                            $redact: {
                                $cond: {
                                    if: {
                                        $eq: [ { $mod: [ "$arrayIndex", everyNth ] }, 0 ], // Keeps the first and every nth element
                                    },
                                    then: "$$KEEP",
                                    else: "$$PRUNE",
                                },
                            },
                        },
                        {
                            $project: {
                                symbol: 1,
                                value: `$${timeSeriesInfo.field}.${timeSeriesInfo.column}`,
                                date: {
                                    $dateFromString: {
                                        dateString: `$${timeSeriesInfo.field}.${timeSeriesInfo.date}`,
                                        format: "%Y-%m-%d", // Format for "YYYY-MM-DD"
                                    },
                                },
                                quarter: {
                                    $cond: {
                                        if: { $lte: [ { $month: { $dateFromString: { dateString: `$${timeSeriesInfo.field}.${timeSeriesInfo.date}`, format: "%Y-%m-%d" } } }, 3 ] },
                                        then: "Q1",
                                        else: {
                                            $cond: {
                                                if: { $lte: [ { $month: { $dateFromString: { dateString: `$${timeSeriesInfo.field}.${timeSeriesInfo.date}`, format: "%Y-%m-%d" } } }, 6 ] },
                                                then: "Q2",
                                                else: {
                                                    $cond: {
                                                        if: { $lte: [ { $month: { $dateFromString: { dateString: `$${timeSeriesInfo.field}.${timeSeriesInfo.date}`, format: "%Y-%m-%d" } } }, 9 ] },
                                                        then: "Q3",
                                                        else: "Q4",
                                                    },
                                                },
                                            },
                                        },
                                    },
                                },
                                year: { $year: { $dateFromString: { dateString: `$${timeSeriesInfo.field}.${timeSeriesInfo.date}`, format: "%Y-%m-%d" } } },
                            },
                        },   
                        {
                            $group: {
                                _id: {
                                    year: "$year",
                                    quarter: "$quarter",
                                    symbol: "$symbol",
                                },
                                avgValue: { $avg: "$value" },
                            },
                        },
                        {
                            $group: {
                                _id: {
                                    year: "$_id.year",
                                    quarter: "$_id.quarter",
                                },
                                topSymbols: {
                                    $push: {
                                        symbol: "$_id.symbol",
                                        avgValue: "$avgValue",
                                    },
                                },
                            },
                        },
                        {
                            $project: {
                                companies: {
                                    $switch: {
                                        branches: [
                                            {
                                                case: { $eq: [ metaInfo.key, "absolute" ] },
                                                then: {
                                                    $filter: {
                                                        input: "$topSymbols",
                                                        as: "topSymbols",
                                                        cond: specific[0] === "up" ?
                                                            { $gt: [ "$$topSymbols.avgValue", select ] } :
                                                            { $lt: [ "$$topSymbols.avgValue", select ] },
                                                    },
                                                },
                                            },
                                            {
                                                case: { $eq: [ metaInfo.key, "sublist" ] },
                                                then: {
                                                    $slice: [
                                                        {
                                                            $sortArray: {
                                                                input: "$topSymbols",
                                                                sortBy: { avgValue: specific[0] === "end" ? -1 : 1 },
                                                            },
                                                        },
                                                        0,
                                                        select,
                                                    ],
                                                },
                                            },
                                            {
                                                case: { $eq: [ metaInfo.key, "interval" ] },
                                                then: {
                                                    $slice: [
                                                        {
                                                            $sortArray: {
                                                                input: "$topSymbols",
                                                                sortBy: { avgValue: specific[0] === "end" ? -1 : 1 },
                                                            },
                                                        },
                                                        interval1,
                                                        interval2 - interval1 <= 0 ? 1 : interval2 - interval1,
                                                    ],
                                                },
                                            },
                                        ],
                                        default: "$topSymbols", // Fallback if none of the conditions are met
                                    },
                                },
                            },
                        },
                    ]);

                    const endDate = new Date(strictQuery.$lte);
                    let startDate = new Date(strictQuery.$gte);
                    const startDateIfMarketCap = new Date("2018-12-01");
                    const startDateBeforeStartDateIfMarketCap = startDate.getTime() < startDateIfMarketCap.getTime();
                    if (timeSeriesInfo.timeSeriesKey.includes("market-capitalization") && startDateBeforeStartDateIfMarketCap) {
                        startDate = new Date("2018-12-01");
                    }
                    const thresHold = [ {
                        year: 2019,
                        thresHold: 990000000000,
                    }, {
                        year: 2020,
                        // 3 trillion
                        thresHold: 3000000000000,
                    }, {
                        year: 2023,
                        // 4 trillion
                        thresHold: 5000000000000,
                    }, {
                        year: 2024,
                        thresHold: 5000000000000,
                    } ];
                    timeSeries = result.map(e => {
                        const year = e._id.year as string;
                        const quarter = e._id.quarter === "Q1" ? "03" : e._id.quarter === "Q2" ? "06" : e._id.quarter === "Q3" ? "09" : "12";
                        const endOfQuarter = moment(`${year}-${quarter}`).endOf("quarter").toDate();
                        let startOfQuarter = moment(`${year}-${quarter}`).startOf("quarter").toDate();
                        if (startOfQuarter?.getTime() == null || endOfQuarter?.getTime() == null) return null;
                        // daily dates
                        const dates: Date[] = [];
                        while (startOfQuarter.getTime() <= endOfQuarter.getTime()) {
                            dates.push(startOfQuarter);
                            startOfQuarter = moment(startOfQuarter).add(1, "days").toDate();
                            if (startOfQuarter?.getTime() == null) break;
                        }
                        return e.companies.map(p => {
                            return dates.map(d => {
                                const value1 = p.avgValue;
                                if (timeSeriesInfo.timeSeriesKey.includes("market-capitalization")) {
                                    const thres = thresHold.find(t => t.year === e.year)?.thresHold ?? 3000000000000;
                                    if (thres != null && value1 > thres) {
                                        return null;
                                    }
                                }
                                const n = new TimeSeriesHelperValue();
                                n.symbol = p.symbol;
                                n.value = 1;
                                n.date = moment(d).format("YYYY-MM-DD");
                                n.d = new Date(d);
                                return n;
                            }).flat();
                        
                        }).flat().filter(x => x != null && x.d.getTime() >= startDate.getTime() && x.d.getTime() <= endDate.getTime());
                    }).flat();
                    // group timeSeries based on symbol, then find min date and max date and create TimeSeriesHelperValue for each date where there is no value
                    const joined = await this.toppListAdjusted(timeSeries, startDate, endDate, isSublist, metaInfo, select);
                    return joined.flat();
                }
            } else {
                if (isSwedishMarket) {
                    timeSeries = await swedishcompanies.aggregate([
                        { $match: { symbol: { $in: symbols } } }, { $unwind: "$" + timeSeriesInfo.field }, query,
                        { $project: { value: "$" + timeSeriesInfo.field + "." + timeSeriesInfo.column, date: "$" + timeSeriesInfo.field + "." + timeSeriesInfo.date, symbol: "$symbol" } },
                    ]).exec() as TimeSeriesHelperValue[];
                } else {
                    if (timeSeriesInfo?.field?.toLowerCase()?.includes("price")) {
                        await this.seedPriceBySymbols(symbols);
                    }
                    if (timeSeriesInfo.transformationKey.includes("BETA_DONE")) {
                        const result = await timeseries.aggregate([
                            {
                                $match: {
                                    ID: { $in: symbols },
                                    transformationKey: /BETA_DONE/i,
                                    main: true,
                                },
                            },
                            {
                                $lookup: {
                                    from: "timeserieshelpervalues",
                                    localField: "graphValue",
                                    foreignField: "_id",
                                    as: "graphValue",
                                },
                            },
                            { $unwind: "$graphValue" },
                            {
                                $project: {
                                    symbol: "$ID",
                                    value: "$graphValue.value",
                                    date: {
                                        $dateFromString: {
                                            dateString: "$graphValue.date",
                                            format: "%Y-%m-%d",
                                        },
                                    },
                                },
                            },
                            {
                                $group: {
                                    _id: {
                                        month: { $month: "$date" },
                                        year: { $year: "$date" },
                                    },
                                    topSymbols: { $push: "$$ROOT" },
                                },
                            },                          
                            {
                                $project: {
                                    _id: 0,
                                    year: "$_id.year",
                                    month: "$_id.month",
                                    topSymbols: {
                                        $slice: [
                                            {
                                                $sortArray: {
                                                    input: "$topSymbols",
                                                    sortBy: { value: -1 },
                                                },
                                            },
                                            symbols.length, 
                                        ],
                                    },
                                },
                            },
                        ]).exec();
                        const endDate = new Date(strictQuery.$lte);
                        const startDate = moment(strictQuery.$gte).toDate();
                        timeSeries = result.map(e => {
                            return e.topSymbols.map(p => {
                                const n = new TimeSeriesHelperValue();
                                n.symbol = p.symbol;
                                n.value = p.value;
                                n.date = moment(p.date).endOf("month").format("YYYY-MM-DD");
                                n.d = p.date;
                                return n;
                            }).filter(x => x.d.getTime() >= startDate.getTime() && x.d.getTime() <= endDate.getTime());
                        }).flat();
                        return timeSeries = this.appendTimeSeriesKey(this.cleanMultipleSymbolTimeSeries(timeSeries), timeSeriesInfo);
                    } else {
                        timeSeries = await companies.aggregate([
                            { $match: { symbol: { $in: symbols } } }, { $unwind: "$" + timeSeriesInfo.field }, query,
                            { $project: { value: "$" + timeSeriesInfo.field + "." + timeSeriesInfo.column, date: "$" + timeSeriesInfo.field + "." + timeSeriesInfo.date, symbol: "$symbol" } },
                        ]).exec() as TimeSeriesHelperValue[];
                    }
                        
                }
            }

            timeSeries = this.appendTimeSeriesKey(this.cleanMultipleSymbolTimeSeries(timeSeries), timeSeriesInfo);
            break;
        }
        }
        return this.appendTimeSeriesKey(timeSeries, timeSeriesInfo);
    }

    private async toppListAdjusted(timeSeries: TimeSeriesHelperValue[], startDate: Date, endDate: Date, isSublist: boolean, metaInfo: GeneralCategorize, select: number) {
        const grouped = makeObjectArrayToArrayOfArrayHelper(_.groupBy(timeSeries, "symbol")) as TimeSeriesHelperValue[][];
        const fromDate = await getDateFromLabelUtilities(moment(startDate).format("YYYY-MM-DD"));
        const toDate = await getDateFromLabelUtilities(moment(endDate).format("YYYY-MM-DD"));
        const dateRange = await this.datesService.getSteps(toDate, "day", fromDate);
        const fakeTs = dateRange.map(e => {
            const n = new TimeSeriesHelperValue();
            n.date = e.label;
            n.value = 0;
            n.symbol = "n";
            n.d = e.date;
            return n;
        });
        const joined = grouped.map(e => {
            const res = leftJoinByDate(fakeTs, e, false, 0, false, false, true);
            return res[1];
        });
        return joined;
    }

    public async seedPriceBySymbols(symbols: string[]) {
        const currentDay = moment().day();
        if (currentDay === 0 || currentDay === 6) {
            return;
        }
        // check if current time is after 16.00
        const currentHour = moment().hour();
        
        const lol = 1 < 16;
        if (lol) {
            return;
        }

        try {
            for (let i = 0; i < symbols.length; i++) {
                const company = await companies.findOne({ symbol: symbols[i], "price_x.date": moment().format("YYYY-MM-DD") });

                if (company) {
                    continue;
                }

                const stockRes = await axios.get(
                    `https://financialmodelingprep.com/api/v3/historical-price-full/${symbols[i]}?limit=100000&apikey=9273c02de90f666ee1baf7bb75033f71&from=2005-01-01`
                );
                await new Promise((resolve) => setTimeout(resolve, 300));
                const price = stockRes.data.historical;
                // update symbol in companies in price_x
                await companies.updateOne({ symbol: symbols[i] }, { price_x: price });
            }
        } catch (e) {
            // empty
        }
    }

    public async seedCommoditiesPrice(symbols: string[], currentDate?: ImpreemDate) {
        const currentDay = moment().day();
        if (currentDay === 0 || currentDay === 6) {
            return;
        }
        try {
            for (let i = 0; i < symbols.length; i++) {
                const com = await commodities.findOne({ symbol: symbols[i], date: moment().format("YYYY-MM-DD") });

                if (com) {
                    continue;
                }

                const stockRes = await axios.get(
                    `https://financialmodelingprep.com/api/v3/historical-price-full/${symbols[i]}?limit=100000&apikey=9273c02de90f666ee1baf7bb75033f71&from=2005-01-01`
                );
                await new Promise((resolve) => setTimeout(resolve, 300));
                const price = stockRes.data.historical.map(e => {
                    e.symbol = symbols[i];
                    return e;
                });
                await commodities.deleteMany({ symbol: symbols[i] });
                await commodities.insertMany(price);
            }
        } catch (error) {
            // empty
        }
    }

    public async seedIndexesPrice(symbols: string[], currentDate?: ImpreemDate) {
        const currentDay = moment().day();
        if (currentDay === 0 || currentDay === 6) {
            return;
        }
        try {
            for (let i = 0; i < symbols.length; i++) {
                const com = await indexes.findOne({ symbol: symbols[i], "price.date": moment().format("YYYY-MM-DD") });

                if (com) {
                    continue;
                }

                const stockRes = await axios.get(
                    `https://financialmodelingprep.com/api/v3/historical-price-full/${symbols[i]}?limit=100000&apikey=9273c02de90f666ee1baf7bb75033f71&from=2005-01-01`
                );
                await new Promise((resolve) => setTimeout(resolve, 300));
                const price = stockRes.data.historical.map(e => {
                    e.symbol = symbols[i];
                    return e;
                });
                await indexes.updateOne({ symbol: symbols[i] }, { price: price });
            }
        } catch (error) {
            // empty
        }
    }

    private cleanMultipleSymbolTimeSeries(timeSeries: TimeSeriesHelperValue[]): TimeSeriesHelperValue[] {
        const symbolGroups = new Map<string, TimeSeriesHelperValue[]>();

        // Group time series data by symbol
        for (const data of timeSeries) {
            if (!symbolGroups.has(data.symbol)) {
                symbolGroups.set(data.symbol, []);
            }
            symbolGroups.get(data.symbol)!.push(data);
        }

        const cleanedData: TimeSeriesHelperValue[] = [];

        // Clean and sort time series data for each symbol
        for (const [ symbol, data ] of symbolGroups.entries()) {
            if (data.length > 3) {
                // Remove duplicate dates
                const uniqueData = cleanDateArray(data, "date");

                // Sort by date if not already sorted
                if (!isSortedByDate(uniqueData)) {
                    sortByDate(uniqueData, "date");
                }

                cleanedData.push(...uniqueData);
            }
        }

        return cleanedData;
    }

    private groupByLength(strings: string[]): Record<number, string[]> {
        const grouped: Record<number, string[]> = {};

        for (const str of strings) {
            const length = str.length;
            if (grouped[length]) {
                grouped[length].push(str);
            } else {
                grouped[length] = [ str ];
            }
        }

        return grouped;
    }

    private async transformAllTimeSeriesBasedOnTransformationUniqueResultsFromDB(
        timeSeriesInfo: TimeSeriesDTO[],
        alignedTimeSeriesMatrix: TimeSeriesHelperValue[][],
        periodicity: Periodicity): Promise<TimeSeriesHelperValue[][]> {
        const clonedTs: TimeSeriesDTO[] = [];
        const res = alignedTimeSeriesMatrix.map((timeSeries) => {
            const transformationKey = timeSeries.find(e => e.transformationKey != null)?.transformationKey;
            const ts = timeSeriesInfo.find(e => e.transformationKey === transformationKey);
            if (ts == null) {
                return;
            }
            const key = transformationKey.split("-")[0] as TransformationTypeKey;
            const extra: TransformationMeta = new TransformationMeta();
            if (!ts.transformationKey.includes("BETA_DONE") && key === "beta" || ts?.transformations?.includes("beta")) {
                // check if ts.timeseries[0] exists in clonedTs
                const exists = clonedTs.find(e => e.transformationKey === ts.timeseries[0].transformationKey);
                const extraTs: TimeSeriesDTO = exists ?? cloneDeep(ts.timeseries[0]);
                if(!exists){
                    clonedTs.push(extraTs);
                }
                extra.extraTimeSeries = [ extraTs ];
                extra.extraTimeSeries[0].graphValue = alignedTimeSeriesMatrix.find(e => e[0].transformationKey === extraTs.transformationKey && (TimeSeriesManager.isCompanyTimeSeries(extraTs) ? e[0].symbol === extraTs.ID : true));
            }
            const dto = this.getTransformationSeries(ts, timeSeries, periodicity, extra);
            return dto;
        });
        const results = await Promise.all(res.filter(e => e != null));
        return results;
    }

    private async getAllTimeSeriesUsedInTestBySelected(
        allTimeseriesUsed: TimeSeriesDTO[],
        currentDate: ImpreemDate,
        lag: number,
        horizon: number,
        symbols: string[],
        allTimeSeriesFromDbInSession: TimeSeriesHelperValue[],
        explicitStartDate?: ImpreemDate,
        endDate?: ImpreemDate,
        startDate?: ImpreemDate
    ): Promise<TimeSeriesHelperValue[]> {
        const timeSeriesValues: TimeSeriesHelperValue[] = [];
        for (let p = 0; p < allTimeseriesUsed.length; p++) {
            const timeSeriesInfo = allTimeseriesUsed[p];
            let timeBackDate: ImpreemDate | null;
            let currentDateLagged: ImpreemDate | null;
            if (!endDate || !startDate) {
                const endOfDate = currentDate.step - convertPeriodictyToStep(timeSeriesInfo.periodicity) * lag;
                let timeBack = currentDate.step - convertPeriodictyToStep(timeSeriesInfo.periodicity) * horizon;
                if (timeBack < 0) {
                    timeBack = 0;
                }
                if (explicitStartDate) {
                    timeBack = explicitStartDate.step;
                }
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                if (isRouter) {
                    timeBackDate = await getDateFromStepUtilities(timeBack);
                    currentDateLagged = await getDateFromStepUtilities(endOfDate);
                } else {
                    timeBackDate = await getDateFromStep(timeBack, localUrl);
                    currentDateLagged = await getDateFromStepUtilities(endOfDate);
                }
            } else {
                timeBackDate = startDate;
                currentDateLagged = endDate;
            }
            if (currentDateLagged == null || timeBackDate == null) {
                continue;
            }
            const query = { $match: {} };
            const strictQuery = { $lte: currentDateLagged.label, $gte: timeBackDate.label };
            query.$match[`${timeSeriesInfo.field}.${timeSeriesInfo.date}`] = strictQuery;
            let timeSeries: TimeSeriesHelperValue[] = [];
            timeSeries = await this.getTimeSeries(timeSeriesInfo, timeSeries, symbols, query, strictQuery);
            // split by transformation key
            const ts = makeObjectArrayToArrayOfArrayHelper(_.groupBy(timeSeries, "transformationKey")) as TimeSeriesHelperValue[][];
            if (ts[0] == null) {
                continue;
            }

            ts.forEach(x => {
                const timeSeriesHelper: TimeSeriesHelperValue[] = this.appendTimeSeriesKey(x, {
                    transformationKey: timeSeriesInfo.collectionInDb === CCollectionTypes.newsWord ? x[0].transformationKey : timeSeriesInfo.transformationKey,
                    timeSeriesKey: timeSeriesInfo.collectionInDb === CCollectionTypes.newsWord ? x[0].timeSeriesKey : timeSeriesInfo.timeSeriesKey,
                    parameters: timeSeriesInfo.parameters,
                });
                const spreadHelper = groupArrayIntoGroups(timeSeriesHelper, 3);
                spreadHelper.forEach(t => {
                    timeSeriesValues.push(...t);
                });
            });

        }
        return timeSeriesValues;
    }

    private appendTimeSeriesKey(timeSeries: TimeSeriesHelperValue[], timeSeriesInfo: TimeSeriesDTO | { transformationKey: string; timeSeriesKey: string; parameters: any }): TimeSeriesHelperValue[] {
        return timeSeries.map(e => {
            const n = e;
            n.timeSeriesKey = timeSeriesInfo.timeSeriesKey;
            n.parameters = timeSeriesInfo.parameters;
            n.transformationKey = timeSeriesInfo.transformationKey;
            return n;
        });
    }

    private async includeComparisionSeries(allTimeseriesUsed: TimeSeriesDTO[], backtest: Backtest) {
        const needsComparision = allTimeseriesUsed.some(t => timeSeriesNeedsComparision.some(x => t.transformationKey.includes(x)));
        if (needsComparision) {
            await this.getIndexes(backtest.comparisonIndex, allTimeseriesUsed);
        }
    }

    private async getAnyTimeSeries(
        transformationKey: string,
        backtest: Backtest,
        currentDate: ImpreemDate,
        strategy: StrategyDTO,
        timeBack: ImpreemDate,
        symbols: string[] = [ "COST" ]
    ) {
        const timeSeries = await timeseries.findOne({ main: true, transformationKey: transformationKey }).exec() as unknown as TimeSeriesDTO | null;
        if (timeSeries == null) {
            return null;
        }
        timeSeries.transformationKey = transformationKey;
        const tsDto = await this.getTimeSeriesAndTransformation(
            backtest,
            timeSeries,
            symbols,
            currentDate,
            strategy,
            timeBack,
            backtest.periodicity);
        return tsDto;
    }

    private async getIndexes(comparisonIndexes: string[], allTimeseriesUsed: TimeSeriesDTO[]) {
        const findTimeSeries = await timeseriesmenu.findOne({ categoryTitle: "Indexes" }).exec() as unknown as MenuCategory;
        if (findTimeSeries) {
            for (let i = 0; i < comparisonIndexes.length; i++) {
                const ts = findTimeSeries.items.find(t => t.ID === comparisonIndexes[i]);
                if (ts) {
                    const values = await indexes.findOne({ symbol: ts.ID }).exec();
                    if (!values) {
                        continue;
                    }
                    ts.graphValue = values.price.map(t => {
                        const value = new TimeSeriesHelperValue();
                        value.value = t.adjClose;
                        value.d = moment(t.date).toDate();
                        // convert to YYYY-MM-DD
                        value.date = moment(t.date).format("YYYY-MM-DD");
                        return value;
                    });
                    allTimeseriesUsed.push(ts);
                }
            }
        }
    }
}
