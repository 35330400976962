import { Box, Button, Divider, Flex, Heading, useColorMode, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Backtest } from '../../backtesting-common-frontend';
import { getPeriodicityTabsOptions } from '../../backtesting-common-frontend/shared/tab.utilities';
import { cloneDeep } from '../../backtesting-common-frontend/shared/utilites/object.utilities';
import DateSettings from '../../components/settings/dates-settings';
import { Tab as TabsComponent } from '../../components/tabs';
import { AppState } from '../../store/store';

export default function SettingsScreen() {
    const dispatch = useDispatch();
    const backtest = useSelector((state: AppState) => state.backtests.backtest);
    const [ currentPeriodicity, setCurrentPeriodicity ] = React.useState<TabsComponent | null>(null);
    const [ periodicityOptions ] = React.useState<TabsComponent[]>(getPeriodicityTabsOptions());
    const [ currentBacktest, setCurrentBacktest ] = React.useState<Backtest | null>(null);
    const [ periodicityChanged, setPeriodicityChanged ] = React.useState<boolean>(false);

    const { toggleColorMode } = useColorMode();

    React.useEffect(() => {
        if(backtest){
            setCurrentBacktest(cloneDeep(backtest));
        }
    }, [ backtest ]);

    React.useEffect(() => {
        const perTab = periodicityOptions.find(p => p.Id === currentBacktest?.periodicity);
        if (perTab) {
            setCurrentPeriodicity(perTab);
        }
    }, [ currentBacktest, periodicityOptions ]);

    return (
        <div style={{  height: '100vh' }}>
            <Flex mt={4} p={2}>
                <Box flex="1" p="4"   
                    borderRadius="md"
                    marginInlineEnd={10}
                    boxShadow="md">
                    {periodicityChanged && <>
                        <Box bg='rebeccapurple' w='100%' p={4} color='white'>
                            <Heading size="md">Periodicity changed warning</Heading>
                            <p>Remember that transformation will not change after this apply.</p>
                            <p>This means that some transformations on the time series might not fit the new periodicty.</p>
                        </Box>
                        <Divider mt={5} mb={5} />
                    </>}
                    <Box bg='tomato' w='100%' p={4} color='white'>
                        <Heading size="md">Long periods</Heading>
                        <p>Remember that very long periods might take a long time to process.</p>
                        <p>Be patient and let the process finish.</p>
                        <p>A tip is to build blocks of backtest results to reuse them in other backtests.</p>
                    </Box>
                </Box>

                <Box flex="2" p="4"   bg={'whiteAlpha.100'}
                    borderRadius="md"
                    marginInlineEnd={10}
                    boxShadow="md">
                    <Button
                        w={'full'}
                        bg={useColorModeValue('#151f21', 'gray.900')}
                        color={'white'}
                        rounded={'md'}
                        _hover={{
                            transform: 'translateY(-2px)',
                            boxShadow: 'lg',
                        }} onClick={() => {
                            toggleColorMode();
                        } }>
                Toggle dark mode
                    </Button>
                    <Flex direction="row" justify="space-between">
                        <Box flex="2" p="4" bg='whiteAlpha.100' borderRadius="md" marginInlineEnd={10} boxShadow="md">
                            <Heading size="md">Date settings</Heading>
                            <DateSettings />
                        </Box>

                        {/* <Box flex="2" p="4" bg='whiteAlpha.100' borderRadius="md" marginInlineEnd={10} boxShadow="md">
                            <Heading size="md">Periodicity</Heading>
                            <>
                                {currentPeriodicity != null && (
                                    <>
                                        <Tabs onChange={async(index) => {
                                            const perTab = periodicityOptions[index] as TabsComponent;
                                            if (perTab) {
                                                currentBacktest.periodicity = perTab.Id as Periodicity;
                                                const status = new StatusDisplayDTO("Updating periodicity", "info");
                                                dispatch(updateMessage(status));
                                                dispatch(setLoading(true));
                                                await updateBacktest(currentBacktest).finally(() => {
                                                    dispatch(setLoading(false));
                                                    const status = new StatusDisplayDTO("Periodicity updated", "success");
                                                    dispatch(updateMessage(status));
                                                    setPeriodicityChanged(true);
                                                });
                                            }
                                        }} defaultIndex={periodicityOptions.findIndex(option => option === currentPeriodicity)}>
                                            <>
                                                <TabList>
                                                    {periodicityOptions.map((option, index) => (
                                                        <Tab key={index}>{option.Name}</Tab>
                                                    ))}
                                                </TabList>
                                            </>
                                        </Tabs>
                                    </>
                                )}
                            </>
                        </Box> */}
                    </Flex>
                </Box>
                <Box flex="1" p="4"  
                    borderRadius="md"
                    marginInlineEnd={10}
                    boxShadow="md">
                </Box>
            </Flex>
        </div>
    );
    
}

