/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/dot-notation */
import mongoose from "mongoose";
const Schema = mongoose.Schema;

const testSchema = new Schema({
    comparisonSeries: Schema.Types.Mixed,
    evaluationMethods: Schema.Types.Mixed,
    allTimeseriesUsed: Schema.Types.Mixed,
    transformTimeseries: Schema.Types.Mixed,
    startDate: Schema.Types.Mixed,
    endDate: Schema.Types.Mixed,
    strategies: [{ type: Schema.Types.ObjectId, ref: "strategies", required: true }],
    created: { type: Date, default: Date.now },
    methods: [{
        type: mongoose.Schema.Types.ObjectId,
        ref: "methods"
    }],
    clientId: { type: Schema.Types.ObjectId, ref: "clients", required: true }
});

testSchema.pre("findOneAndDelete", async function (next) {
    try {
        const test = this;
        const methodsIds = test["methods"].map(m => m["_id"]);
        // Delete all methods associated with the test
        await mongoose.model("methods").deleteMany({ _id: { $in: methodsIds } });
        next();
    } catch (error) {
        next();
    }
});

const tests = mongoose.model("tests", testSchema);

export default tests;
