import { createSlice } from '@reduxjs/toolkit';
import { StopLossTakeProfit } from '../../backtesting-common-frontend/database/stoplosstakeprofits';

export class StopLossTakeProfitStore {
    stopLossTakeProfits: StopLossTakeProfit[] = [];
}

const initialState: StopLossTakeProfitStore = {
    stopLossTakeProfits: [],
};

const stopLossTakeProfitsSlice = createSlice({
    name: 'stoplosstakeprofits',
    initialState,
    reducers: {
        addStopLossTakeProfit: (state, action) => {
            state.stopLossTakeProfits.push(action.payload);
        },
        removeStopLossTakeProfit: (state, action) => {
            state.stopLossTakeProfits = state.stopLossTakeProfits.filter((e) => e._id !== action.payload._id);
        },
    },
});

export const { addStopLossTakeProfit, removeStopLossTakeProfit } = stopLossTakeProfitsSlice.actions;
export const stopLossTakeProfitsReducer = stopLossTakeProfitsSlice.reducer;