const isFrontend = typeof window !== 'undefined';

export const env = isFrontend ? {
    "url": "http://localhost:3001/",
    "routerUrl": "https://app.edvardwo.com/",
    "rWorkerUrl": "http://localhost:3003/",
    "socketUrl": "https://sockets.edvardwo.com/",
    "homePage": "http://127.0.0.1:8080/",
    "localhost": "http://127.0.0.1/",
} : {
    "url": "http://localhost:3001/",
    "routerUrl": "http://localhost/",
    "rWorkerUrl": "http://localhost:3003/",
    "socketUrl": "http://localhost:3002/",
    "homePage": "http://127.0.0.1:8080/",
    "localhost": "http://localhost:3003/",
};
