import mongoose from "mongoose";
const Schema = mongoose.Schema;

const companiesSchema = new Schema({}, { strict: false });

export const companies = mongoose.model("companies", companiesSchema);

const pricesSchema = new Schema({}, { strict: false });

export const companyprices = mongoose.model("prices", pricesSchema);

export const companyprofiles = mongoose.model("profiles", pricesSchema);

export class CompanyProfile {
    _id: string;
    symbol: string;
    price: number;
    beta: number;
    volAvg: number;
    mktCap: number;
    lastDiv: number;
    changes: number;
    companyName: string;
    currency: string;
    cik: string;
    isin: string;
    cusip: string;
    exchange: string;
    exchangeShortName: string;
    industry: string;
    website: string;
    description: string;
    ceo: string;
    sector: string;
    country: string;
    fullTimeEmployees: string;
    phone: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    dcfDiff: number;
    dcf: number;
    image: string;
    ipoDate: string;
    defaultImage: boolean;
    isEtf: boolean;
    isActivelyTrading: boolean;
    isAdr: boolean;
    isFund: boolean;
      
    constructor(data: Partial<CompanyProfile>) {
        Object.assign(this, data);
    }
}

export class Company {
    _id: string;
    symbol: string;
    price_x: {adjClose: number, date: string}[];
    price_y: number;
    beta: number;
    volAvg: number;
    mktCap: number;
    lastDiv: number;
    range: string;
    changes: number;
    companyName: string;
    currency: string;
    cik: string;
    isin: string;
    cusip: string;
    exchange: string;
    exchangeShortName: string;
    industry: string;
    website: string;
    description: string;
    ceo: string;
    sector: string;
    country: string;
    fullTimeEmployees: string;
    phone: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    dcfDiff: number;
    dcf: number;
    image: string;
    ipoDate: string;
    defaultImage: boolean;
    isEtf: boolean;
    isActivelyTrading: boolean;
    isAdr: boolean;
    isFund: boolean;
    "income-statement": any[];
    "income-statement-quarter": any[];
    "income-statement-growth": any[];
    "income-statement-as-reported": any[];
    "income-statement-as-reported-quarter": any[];
    ratios: any[];
    "ratios-quarter": any[];
    "enterprise-values": any[];
    "enterprise-values-quarter": any[];
    "historical-rating": any[];
    "historical-discounted-cash-flow-statement": any[];
    "historical-discounted-cash-flow-statement-quarter": any[];
    "historical-market-capitalization": any[];
    news: any[];
    grade: any[];
    historicalDividend: any[];
    historicalSplit: any[];
    newsCount: any[];
    newsUnbias: any[];
  
    constructor(data: Partial<Company>) {
        Object.assign(this, data);
    }
}
