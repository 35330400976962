import mongoose from "mongoose";
const Schema = mongoose.Schema;

const stoplosstakeprofitsSchema = new Schema({
    takeProfitProcentageChange: { type: Number },
    stopLossProcentageChange: { type: Number },
    backtestId: { type: Schema.Types.ObjectId, ref: "backtests" }
});

export class StopLossTakeProfit {
    public _id: mongoose.Types.ObjectId;
    public takeProfitProcentageChange: number;
    public stopLossProcentageChange: number;
    public backtestId: mongoose.Types.ObjectId;
}

stoplosstakeprofitsSchema.loadClass(StopLossTakeProfit);

export const stoplosstakeprofits = mongoose.model("stoplosstakeprofits", stoplosstakeprofitsSchema);


