import { Box, Button, Heading, Input, VStack } from '@chakra-ui/react';
import { MathJax } from 'better-react-mathjax';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addHoldValue } from '../../action-creators/criteria-board/criteria-board.actions';
import { createOrEditNewMehtodOrCurrent } from '../../action-creators/methods/methods.actions';
import { createFakeGraphItem, goldTimeSeries } from '../../action-creators/timeseries/time-series.action';
import { BacktestingSetting, GraphItem } from '../../backtesting-common-frontend';
import { env } from '../../backtesting-common-frontend/env';
import { getHistoricalEvents } from '../../backtesting-common-frontend/http-utilities/http-utilities/dates/dates.http.service';
import { cloneDeep } from '../../backtesting-common-frontend/shared/utilites/object.utilities';
import { StatusDisplayDTO } from '../../backtesting-common-frontend/status/error-handling';
import { StrategyDTO } from '../../backtesting-common-frontend/strategies/strategy';
import { CriteriaBoardMethod, CriteriaBoardOptionSelect, CriteriaDateDTO } from '../../backtesting-common-frontend/techniques';
import { TheoryMethodService } from '../../backtesting-common-frontend/techniques/theory-method.service';
import { MethodsResultsManager } from '../../managers/methods/methods-manager';
import { setLoading, updateMessage } from '../../store/backtests/backtests';
import { editGraphItemRedux } from '../../store/graphitems/graphitems';
import { AppState } from '../../store/store';
import AddMethodHelper from '../methods/add-method-helper';
import { ExplainerInfo } from '../shared/explainer/info';
import { RowTableDTO } from '../table';
import TableComponent from '../table/table';

const routerUrl = env.routerUrl;

export const monthsList = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December",
];

export const HistoricalEvents = () => {
    const dispatch = useDispatch();
    const currentReduxStrategy = useSelector(
        (state: AppState) => state.strategies.strategy
    );
    const backtest = useSelector((state: AppState) => state.backtests.backtest);
    const theoryActiveCtx = useSelector((state: AppState) => state.graphItems.theoryActive);
    const currentTab = useSelector((state: AppState) => state.tabs.currentMainTab);
    const graphItems = useSelector((state: AppState) => state.graphItems.graphItems);
    const test = useSelector((state: AppState) => state.tests.test);
    const [ dates, setDates ] = useState<CriteriaDateDTO[]>([]);
    const [ currentStrategy, setCurrentStrategy ] = useState<StrategyDTO | null>(null);
    const [ currentTest, setCurrentTest ] = useState<BacktestingSetting | null>(null);
    const [ theoryMethodService ] = useState<TheoryMethodService>(new TheoryMethodService());
    const [ eventName, setEventName ] = useState<string>("Bank crisis");

    useEffect(() => {
        setCurrentStrategy(cloneDeep(currentReduxStrategy));
    }, [ currentReduxStrategy ]);

    useEffect(() => {
        const t = cloneDeep(test);
        if(t){
            setCurrentTest(t);
        }
    }, [ test ]);

    useEffect(() => {
        if(currentTest && currentTab){
            const currentMethod = MethodsResultsManager.getCurrentMethod(currentTest, currentTab);
            if(currentMethod){
                const work = currentMethod.parameters as CriteriaBoardMethod | undefined;
                if(work){
                    setDates(work.dates);
                }
            }
        }
    }, [ currentTest, currentTab ]);

    const addMethod = async(name: string, update: boolean, test?: boolean, graphItemsTest?: GraphItem[], holdValue?: string) => {
        if(!currentTest || !currentTab) return;
        const currentMethod = MethodsResultsManager.getCurrentMethod(currentTest, currentTab);
        let work: CriteriaBoardMethod | undefined = currentMethod?.parameters as CriteriaBoardMethod | undefined;
        const options = new CriteriaBoardOptionSelect();
        options.dates = true;
        const w = new CriteriaBoardMethod("Theory", options);
        w.dates = dates.map(e => {
            e.months = e.months.map(e => {
                e.value = +e.value;
                return e;
            });
            return e;
        });
        addHoldValue(holdValue, w);
        work = w;
        const dtoGraphItem: GraphItem  = createFakeGraphItem(goldTimeSeries);
        if(test && dtoGraphItem && work){
            const res = theoryMethodService.addBuyLabels([ dtoGraphItem?.timeSeries ], work);
            dtoGraphItem.timeSeries = res[0];
            dispatch(editGraphItemRedux(dtoGraphItem));
        }else if(dtoGraphItem){
            createOrEditNewMehtodOrCurrent(
                dispatch,
                currentTest,
                currentTab,
                new MethodsResultsManager(),
                work,
                name,
                dtoGraphItem,
                [ dtoGraphItem?.timeSeries ],
                currentStrategy,
                null,
                undefined,
                update === false ? true : false,
                "Theory"
            );
        }

    };

    const activeGraphItem = graphItems.find((e) => theoryActiveCtx.includes(e._id));

    const rows: RowTableDTO[] = [];
    dates.forEach((date, index) => {
        rows.push({
            columns: [
                {
                    title: index === 0 ? "Year" : null,
                    value: date.year,
                },
                {
                    title: index === 0 ? "Months" : null,
                    value: date.months.length > 0 ? date.months.map(e => e.label ?? "").join(", ") : "",
                },
                {
                    title: "Remove",
                    value: <Button onClick={() => setDates(dates.filter((e, i) => i !== index))}>Remove</Button>,
                },
            ],
        });
    });

    const disabled = dates.length === 0;

    return (
        <VStack align="start" spacing={8}>
            <ExplainerInfo.Information
                title="Historical Events Method"
                text={
                    <MathJax>
                        {`
                The "Historical Events" method generates buy signals based on the occurrence of significant historical events.

                **Algorithm:**
                1. Define a list of historical events \\( E \\) and their dates.
                2. For each event date \\( e \\) in \\( E \\):
                   \\[
                   \\text{generate a buy signal on } e
                   \\]
            `}
                    </MathJax>
                }
            />
            <Heading size="md">Write a historical event</Heading>
            <Box key={"yearMonthSelec"}>
                <Input placeholder='A event name' value={eventName} onChange={(value) => {
                    setEventName(value.target.value);
                }} />
            </Box>
            <Button colorScheme="teal" onClick={async() => {
                dispatch(setLoading(true));
                const d = await getHistoricalEvents(eventName, routerUrl);
                dispatch(setLoading(false));
                if(d){
                    setDates(d);
                }else{
                    const status = new StatusDisplayDTO("Could not find event", "error");
                    dispatch(updateMessage(status));
                }
            }
            }>Search</Button>
            <TableComponent rows={rows} />
            <Box margin={2} width={'full'}>
                <AddMethodHelper 
                    useHoldValue={true}
                    disabledText={'Missing date or time series'} testDisabled={activeGraphItem != null} callback={(name, update, holdValue) => {
                        addMethod(name, update, false, undefined, holdValue);
                    }} disabled={disabled} callbackTest={(graphItemsTest, holdValue) => {
                        return new Promise<void>((resolve, _reject) => {
                            setTimeout(() => {
                                addMethod("", false, true, graphItemsTest, holdValue);
                            }, 500);
                            setTimeout(() => {
                                resolve();
                            }, 1500);
                        });
                    }} />
            </Box>
        </VStack>
    );
};
