/* eslint-disable @typescript-eslint/no-empty-function */
/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import axios from 'axios';
import * as React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { updateBacktestsGlobally } from '../action-creators/backtests/backtests.actions';
import { setActiveGraphProperties } from '../action-creators/graph-items/graph-items';
import { Backtest, BacktestingSetting } from '../backtesting-common-frontend';
import { licenseType } from '../backtesting-common-frontend/clients/clients';
import { env } from '../backtesting-common-frontend/env';
import { AuthResponse, isBacktestLicense } from '../backtesting-common-frontend/http-utilities/auth/auth-http-utilities';
import { getBacktests } from '../backtesting-common-frontend/http-utilities/http-utilities/backtests/backtests-backend.service';
import { readGraphItems } from '../backtesting-common-frontend/http-utilities/http-utilities/graph-items/graph-items.http';
import { sendInsightsData } from '../backtesting-common-frontend/http-utilities/http-utilities/insights/insights';
import { getNews } from '../backtesting-common-frontend/http-utilities/http-utilities/news/news.http.service';
import {
    myProgress,
    removeProgress,
} from '../backtesting-common-frontend/http-utilities/http-utilities/progress/progress.service';
import { getNewsBacktests } from '../backtesting-common-frontend/http-utilities/http-utilities/strategy/strategy';
import { getAllTags } from '../backtesting-common-frontend/http-utilities/http-utilities/tags/tags.http.service';
import { getTimeSeries } from '../backtesting-common-frontend/http-utilities/http-utilities/time-series/time-series-backend.service';
import { getTransformations } from '../backtesting-common-frontend/http-utilities/http-utilities/transformations/transformations-backend.service';
import { MenuCategory } from '../backtesting-common-frontend/menu/dtos/menu-dtos';
import { cloneDeep } from '../backtesting-common-frontend/shared/utilites/object.utilities';
import { mergeNestedCapitalLetters, splitByCapitalLetter } from '../backtesting-common-frontend/shared/utilites/string.utilities';
import { sleep } from '../backtesting-common-frontend/shared/utilites/utilities';
import { InProgressDTO } from '../backtesting-common-frontend/status/inprogress-dto';
import { StrategyDTO } from '../backtesting-common-frontend/strategies/strategy';
import BacktestQueue from '../components/backtests/backtest-queue';
import ProgressMain from '../components/backtests/progress';
import LazyLoading from '../components/lazy-loading/lazy-loding';
import HomeMenu from '../components/shared/menu/home-menu';
import StatusDisplayComponent from '../components/shared/status-display/status-display.component';
import SupportChat from '../components/support/support';
import { MenuComponentDTO } from '../dtos/menu';
import LoginScreen from '../screens/auth/login-screen';
import BacktestScreen from '../screens/backtest/backtest-screen';
import LogoutScreen from '../screens/logout/logout-screen';
import TheoryScreen from '../screens/methods/theory-screen';
import NewsImpactScreen from '../screens/news/news-screen';
import PortfolioAllocationScreen from '../screens/portfolio-allocation/portfolio-allication-screen';
import HomeScreen from '../screens/project/home-screen';
import ProfileScreen from '../screens/screening/profile-screen';
import { ScreeningScreen } from '../screens/screening/screening-screen';
import SettingsScreen from '../screens/settings/settings-screen';
import TransformationScreen from '../screens/transformations/transformations-screen';
import { socket } from '../socket/socket';
import { addOrUpdateClient } from '../store/auth/client';
import { addNewsBacktests, setLoading, updateBacktestInBacktests, updateBacktestStore, updateBacktestsStore, updateMenuCategory, updateTags } from '../store/backtests/backtests';
import { bulkAddAndOverwriteGraphItemRedux } from '../store/graphitems/graphitems';
import { addNews } from '../store/news/news';
import { AppState } from '../store/store';
import { updateStrategyStore } from '../store/strategies/strategy';
import { updateTestStore } from '../store/tests/tests';
import { updateTransformations } from '../store/transformations/transformations';

function getCampaignQueryParam() {
    const queryParams = new URLSearchParams(window.location.search);

    return queryParams.get('campaign');
}

export class BacktestDTO {
    public backtests: Backtest[] = [];
}

export class ProgressHolderDTO {
    public progress: InProgressDTO[] = [];
}

export default function Navigation() {
    const dispatch = useDispatch();
    const clientFromState = useSelector((state: AppState) => state.client?.client);
    const shouldCheck = useSelector((state: AppState) => state.client?.shouldCheckUptade);
    const backtests = useSelector((state: AppState) => state.backtests.backtests);
    const [ check, setCheck ] = useState<boolean>(true);

    const [ client, setClient ] = useState<AuthResponse | null>(null);
    const [ progress, setProgress ] = useState<ProgressHolderDTO>({ progress: [] });
    const [ requestedSelectionFilters, setRequestedSelectionFiltersMenu ] = useState<boolean>(false);

    const [ everythingHasLoaded, setEverythingHasLoaded ] = useState<number>(0);
    const [ hasLoadedBacktestsAndClient, setHasLoadedBacktestsAndClient ] = useState<boolean>(false);
    const [ hasGottenTimeSeries, setHasGottenTimeSeries ] = useState<boolean>(false);

    React.useEffect(() => {
        const id = getCampaignQueryParam();
        const item = localStorage.getItem('user');
        const client = item ? JSON.parse(item) as AuthResponse : null;
        if (id && client) {
            sendInsightsData(id, client);
        }
    }, [ ]);

    React.useEffect(() => {
        myProgress()
            .then((p) => {
                setProgress({ progress: p });
            })
            .catch(() => { });
    }, []);

    React.useEffect(() => {
        if(clientFromState){
            setClient(clientFromState);
        }
    }, [ clientFromState ]);

    React.useEffect(() => {
        let interval: NodeJS.Timer | null = null;
        if (progress.progress.length > 0) {
            interval = setInterval(() => {
                const currentDate = new Date();
                setProgress((progress) => ({
                    progress: progress.progress
                        .filter((t) => {
                            const diffInMinutes =
								(currentDate.getTime() - new Date(t.created).getTime()) / 60000;
                            if (diffInMinutes > 30) {
                                removeProgress(t)
                                    .then(() => { })
                                    .catch(() => { });
                                return false;
                            }
                            return true;
                        })
                        .map((e) => {
                            if (e['progress'] == null) {
                                e['progress'] = 0;
                            }
                            e.progress = e.progress + 5 / 10;
                            if (e.progress > 100) {
                                e.progress = 100;
                            }
                            return e;
                        }),
                }));
            }, 1000);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [ progress ]);

    React.useEffect(() => {
        if(check){
            setCheck(false);
            const item = localStorage.getItem('user');
            if(item){
                const storeAuth = JSON.parse(item) as AuthResponse;
                if(storeAuth?.token == null){
                    return;
                }
                dispatch(setLoading(true));
                axios({
                    url: env.routerUrl + 'clients/check-valid-token',
                    method: 'POST',
                    headers: {
                        authorization: 'bearer ' + storeAuth.token,
                    },
                }).then(async(data) => {
                    const license = data.data?.license as licenseType;
                    storeAuth.license = license;
                    localStorage.setItem('user', JSON.stringify(storeAuth));

                    // check browser history to /home
                    // window.history.pushState({}, '', '/home');
                    setClient(storeAuth);
                    dispatch(addOrUpdateClient(storeAuth));
                    let tryAgain = true;
                    let connected = false;
                    while (connected === false) {
                        if (socket?.socket?.connected) {
                            if (tryAgain) {
                                tryAgain = false;
                                getBacktests()
                                    .then((data) => {
                                        connected = true;
                                        tryAgain = false;
                                        setEverythingHasLoaded(stat => stat + 1);
                                        dispatch(updateBacktestsStore(data));
                                        setHasLoadedBacktestsAndClient(true);
                                    })
                                    .catch(() => {
                                        tryAgain = true;
                                    });
                            }
                        }
                        console.log('Trying to connect');
                        await sleep(1000);
                    }
                    getNewsBacktests().then((data) => {
                        dispatch(addNewsBacktests(data));
                    }).catch(() => {
                        // empty
                    });
                }).finally(() => {
                    dispatch(setLoading(false));
                });
            
            }
        }
    }, [ check, dispatch ]);

    React.useEffect(() => {
        if(shouldCheck){
            setCheck(true);
        }
    }, [ shouldCheck ]);

    if (client) {
        if (socket?.socket?.connected == null) {
            socket.renderSocket(client.clientId);
            socket?.socket?.on('backtests', (msg: Backtest[]) => {
                console.log('backtests', msg);
                updateBacktestsGlobally(dispatch, msg);
            });
            socket?.socket?.on('backtests-force-new', (msg: Backtest[]) => {
                dispatch(updateBacktestsStore(msg));
            });
            socket?.socket?.on('progress', () => {
                myProgress()
                    .then((p) => {
                        setProgress({ progress: p });
                    })
                    .catch(() => { });
            });
        
            socket?.socket?.on("backtest", (msg: Backtest) => {
                dispatch(updateBacktestInBacktests(msg));
                if (msg.activeFrontend) {
                    dispatch(updateBacktestStore(msg));
                    dispatch(updateTestStore(msg?.test));
                    const s = msg?.test?.strategies?.find(x => x.active);
                    if(s){
                        dispatch(updateStrategyStore(s));
                    }
                }
            });
        
            socket?.socket?.on("tests", (msg: BacktestingSetting) => {
                dispatch(updateTestStore(msg));
            });
        
            socket?.socket?.on("strategies", (msg: StrategyDTO) => {
                dispatch(updateStrategyStore(msg));
            });
        }
    }

    React.useEffect(() => {
        if(client && !requestedSelectionFilters){
            setRequestedSelectionFiltersMenu(true);
            getTimeSeries().then(res => {
                setHasGottenTimeSeries(true);
                setEverythingHasLoaded(stat => stat + 1);
                const menuDTO = new MenuComponentDTO();
                menuDTO.type = "method";
                menuDTO.menu = res;
                let resResult = cloneDeep(menuDTO.menu) as MenuCategory[];
                resResult = resResult.map((p, x) => {
                    p.items = p.items.map((e, i) => {
                        e.display = mergeNestedCapitalLetters(splitByCapitalLetter(e.display)).replace(/\s{2,}/g, " ").trim() ?? "";
                        if (x === 0 && i === 0) {
                            e.active = true;
                        } else {
                            e.active = false;
                        }
                        return e;
                    });
                    return p;
                });
                dispatch(updateMenuCategory(resResult));
            }).catch(() => {});
            getTransformations().then(res => {
                setEverythingHasLoaded(stat => stat + 1);
                dispatch(updateTransformations(res));
            }).catch(() => {});
            readGraphItems().then(res => {
                if(res){
                    setEverythingHasLoaded(stat => stat + 1);
                    dispatch(bulkAddAndOverwriteGraphItemRedux(res));
                    setActiveGraphProperties(res, dispatch);
                }
            }).catch(() => {});
            getAllTags().then(res => {
                if(res){
                    dispatch(updateTags(res));
                }
            }).catch(() => {});
            getNews().then(res => {
                dispatch(addNews(res));
            }).catch(() => {});
        }
    }, [ client, dispatch, requestedSelectionFilters ]);

    const isLoading = everythingHasLoaded < (isBacktestLicense() ? 4 : 3) || !hasLoadedBacktestsAndClient || !hasGottenTimeSeries || backtests.length === 0;

    const hashRouter = <HashRouter>
        {HomeMenu()}
        <Routes>
            {isBacktestLicense() && <Route path="/" element={<LazyLoading  isLoadingGlobal={isLoading} full={true}><HomeScreen /></LazyLoading>} />}
            <Route path={isBacktestLicense() ? "/backtesting" : "/"} element={<LazyLoading forceHide={isLoading}  isLoadingGlobal={isLoading} full={true}><TheoryScreen /></LazyLoading>} />
            <Route path="/transformations" element={<LazyLoading  isLoadingGlobal={isLoading} full={true}><TransformationScreen /></LazyLoading>} />
            {isBacktestLicense() && <>
                <Route path="/run-backtest" element={<LazyLoading  isLoadingGlobal={isLoading} full={true}><BacktestScreen /></LazyLoading>} />
                <Route path="/news" element={<LazyLoading  isLoadingGlobal={isLoading} full={true}><NewsImpactScreen /></LazyLoading>} />
                <Route path="/portfolio-allocation" element={<LazyLoading  isLoadingGlobal={isLoading} full={true}><PortfolioAllocationScreen /></LazyLoading>} />
                <Route path="/settings" element={<LazyLoading  isLoadingGlobal={isLoading} full={true}><SettingsScreen /></LazyLoading>} />
            </>}
            <Route path="/screening" element={<LazyLoading  isLoadingGlobal={isLoading} full={true}><ScreeningScreen /></LazyLoading>} />
            <Route path="/profile" element={<LazyLoading  isLoadingGlobal={isLoading} full={true}><ProfileScreen /></LazyLoading>} />
            <Route path="/logout" element={<LazyLoading  isLoadingGlobal={isLoading} full={true}><LogoutScreen /></LazyLoading>} />
        </Routes>
    </HashRouter>;

    return (
        <>
            {client != null ? hashRouter : <LoginScreen />}
            <StatusDisplayComponent />
            <ProgressMain progressDtos={progress.progress} />
            <SupportChat />
            <BacktestQueue />
        </>

    );
}
