import { CCollectionTypes } from "..";
import { ImpreemMethodDTO, MethodDTO, TimeSeriesDTO } from "../methods";

export class TimeSeriesManager {

    public static isCompanyTimeSeries(x: ImpreemMethodDTO | TimeSeriesDTO): boolean {
        if (this.isTimeSeries(x)) {
            return x.transformationKey.includes("companies") || x.collectionInDb === CCollectionTypes.newsSentiments || x.collectionInDb === CCollectionTypes.newsMentions;
        }
        return x.timeseries.some(x => x.transformationKey.includes("companies") || x.collectionInDb === CCollectionTypes.newsSentiments || x.collectionInDb === CCollectionTypes.newsMentions);
    }

    public static isTimeSeries(item: TimeSeriesDTO | ImpreemMethodDTO | MethodDTO | unknown): item is TimeSeriesDTO {
        return item != null && (item as TimeSeriesDTO).transformationKey !== undefined;
    }

}