import { Box, Button, Heading, Select, VStack } from '@chakra-ui/react';
import { MathJax } from 'better-react-mathjax';
import { MultiSelect, useMultiSelect } from 'chakra-multiselect';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addHoldValue } from '../../action-creators/criteria-board/criteria-board.actions';
import { createOrEditNewMehtodOrCurrent } from '../../action-creators/methods/methods.actions';
import { createFakeGraphItem, goldTimeSeries } from '../../action-creators/timeseries/time-series.action';
import { BacktestingSetting, GraphItem } from '../../backtesting-common-frontend';
import { MonthIndexArray } from '../../backtesting-common-frontend/shared/utilites/dates.utilities';
import { seq_along } from '../../backtesting-common-frontend/shared/utilites/math.utilities';
import { cloneDeep } from '../../backtesting-common-frontend/shared/utilites/object.utilities';
import { StrategyDTO } from '../../backtesting-common-frontend/strategies/strategy';
import { CriteriaBoardMethod, CriteriaBoardOptionSelect, CriteriaDateDTO } from '../../backtesting-common-frontend/techniques';
import { TheoryMethodService } from '../../backtesting-common-frontend/techniques/theory-method.service';
import { MethodsResultsManager } from '../../managers/methods/methods-manager';
import { editGraphItemRedux } from '../../store/graphitems/graphitems';
import { AppState } from '../../store/store';
import AddMethodHelper from '../methods/add-method-helper';
import InfoTooltip from '../shared/explainer/explainer';
import { ExplainerInfo } from '../shared/explainer/info';
import { RowTableDTO } from '../table';
import TableComponent from '../table/table';

const YearSelector = ({ onAdd }) => {
    const [ selectedYear, setSelectedYear ] = useState<number | null>(null);
    const { value, options, onChange } = useMultiSelect({
        value: [],
        options: MonthIndexArray.map((month, index) => ({ value: month.index.toString(), label: month.name })),
    });

    const { value: valueWeek, options: optionsWeek, onChange: onChangeWeek } = useMultiSelect({
        value: [],
        options: seq_along(4).map((index) => ({ value: (index + 1).toString(), label: (index + 1).toString() })),
    });

    const handleAdd = () => {
        onAdd(new CriteriaDateDTO(selectedYear, value as any, valueWeek as any));
    };

    return (
        <VStack align="start" spacing={4}>
            <Select
                placeholder="Select a year"
                value={selectedYear}
                onChange={(e) => setSelectedYear(parseInt(e.target.value))}
            >
                {Array.from({ length: new Date().getFullYear() - 1999 }, (_, index) => 2000 + index).map((year) => (
                    <option key={year} value={year}>
                        {year}
                    </option>
                ))}
            </Select>
            {onChange && <MultiSelect
                options={options}
                value={value}
                label='Choose months'
                onChange={onChange}
                create
            />}
            {onChange && <MultiSelect
                options={optionsWeek}
                value={valueWeek}
                label='Choose weeks'
                onChange={onChangeWeek}
                create
            />}
            <Button colorScheme="teal" onClick={handleAdd}>
                Add
            </Button>
        </VStack>
    );
};

export const DateSelector = () => {
    const dispatch = useDispatch();
    const currentReduxStrategy = useSelector(
        (state: AppState) => state.strategies.strategy
    );
    const backtest = useSelector((state: AppState) => state.backtests.backtest);
    const theoryActiveCtx = useSelector((state: AppState) => state.graphItems.theoryActive);
    const currentTab = useSelector((state: AppState) => state.tabs.currentMainTab);
    const graphItems = useSelector((state: AppState) => state.graphItems.graphItems);
    const test = useSelector((state: AppState) => state.tests.test);
    const [ dates, setDates ] = useState<CriteriaDateDTO[]>([]);
    const [ sections, setSections ] = useState<number>(1);
    const [ currentStrategy, setCurrentStrategy ] = useState<StrategyDTO | null>(null);
    const [ currentTest, setCurrentTest ] = useState<BacktestingSetting | null>(null);
    const [ theoryMethodService ] = useState<TheoryMethodService>(new TheoryMethodService());

    useEffect(() => {
        setCurrentStrategy(cloneDeep(currentReduxStrategy));
    }, [ currentReduxStrategy ]);

    useEffect(() => {
        const t= cloneDeep(test);
        if(t){
            setCurrentTest(t);
        }
    }, [ test ]);

    useEffect(() => {
        if(currentTest && currentTab){
            const currentMethod = MethodsResultsManager.getCurrentMethod(currentTest, currentTab);
            if(currentMethod){
                const work = currentMethod?.parameters as CriteriaBoardMethod | undefined;
                if(work && work.dates){
                    setDates(work.dates);
                }
            }
        }
    }, [ currentTab, currentTest ]);

    const addMore = () => {
        setSections(sections + 1);
    };

    const handleAdd = (date: CriteriaDateDTO) => {
        setDates([ ...dates, date ]);
        addMore();
    };

    const addMethod = async(name: string, update: boolean, test?: boolean, graphItemsTest?: GraphItem[], holdValue?: string) => {
        if(!currentTest || !currentTab) return;
        const currentMethod = MethodsResultsManager.getCurrentMethod(currentTest, currentTab);
        let work: CriteriaBoardMethod | undefined = currentMethod?.parameters as CriteriaBoardMethod | undefined;
        const options = new CriteriaBoardOptionSelect();
        options.dates = true;
        const w = new CriteriaBoardMethod("Theory", options);
        w.dates = dates.map(e => {
            e.months = e.months.map(e => {
                e.value = +e.value;
                return e;
            });
            return e;
        });
        addHoldValue(holdValue, w);
        work = w;
        const activeGraphItem = graphItemsTest?.[0] ?? graphItems.find((e) => theoryActiveCtx.includes(e._id));
        const dtoGraphItem = cloneDeep(activeGraphItem);
        if(test && dtoGraphItem && work){
            const res = theoryMethodService.addBuyLabels([ dtoGraphItem?.timeSeries ], work);
            dtoGraphItem.timeSeries = res[0];
            dispatch(editGraphItemRedux(dtoGraphItem));
        }else{
            const item: GraphItem  = createFakeGraphItem(goldTimeSeries);
            createOrEditNewMehtodOrCurrent(
                dispatch,
                currentTest,
                currentTab,
                new MethodsResultsManager(),
                work,
                name,
                item,
                [ item?.timeSeries ],
                currentStrategy,
                null,
                undefined,
                update === false ? true : false,
                "Theory"
            );
        }

    };

    const activeGraphItem = graphItems.find((e) => theoryActiveCtx.includes(e._id));
    const disabled = dates.length === 0;

    const rows: RowTableDTO[] = [];
    dates.forEach((date, index) => {
        rows.push({
            columns: [
                {
                    title: index === 0 ? "Year" : null,
                    value: date.year ?? "-",
                },
                {
                    title: index === 0 ? "Months" : null,
                    value: date.months.length > 0 ? date.months.map(e => e.label).join(", ") : "-",
                },
                {
                    title: index === 0 ? "Weeks" : null,
                    value: date.weeks.length > 0 ? date.weeks.map(e => e.label).join(", ") : "-",
                },
                {
                    title: "Remove",
                    value: <Button onClick={() => setDates(dates.filter((e, i) => i !== index))}>Remove</Button>,
                },
            ],
        });
    });

    return (
        <VStack align="start" spacing={8}>
            <InfoTooltip text="Set buying rules by years and months." />
            <ExplainerInfo.Information
                title="Dates Method"
                text={
                    <MathJax>
                        {`
                The "Dates" method sets buy signals on specific predefined dates.

                **Algorithm:**
                1. Define a list of dates \\( D \\).
                2. For each date \\( d \\) in \\( D \\):
                   \\[
                   \\text{generate a buy signal on } d
                   \\]
            `}
                    </MathJax>
                }
            />
            <Heading size="md">Select dates</Heading>
            <Box key={"yearMonthSelec"}>
                <YearSelector onAdd={handleAdd} />
            </Box>
            <TableComponent rows={rows} />
            <Box margin={2} width={'full'}>
                <AddMethodHelper 
                    useHoldValue={true}
                    disabledText='Missing dates or time series' callback={(name, update, holdValue) => {
                        addMethod(name, update, false, undefined, holdValue);
                    }} disabled={disabled} testDisabled={activeGraphItem != null} callbackTest={(graphItemsTest, holdValue) => {
                        return new Promise<void>((resolve, _reject) => {
                            setTimeout(() => {
                                addMethod("", false, true, graphItemsTest, holdValue);
                            }, 500);
                            setTimeout(() => {
                                resolve();
                            }, 1500);
                        });
                    }} />
            </Box>
        </VStack>
    );
};
