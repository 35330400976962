
import { MenuCategory } from "../../../menu/dtos/menu-dtos";
import { ImpreemMethodDTO, TimeSeriesDTO } from "../../../methods";
import { Upload } from "../../../timeseries/timeseries-models";
import { sendPost } from "../frontend/http-utilities";

export async function getTimeSeries(): Promise<MenuCategory[]> {
    return sendPost("time-series/get-all-time-series").then(e => {
        return e.data as MenuCategory[];
    }).catch(() => {
        return [];
    });
}

export async function getTtest(ts: TimeSeriesDTO[]): Promise<number | null> {
    return sendPost("time-series/calculate-t-test", { timeseries: ts }).then(e => {
        return e.data as number;
    }).catch(() => {
        return null;
    });

}

export async function removeTimeSeries(timeseries: TimeSeriesDTO): Promise<void> {
    return sendPost("time-series/delete", timeseries).then(e => {
        return;
    }).catch(() => {
        return;
    });
}

export async function calculateDickeyFullerTest(data: object): Promise<number | null> {
    return sendPost("time-series/calculate-dickey-fuller-test", data).then(e => {
        return e.data.pValue[0] as number;
    }).catch(() => {
        return null;
    });
}

export async function updateTimeseries(timeseries: TimeSeriesDTO, extras?: object): Promise<TimeSeriesDTO | null> {
    return sendPost("time-series/update", { timeseries, extras }).then((e) => {
        return e.data as TimeSeriesDTO;
    }).catch(() => {
        return null;
    });
}

export async function getTimeSeriesById(id: string): Promise<TimeSeriesDTO | null> {
    return sendPost("time-series/get", { id }).then((e) => {
        return e.data as TimeSeriesDTO;
    }).catch(() => {
        return null;
    });
}

export async function addTimeseries(
    timeseries: TimeSeriesDTO,
    method: ImpreemMethodDTO | null, 
    methodKey: string, 
    testId: string | null | undefined,
    withMethod?: boolean): Promise<TimeSeriesDTO | null> {
    return sendPost("time-series/add", { timeseries, methodKey, method, testId, withMethod: withMethod ?? true }).then((e) => {
        return e.data as TimeSeriesDTO;
    }).catch(() => {
        return null;
    });
}

export async function getAllIndexes(): Promise<MenuCategory[] | null> {
    return sendPost("time-series/get-all-indexes", {}).then((e) => {
        return e.data as MenuCategory[];
    }).catch(() => {
        return null;
    });
}

export async function getIndexesBySymbol(symbols: string[]): Promise<TimeSeriesDTO[] | null> {
    return sendPost("time-series/get-indexes", { symbols }).then((e) => {
        return e.data as TimeSeriesDTO[];
    }).catch(() => {
        return null;
    });
}

export async function uploadTimeSeries(uploadDto: Upload): Promise<TimeSeriesDTO | null> {
    return sendPost("time-series/upload", uploadDto).then((e) => {
        return e.data as TimeSeriesDTO;
    }).catch(() => {
        return null;
    });
}