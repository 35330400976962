/* eslint-disable @typescript-eslint/no-empty-function */
import { AddIcon } from "@chakra-ui/icons";
import { Button, Container, Grid, GridItem, HStack, Heading, IconButton, Image, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Table, TableContainer, Tbody, Td, Th, Thead, Tooltip, Tr, VStack, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { FaCircleNotch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { createOrEditNewMehtodOrCurrent } from "../../action-creators/methods/methods.actions";
import { addSelectionFilterAction } from "../../action-creators/selection-filters/selection-filters.action";
import { BacktestingSetting } from "../../backtesting-common-frontend";
import { CompanyProfile } from "../../backtesting-common-frontend/database/R-DB/timeseries/companies";
import { isBacktestLicense } from "../../backtesting-common-frontend/http-utilities/auth/auth-http-utilities";
import { getPreviewTransformations } from "../../backtesting-common-frontend/http-utilities/http-utilities/transformations/transformations-backend.service";
import { MenuCategory } from "../../backtesting-common-frontend/menu/dtos/menu-dtos";
import { CategorizeType, GeneralCategorize, TimeSeriesDTO } from "../../backtesting-common-frontend/methods";
import { downloadExcelFundamentalData } from "../../backtesting-common-frontend/shared/excel/excel-helper";
import { groupArray } from "../../backtesting-common-frontend/shared/utilites/array.utilities";
import { cloneDeep } from "../../backtesting-common-frontend/shared/utilites/object.utilities";
import { StrategyDTO } from "../../backtesting-common-frontend/strategies/strategy";
import { RunBacktestButton } from "../../components/backtests/run-backtest-button";
import LazyLoading from "../../components/lazy-loading/lazy-loding";
import SelectionFilters from "../../components/selection-filters/selection-filters";
import StrategyTable from "../../components/strategies/strategies";
import { SelectTimeSeries } from "../../components/time-series/time-series-selector";
import { MethodsResultsManager } from "../../managers/methods/methods-manager";
import { TimeSeriesResultsManager } from "../../managers/time-series/time-series-manager";
import { removeScreeningData, saveScreeningData, setLoading, setSelectedSymbol, updateCompanyProfiles } from "../../store/backtests/backtests";
import { AppState } from "../../store/store";
import { updateNavigationTo } from "../../store/tabs/tabs";

export interface ScreeningData {
    symbol: string;
    transformationKey: string;
    value: number;
    timeseries: TimeSeriesDTO;
}

export function ScreeningScreen() {
    const dispatch = useDispatch();
    const timeSeriesMenu = useSelector(
        (state: AppState) => state.backtests.menuCategory
    );
    const companyProfilesRedux = useSelector(
        (state: AppState) => state.backtests.companyProfiles
    );
    const screeningDataRedux = useSelector(
        (state: AppState) => state.backtests.screeningData
    );
    const test = useSelector((state: AppState) => state.tests.test);
    const currentTab = useSelector((state: AppState) => state.tabs.currentMainTab);
    const backtestCtx = useSelector((state: AppState) => state.backtests.backtest);
    const strategyCtx = useSelector((state: AppState) => state.strategies.strategy);
    const [ companyProfiles, setCompanyProfiles ] = useState<CompanyProfile[]>([]);
    const [ visibleProfiles, setVisibleProfiles ] = useState<CompanyProfile[]>([]);
    const [ strategy, setStrategy ] = useState<StrategyDTO | null>(null);
    const [ timeSeries, setTimeSeries ] = useState<MenuCategory[]>([]);
    const [ allSelectedTimeSeries, setAllSelectedTimeSeries ] = useState<TimeSeriesDTO[]>([]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [ modalContent, setModalContent ] = useState<TimeSeriesDTO | null>(null);
    const [ lowerBound, setLowerBound ] = useState('');
    const [ upperBound, setUpperBound ] = useState('');
    const [ currentTest, setCurrentTest ] = useState<BacktestingSetting | null>(null);
    const [ companyProfileOffset, setCompanyProfileOffset ] = useState(20);
    const [ refresh, setRefresh ] = useState(0);

    useEffect(() => {
        setStrategy(cloneDeep(strategyCtx));
    }, [ strategyCtx ]);

    useEffect(() => {
        const t = cloneDeep(test);
        if (t) {
            setCurrentTest(t);
        }
    }, [ test ]);

    useEffect(() => {
        setCompanyProfiles(cloneDeep(companyProfilesRedux));
        setVisibleProfiles(cloneDeep(companyProfilesRedux).slice(0, companyProfileOffset));
    }, [ companyProfilesRedux, companyProfileOffset ]);

    const handleScreeningResults = (cp: CompanyProfile[], add: boolean) => {
        if (!add) {
            setCompanyProfiles([]);
            setVisibleProfiles([]);
            dispatch(updateCompanyProfiles([]));
        }
        if (add) {
            setCompanyProfiles(prev => [ ...prev, ...cp ]);
            setVisibleProfiles(prev => [ ...prev, ...cp.slice(0, companyProfileOffset) ]);
        } else {
            setCompanyProfiles(cp);
            setVisibleProfiles(cp.slice(0, companyProfileOffset));
        }
    };

    const getTimeSeries = useCallback(async(selectedTimeSeries: TimeSeriesDTO, symbols: string[]) => {
        const symbolsMissingValues = symbols.filter(symbol => {
            return !screeningDataRedux.find(data => data.symbol === symbol && data.transformationKey === selectedTimeSeries.transformationKey);
        });

        if (selectedTimeSeries && symbolsMissingValues.length > 0) {
            dispatch(setLoading(true));
            getPreviewTransformations(symbolsMissingValues, backtestCtx, selectedTimeSeries, "http", undefined, "screening")
                .then(data => {
                    const newValues = data.map((datum) => {
                        const values = datum.timeSeries;
                        values.ID = datum.timeSeries.ID;
                        values.graphValue = values.graphValue.map(e => {
                            e.symbol = datum.timeSeries.ID;
                            return e;
                        });
                        return values;
                    });

                    const screeningData: ScreeningData[] = newValues.map((values) => ({
                        symbol: values.ID,
                        transformationKey: selectedTimeSeries.transformationKey,
                        value: values.graphValue[values.graphValue.length - 1]?.value ?? 0,
                        timeseries: selectedTimeSeries,
                    }));

                    dispatch(saveScreeningData(screeningData));
                }).catch(() => { }).finally(() => {
                    dispatch(setLoading(false));
                });
        }
    }, [ backtestCtx, dispatch, screeningDataRedux ]);

    const handleLoadMore = () => {
        setCompanyProfileOffset(prev => prev + 20);
        setRefresh(prev => prev + 1);
    };

    useEffect(() => {
        if (timeSeriesMenu) {
            const validTimeSeries = cloneDeep(timeSeriesMenu).filter(e => e.category === "fundamentals" ||
                e.category === "company-time-series" || e.category === "beta");
            setTimeSeries(validTimeSeries);
        }
    }, [ timeSeriesMenu ]);

    const handleTimeSeriesSelection = (value: TimeSeriesDTO) => {
        if (TimeSeriesResultsManager.isTimeSeries(value)) {
            setAllSelectedTimeSeries(prev => [ ...prev, value ]);
        }
    };

    const getTimeSeriesHelper = useCallback(async() => {
        const promises: Promise<void>[] = [];
        allSelectedTimeSeries.forEach(ts => {
            promises.push(getTimeSeries(ts, visibleProfiles.map(co => co.symbol)));
        });
        await Promise.all(promises);
    }, [ allSelectedTimeSeries, getTimeSeries, visibleProfiles ]);

    useEffect(() => {
        getTimeSeriesHelper();
    }, [ allSelectedTimeSeries, getTimeSeries, getTimeSeriesHelper, visibleProfiles ]);

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent minW={'700px'}>
                    <ModalHeader>Set Threshold Criteria</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack spacing={4}>
                            <p>Lowerbound is the lower threshold and upperbound is the upper threshold to apply to the screening in the backtest.</p>
                            <Input
                                value={lowerBound}
                                onChange={(e) => setLowerBound(e.target.value)}
                                placeholder="< Lower threshold"
                            />
                            <span>{modalContent?.display}</span>
                            <Input
                                value={upperBound}
                                onChange={(e) => setUpperBound(e.target.value)}
                                placeholder="> Upper threshold"
                            />
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="black" mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button variant="green" onClick={() => {
                            createThresholdMethods(modalContent);
                        }}>Apply</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Grid
                templateColumns={{ base: '1fr', md: '15% 65% 20%' }}
                height={'100vh'}
            >
                <GridItem colSpan={1} bg={useColorModeValue('gray.300', '#292C31')} zIndex={3}>
                    <Heading as="h2" size="lg" p={4}>Choose financial ratios</Heading>
                    <span>Showing values for the first 20 companies.</span>
                    <span>Add threshold by the "+" and run the screening analysis by clicking on the "run backtest"-button.</span>
                    <SelectTimeSeries
                        onHandleSelectionChange={(item) => {
                            handleTimeSeriesSelection(item);
                        }}
                        data={timeSeries}>
                        <>

                        </>
                    </SelectTimeSeries>
                    {allSelectedTimeSeries.map((e, i) => (
                        <HStack key={i}>
                            <Button onClick={() => {
                                setAllSelectedTimeSeries(prev => prev.filter(x => e.transformationKey !== x.transformationKey));
                                dispatch(removeScreeningData(e.transformationKey));
                            }}>Remove</Button>
                            <span>{e.display}</span>
                            <FaCircleNotch color={'white'} style={{ cursor: "pointer" }} onClick={() => {
                                setCompanyProfileOffset(20);
                                setRefresh(prev => prev + 1);
                            }} />
                        </HStack>
                    ))}
                </GridItem>
                <GridItem colSpan={1} bg={useColorModeValue('white', '#171719')} zIndex={1} >
                    <Container m={4} p={10} justifyContent={'center'} alignItems={'center'} minW={'full'} position={'relative'}>
                        <LazyLoading>
                            <>
                                <HStack>
                                    <SelectionFilters timeSeriesMenu={timeSeriesMenu} handleScreeningResults={handleScreeningResults} />
                                </HStack>
                                <Button bg={'red.300'} _hover={
                                    { bg: 'red.500' }
                                } onClick={() => {
                                    setCompanyProfiles([]);
                                    setVisibleProfiles([]);
                                    dispatch(updateCompanyProfiles([]));
                                }}>Clear</Button>
                                <TableContainer >
                                    <Table variant="simple">
                                        <Thead>
                                            <Tr>
                                                <Th>View</Th>
                                                {isBacktestLicense() && <Th>Add</Th>}
                                                <Th>Symbol</Th>
                                                <Th>Image</Th>
                                                {groupArray(allSelectedTimeSeries, "transformationKey").map((ts, index) => (
                                                    <Th key={index + "headers"}>
                                                        <HStack spacing="3">
                                                            <span>{ts[0].display}</span>
                                                            {isBacktestLicense() && <Tooltip label="Applying threshold criteria" hasArrow>
                                                                <IconButton
                                                                    aria-label="Applying threshold criteria"
                                                                    icon={<AddIcon />}
                                                                    onClick={() => {
                                                                        setModalContent(ts[0]);
                                                                        onOpen();
                                                                    }}
                                                                    variant="outline"
                                                                    size="sm"
                                                                />
                                                            </Tooltip>}
                                                            <Button bg={'green.300'} _hover={
                                                                { bg: 'green.500' }
                                                            } onClick={() => {
                                                                downloadExcelFundamentalData(ts[0], allSelectedTimeSeries.filter(x => x.transformationKey === ts[0].transformationKey).map(x => x.graphValue[x.graphValue.length - 1]).flat());
                                                            }}>Download</Button>
                                                        </HStack>
                                                    </Th>
                                                ))}
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {visibleProfiles.map((co, index) => (
                                                <Tr key={index + "rows"}>
                                                    <Td><Button onClick={() => {
                                                        dispatch(setSelectedSymbol(companyProfiles[index].symbol));
                                                        dispatch(updateNavigationTo("profile"));
                                                    }}>View</Button></Td>
                                                    {isBacktestLicense() && <Td>
                                                        <Button onClick={() => {
                                                            const theSymbol = timeSeriesMenu.find((e) => e.category === "symbols")?.items.find((e) => e.ID === co.symbol);
                                                            if (theSymbol && strategy) {
                                                                addSelectionFilterAction(dispatch, strategy, theSymbol, []);
                                                            }
                                                        }}>Add</Button>
                                                    </Td>}
                                                    <Td>{co.symbol}</Td>
                                                    <Td><Image src={co.image} alt={co.companyName} boxSize="40px" objectFit="cover" /></Td>
                                                    {allSelectedTimeSeries.map((ts, idx) => (
                                                        <Td key={idx}>{screeningDataRedux.find(data => data.symbol === co.symbol && data.transformationKey === ts.transformationKey)?.value ?? "N/A"}</Td>
                                                    ))}
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                                <Button onClick={handleLoadMore} disabled={visibleProfiles.length >= companyProfiles.length} bg={visibleProfiles.length >= companyProfiles.length ? 'gray': undefined}>
                                    Load 20 more
                                </Button>
                            </>
                        </LazyLoading>
                    </Container>
                </GridItem>
                <GridItem colSpan={1} bg={useColorModeValue('gray.300', '#292C31')} borderLeft="1px"
                    borderLeftColor={useColorModeValue('gray.200', 'gray.700')}>
                    <RunBacktestButton />
                    <LazyLoading>
                        <>
                            <StrategyTable />
                        </>
                    </LazyLoading>
                </GridItem>
            </Grid>
        </>
    );

    function createThresholdMethods(ts: TimeSeriesDTO) {
        setModalContent(null);

        if (lowerBound.length > 0 && !isNaN(Number(lowerBound))) {
            const copyTs = cloneDeep(ts);
            const betaCategorize = new GeneralCategorize();
            betaCategorize.key = "absolute";
            betaCategorize.interval = [];
            betaCategorize.categorizeType = [ "up", lowerBound ];
            const categorizeType = new CategorizeType();
            categorizeType.key = "beta";
            categorizeType.meta = betaCategorize;
            copyTs.categorizeType = categorizeType;

            copyTs.categorizeType.isOnFly = true;

            createOrEditNewMehtodOrCurrent(
                dispatch,
                currentTest,
                currentTab,
                new MethodsResultsManager(),
                null,
                "Lowerbound: " + lowerBound + " " + modalContent?.display,
                null,
                [ copyTs ],
                strategy,
                null,
                undefined,
                true,
                "SelectionCriteria"
            );
        }

        if (upperBound.length > 0 && !isNaN(Number(upperBound))) {
            const copyTs2 = cloneDeep(ts);
            const betaCategorizeUpper = new GeneralCategorize();
            betaCategorizeUpper.key = "absolute";
            betaCategorizeUpper.interval = [];
            betaCategorizeUpper.categorizeType = [ "down", upperBound ];
            const categorizeTypeUpper = new CategorizeType();
            categorizeTypeUpper.key = "beta";
            categorizeTypeUpper.meta = betaCategorizeUpper;
            copyTs2.categorizeType = categorizeTypeUpper;

            copyTs2.categorizeType.isOnFly = true;

            createOrEditNewMehtodOrCurrent(
                dispatch,
                currentTest,
                currentTab,
                new MethodsResultsManager(),
                null,
                "Upperbound: " + upperBound + " " + modalContent?.display,
                null,
                [ copyTs2 ],
                strategy,
                null,
                undefined,
                true,
                "SelectionCriteria"
            );
        }

        setLowerBound('');
        setUpperBound('');
        onClose();
    }
}
