import {
    Box,
    Button,
    Container,
    FormLabel,
    Input,
    Tooltip,
} from '@chakra-ui/react';
import { MathJax } from 'better-react-mathjax';
import { Select } from 'chakra-react-select';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addHoldValue } from '../../action-creators/criteria-board/criteria-board.actions';
import { createOrEditNewMehtodOrCurrent } from '../../action-creators/methods/methods.actions';
import { BacktestingSetting, GraphItem } from '../../backtesting-common-frontend';
import { cloneDeep } from '../../backtesting-common-frontend/shared/utilites/object.utilities';
import { StrategyDTO } from '../../backtesting-common-frontend/strategies/strategy';
import { CriteriaBoardMethod, CriteriaBoardOptionSelect, criteriaSign } from '../../backtesting-common-frontend/techniques';
import { TheoryMethodService } from '../../backtesting-common-frontend/techniques/theory-method.service';
import { MethodsResultsManager } from '../../managers/methods/methods-manager';
import { editGraphItemRedux } from '../../store/graphitems/graphitems';
import { AppState } from '../../store/store';
import AddMethodHelper from '../methods/add-method-helper';
import { ExplainerInfo } from '../shared/explainer/info';

export default function Growth() {
    const dispatch = useDispatch();
    const currentReduxStrategy = useSelector(
        (state: AppState) => state.strategies.strategy
    );
    const theoryActiveCtx = useSelector((state: AppState) => state.graphItems.theoryActive);
    const graphItems = useSelector((state: AppState) => state.graphItems.graphItems);
    const test = useSelector((state: AppState) => state.tests.test);
    const currentTab = useSelector((state: AppState) => state.tabs.currentMainTab);
    const [ growthValue, setGrowthValue ] = useState<string>("0.01");
    const [ growthType, setGrowthType ] = useState<criteriaSign>("up");
    const [ currentStrategy, setCurrentStrategy ] = useState<StrategyDTO | null>(null);
    const [ currentTest, setCurrentTest ] = useState<BacktestingSetting | null>(null);
    const [ theoryMethodService ] = useState<TheoryMethodService>(new TheoryMethodService());

    useEffect(() => {
        setCurrentStrategy(cloneDeep(currentReduxStrategy));
    }, [ currentReduxStrategy ]);

    useEffect(() => {
        const t = cloneDeep(test);
        if(t){
            setCurrentTest(t);
        }
    }, [ test ]);

    useEffect(() => {
        if(currentTest && currentTab){
            const currentMethod = MethodsResultsManager.getCurrentMethod(currentTest, currentTab);
            if(currentMethod){
                const work = currentMethod.parameters as CriteriaBoardMethod | undefined;
                if(work){
                    setGrowthType(work.growthType);
                    setGrowthValue(work.growthValue);
                }
            }
        }
    }, [ currentTest, currentTab ]);

    const addMethod = (name: string, update: boolean, test?: boolean, graphItemsTest?: GraphItem[], holdValue?: string) => {
        if(!currentTest || !currentTab) return;
        const currentMethod = MethodsResultsManager.getCurrentMethod(currentTest, currentTab);
        let work: CriteriaBoardMethod | undefined = currentMethod?.parameters as CriteriaBoardMethod | undefined;
        const options = new CriteriaBoardOptionSelect();
        options.growth = true;
        const w = new CriteriaBoardMethod("Theory", options);
        w.growthValue = growthValue.toString();
        w.growthType = growthType;
        addHoldValue(holdValue, w);
        work = w;
        const activeGraphItem = graphItemsTest?.[0] ?? graphItems.find((e) => theoryActiveCtx.includes(e._id));
        const dtoGraphItem = cloneDeep(activeGraphItem);
        if(test && dtoGraphItem && work){
            const res = theoryMethodService.addBuyLabels([ dtoGraphItem?.timeSeries ], work);
            dtoGraphItem.timeSeries = res[0];
            dispatch(editGraphItemRedux(dtoGraphItem));
        }else if(dtoGraphItem){
            createOrEditNewMehtodOrCurrent(
                dispatch,
                currentTest,
                currentTab,
                new MethodsResultsManager(),
                work,
                name,
                dtoGraphItem,
                [ dtoGraphItem?.timeSeries ],
                currentStrategy,
                null,
                undefined,
                update === false ? true : false,
                "Theory"
            );
        }

    };

    const activeGraphItem = graphItems.find((e) => theoryActiveCtx.includes(e._id));

    return (
        <Container width={'full'} p={3} size={"sm"}>
            <ExplainerInfo.Information
                title="Growth Method"
                text={
                    <MathJax>
                        {`
                The "Growth" method involves generating buy signals based on the growth rate of the asset over a specified period.

                **Algorithm:**
                1. Define a period \\( P \\).
                2. Calculate the growth rate \\( g \\) over the period:
                   \\[
                   g = \\frac{x_t - x_{t-P}}{x_{t-P}}
                   \\]
                3. For each period, if \\( g \\) meets the growth criteria, generate a buy signal.
            `}
                    </MathJax>
                }
            />
            <Input type="string" value={growthValue} margin={2} onChange={(e) => {
                const inputValue = e.target.value;
                // Use regex to allow positive and negative numbers with optional decimal places
                if (/^-?\d*\.?\d*$/.test(inputValue)) {
                    setGrowthValue(inputValue);
                }
            }} />
            <Box margin={2} width={'full'}>
                <FormLabel>Comparision</FormLabel>
                <Select options={[ { label: "Up", value: "up" }, { label: "Down", value: "down" } ]} onChange={(e) => setGrowthType((e as any).value as criteriaSign)} />
            </Box>
            <Box margin={2} width={'full'}>
                {!activeGraphItem && <Tooltip label={"Add a time series to the graph to enable this feature"} aria-label={"A tooltip"} placement={"top"}>
                    <Button disabled={!activeGraphItem}>
                    Test
                    </Button>
                </Tooltip>}
                <AddMethodHelper 
                    useHoldValue={true}
                    callback={(name, update, holdValue) => {
                        addMethod(name, update, false, undefined, holdValue);
                    }} disabled={!activeGraphItem} testDisabled={activeGraphItem != null} callbackTest={(graphItemsTest, holdValue) => {
                        return new Promise<void>((resolve, _reject) => {
                            setTimeout(() => {
                                addMethod("", false, true, graphItemsTest, holdValue);
                            }, 500);
                            setTimeout(() => {
                                resolve();
                            }, 1500);
                        });
                    }} />
            </Box>
        </Container>
    );
}
