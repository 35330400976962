/* eslint-disable @typescript-eslint/no-loop-func */
import axios from "axios";
import { ImpreemDate } from "../../../date/impreem-date";

import moment from "moment";
import { Periodicity, convertPeriodictyToStep, sleep } from "../../../shared/periodicity";
import { CriteriaDateDTO } from "../../../techniques";

export async function getHistoricalEvents(prompt: string, url: string): Promise<CriteriaDateDTO[] | null> {
    return await axios({
        method: "post",
        url: url + "dates/get-historical-events",
        data: {
            prompt,
        },
    }).then(e => {
        return e.data as CriteriaDateDTO[];
    }).catch(() => {
        return null;
    });
}

export async function getDatesFromLabel(labels: string[], url: string): Promise<ImpreemDate[] | null> {
    return await axios({
        method: "post",
        url: url + "dates/get-dates",
        data: {
            labels,
        },
    }).then(e => {
        return e.data as ImpreemDate[];
    }).catch(() => {
        return null;
    });
}

export const getFutureDate = async (date: ImpreemDate, periodicity: Periodicity, url: string): Promise<ImpreemDate> => {
    return await axios({
        method: "post",
        url: url + "dates/get-future-date-from-step",
        data: {
            step: date.step,
            periodicity,
        },
    }).then(e => {
        return e.data as ImpreemDate;
    }).catch(e => {
        const newDate = moment(date.date).add("days", convertPeriodictyToStep(periodicity)).format("YYYY-MM-DD");
        const backup = new ImpreemDate(date.step + convertPeriodictyToStep(periodicity), newDate);
        return backup;
    });
};

export const getDateFromLabel = async (dateLabel: string, url: string): Promise<ImpreemDate> => {
    return await axios({
        method: "post",
        url: url + "dates/get-date-from-label",
        data: {
            label: dateLabel,
        },
    }).then(e => {
        return e.data as ImpreemDate;
    }).catch(e => {
        return null;
    });
};

export const getDateFromStep = async (step: number, url: string): Promise<ImpreemDate> => {
    return await axios({
        method: "post",
        url: url + "dates/get-date",
        data: {
            step: step,
        },
    }).then(e => {
        return e.data as ImpreemDate;
    }).catch(e => {
        return null;
    });
};

export const getLastDate = async (url: string): Promise<ImpreemDate> => {
    const success = false;
    while (!success) {
        const d = new Promise<ImpreemDate>((res, rej) => {
            return axios({
                method: "post",
                url: url + "dates/get-last-date",
                data: {
                },
            }).then(e => {
                res(e.data as ImpreemDate);
            }).catch(e => {
                console.log(e);
                res(null);
            });
        });
        if (d != null) {
            return d;
        }
        console.log("Trying to get date...");
        await sleep(1000 * 5);
    }
};
