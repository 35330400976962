/* eslint-disable @typescript-eslint/no-namespace */
import { AnyAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { addStopLossTakeProfit, removeStopLossTakeProfit } from "../../../../store/stoplosstakeprofits/stoplosstakeprofits";
import { StopLossTakeProfit } from "../../../database/stoplosstakeprofits";
import { sendPost } from "../frontend/http-utilities";

export namespace StopLossTakeProfitHttp {

    export async function create(dto: StopLossTakeProfit, dispatch: Dispatch<AnyAction>): Promise<StopLossTakeProfit> {
        return sendPost("stoplosstakeprofits/create", dto).then((e) => {
            dispatch(addStopLossTakeProfit(e.data));
            return e.data;
        });
    }
    
    export async function update(dto: StopLossTakeProfit): Promise<StopLossTakeProfit> {
        return sendPost("stoplosstakeprofits/update", dto).then((e) => {
            return e.data;
        });
    }
    
    export async function get(backtestId: string, dispatch: Dispatch<AnyAction>): Promise<StopLossTakeProfit> {
        return sendPost("stoplosstakeprofits/get", { backtestId }).then((e) => {
            if(e.data == null) return null;
            dispatch(addStopLossTakeProfit(e.data));
            return e.data;
        });
    }
    
    export async function remove(dto: StopLossTakeProfit, dispatch: Dispatch<AnyAction>): Promise<StopLossTakeProfit> {
        return sendPost("stoplosstakeprofits/delete", dto).then((e) => {
            dispatch(removeStopLossTakeProfit(dto));
            return e.data;
        });
    }

}