import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Backtest, BacktestResultsDTO, tagsBacktest } from '../../backtesting-common-frontend';
import { CompanyProfile } from '../../backtesting-common-frontend/database/R-DB/timeseries/companies';
import { MenuCategory } from '../../backtesting-common-frontend/menu/dtos/menu-dtos';
import { StatusDisplayDTO } from '../../backtesting-common-frontend/status/error-handling';
import { ScreeningData } from '../../screens/screening/screening-screen';

class BacktestResult {
    backtest: Backtest | null = null;
    backtests: Backtest[] = [];
    menuCategory: MenuCategory[] = [];
    loading = false;
    statusMessage: StatusDisplayDTO | null = null;

    overwriteBacktestResult: BacktestResultsDTO | null = null;
    tags: tagsBacktest[] = [];
    selectedSymbol: string | null = null;
    companyProfiles: CompanyProfile[] = [];
    backtestsQueue: Backtest[] = [];
    newsBacktests: Backtest[] = [];
    screeningData: ScreeningData[] = [];
    runningBacktest = false;
}

const initialState: BacktestResult = {
    backtest: null,
    backtests: [],
    menuCategory: [],
    loading: false,
    statusMessage: null,
    // backtestResults: [],
    overwriteBacktestResult: null,
    tags: [],
    selectedSymbol: null,
    companyProfiles: [],
    backtestsQueue: [],
    newsBacktests: [],
    screeningData: [],
    runningBacktest: false,
};

const backtest = createSlice({
    name: 'backtests',
    initialState,
    reducers: {
        setCurrentBacktest: (state, action: PayloadAction<Backtest>) => {
            const currentBacktest = action.payload;
            let n = state.backtests;
            if (n && currentBacktest) {
                // Check if currentBacktest is new by JSON.stringify
                const index = n.findIndex((backtest) => JSON.stringify(backtest) === JSON.stringify(currentBacktest));
                if (index === -1 && state.newsBacktests.length === 0) {
                    n = n.filter((backtest) => backtest._id !== currentBacktest._id);
                    n.unshift(currentBacktest);
                }
                state.backtests = n;
            }
        },
        setActive: (state, action: PayloadAction<string>) => {
            // Find the backtest to activate and set its activeFrontend to true
            const activeBacktest = state.backtests.find(
                (backtest) => backtest._id === action.payload
            );
            if (activeBacktest) {
                activeBacktest.activeFrontend = true;
            }
            state.backtest = activeBacktest;
            state.backtests = state.backtests.map((backtest) => {
                if(backtest._id === action.payload){
                    backtest.activeFrontend = true;
                }else{
                    backtest.activeFrontend = false;
                }
                return backtest;
            });
        },
        updateBacktestInBacktests: (state, action: PayloadAction<Backtest>) => {
            const index = state.backtests.findIndex((backtest) => backtest._id === action.payload._id);
            if (index !== -1) {
                state.backtests[index] = action.payload;
            }
        },
        addBacktestQueue: (state, action: PayloadAction<Backtest[]>) => {
            state.backtestsQueue = action.payload;
        },
        add: (state, action: PayloadAction<Backtest[]>) => {
            state.backtests = action.payload;
        },
        addCurrent: (state, action: PayloadAction<Backtest>) => {
            state.backtest = action.payload;
        },
        addMenuCategory: (state, action: PayloadAction<MenuCategory[]>) => {
            state.menuCategory = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        updateMessage: (state, action: PayloadAction<StatusDisplayDTO | null>) => {
            state.statusMessage = action.payload;
        },
        overwriteBacktestResult: (state, action: PayloadAction<BacktestResultsDTO>) => {
            state.overwriteBacktestResult = action.payload;
        },
        updateTags: (state, action: PayloadAction<tagsBacktest[]>) => {
            state.tags = action.payload;
        },
        setSelectedSymbol: (state, action: PayloadAction<string | null>) => {
            state.selectedSymbol = action.payload;
        },
        updateCompanyProfiles: (state, action: PayloadAction<CompanyProfile[]>) => {
            state.companyProfiles = action.payload;
        },
        addCompanyProfile: (state, action: PayloadAction<CompanyProfile>) => {
            state.companyProfiles.push(action.payload);
        },
        addNewsBacktests: (state, action: PayloadAction<Backtest[]>) => {
            state.newsBacktests = action.payload;
        },
        saveScreeningData: (state, action: PayloadAction<ScreeningData[]>) => {
            state.screeningData.push(...action.payload);
        },
        removeScreeningData: (state, action: PayloadAction<string>) => {
            state.screeningData = state.screeningData.filter((data) => data.transformationKey !== action.payload);
        },
        setRunningBacktest: (state, action: PayloadAction<boolean>) => {
            state.runningBacktest = action.payload;
        },
    },
});

export const setCurrentBacktest = backtest.actions.setCurrentBacktest;
export const setActive = backtest.actions.setActive;
export const updateBacktestInBacktests = backtest.actions.updateBacktestInBacktests;
export const updateBacktestsStore = backtest.actions.add;
export const updateBacktestStore = backtest.actions.addCurrent;
export const updateMenuCategory = backtest.actions.addMenuCategory;
export const setLoading = backtest.actions.setLoading;
export const updateMessage = backtest.actions.updateMessage;
export const overwriteBacktestResult = backtest.actions.overwriteBacktestResult;
export const updateTags = backtest.actions.updateTags;
export const setSelectedSymbol = backtest.actions.setSelectedSymbol;
export const updateCompanyProfiles = backtest.actions.updateCompanyProfiles;
export const addBacktestQueue = backtest.actions.addBacktestQueue;
export const addCompanyProfile = backtest.actions.addCompanyProfile;
export const addNewsBacktests = backtest.actions.addNewsBacktests;
export const saveScreeningData = backtest.actions.saveScreeningData;
export const removeScreeningData = backtest.actions.removeScreeningData;
export const setRunningBacktest = backtest.actions.setRunningBacktest;

export const backtestReducer = backtest.reducer;