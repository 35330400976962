import { combineReducers } from '@reduxjs/toolkit';
import { clientReducer } from './auth/client';
import { backtestReducer } from './backtests/backtests';
import { graphItemsReducer } from './graphitems/graphitems';
import { lazyLoadingsReducer } from './lazy-loadings/lazy-loadings';
import { newsReducer } from './news/news';
import { stopLossTakeProfitsReducer } from './stoplosstakeprofits/stoplosstakeprofits';
import { strategiesReducer } from './strategies/strategy';
import { symbolsReducer } from './symbols/symbols';
import { tabsReducer } from './tabs/tabs';
import { testReducer } from './tests/tests';
import { transformationsReducer } from './transformations/transformations';

const rootReducer = combineReducers({
    client: clientReducer,
    backtests: backtestReducer,
    tests: testReducer,
    strategies: strategiesReducer,
    transformations: transformationsReducer,
    graphItems: graphItemsReducer,
    news: newsReducer,
    lazyLoadings: lazyLoadingsReducer,
    tabs: tabsReducer,
    symbols: symbolsReducer,
    stopLossTakeProfits: stopLossTakeProfitsReducer,
});

export default rootReducer;