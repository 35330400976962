import { Button, Tooltip } from "@chakra-ui/react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BacktestingMetaDTO } from "../../backtesting-common-frontend";
import { isBacktestLicense } from "../../backtesting-common-frontend/http-utilities/auth/auth-http-utilities";
import { runBacktest } from "../../backtesting-common-frontend/http-utilities/http-utilities/backtests/backtests-backend.service";
import { myProgress } from "../../backtesting-common-frontend/http-utilities/http-utilities/progress/progress.service";
import { StatusDisplayDTO } from "../../backtesting-common-frontend/status/error-handling";
import { setLoading, setRunningBacktest, updateMessage } from "../../store/backtests/backtests";
import { AppState } from "../../store/store";
import { updateNavigationTo } from "../../store/tabs/tabs";

export function RunBacktestButton() {
    const dispatch = useDispatch();
    const backtest = useSelector((state: AppState) => state.backtests.backtest);
    const strategyCtx = useSelector((state: AppState) => state.strategies.strategy);
    const [ canRunBacktest, setCanRunBacktest ] = useState<boolean>(false);
    const runningBacktest = useSelector((state: AppState) => state.backtests.runningBacktest);

    React.useEffect(() => {
        if(strategyCtx) {
            const isOnlySymbols = true;//strategyCtx.selectionFilters.every(x => x.category === "symbols") && !(strategyCtx.selectionFilters.length === 0);
            setCanRunBacktest(strategyCtx.intersection.length > 0 && strategyCtx.selectionFilters.length > 0 || isOnlySymbols);
        }
    }, [ strategyCtx ]);

    return <Tooltip label={!isBacktestLicense() ? "Please upgrade your subscription to get access to backtest software" : !canRunBacktest ? "Cannot run backtest, please add atleast one selection filter and a method" :
        "Will run the current strategy"} aria-label={"A tooltip"} placement={"top"}>
        <Button disabled={!canRunBacktest && isBacktestLicense() || runningBacktest} colorScheme={!canRunBacktest || !isBacktestLicense() ? "gray" : "teal"} width={'full'} onClick={async() => {
            if(!isBacktestLicense()){
                window.open("https://buy.stripe.com/6oEcOpdYh2b5fyU7sx", "_blank");
            }
            if(runningBacktest){
                const status = new StatusDisplayDTO("Backtest is already running", "error");
                dispatch(updateMessage(status));
                return;
            }
            if(!canRunBacktest || !isBacktestLicense()) return;
            dispatch(setLoading(true));

            const removeAfter30Seconds = setInterval(() => {
                myProgress();
            }, 10000);

            setTimeout(() => {
                clearInterval(removeAfter30Seconds);
            }, 30000);

            dispatch(updateNavigationTo("/run-backtest"));
            const meta = new BacktestingMetaDTO();
            meta.calculateReturnsLast = true;
            meta.returnPortfolio = false;
            meta.takeFirstValue = false;
            meta.takeTestFromBacktest = false;
            meta.useCachedTimeSeries = [];
            dispatch(setRunningBacktest(true));
            await runBacktest(backtest._id, meta).finally(() => {
                dispatch(setLoading(false));
                dispatch(setRunningBacktest(false));
            });

        } }>
            {isBacktestLicense() ? "Run backtest" : "Run backtest analysis"}
        </Button>
    </Tooltip>;
}