/* eslint-disable no-console */
import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { setActiveGraphProperties } from "../../action-creators/graph-items/graph-items";
import { graphItemsExists } from "../../action-creators/timeseries/time-series.action";
import { Backtest, CCollectionTypes, GraphItem } from "../../backtesting-common-frontend";
import { updateBacktest } from "../../backtesting-common-frontend/http-utilities/http-utilities/backtests/backtests-backend.service";
import { deleteGraphItem } from "../../backtesting-common-frontend/http-utilities/http-utilities/graph-items/graph-items.http";
import { getPreviewTransformations } from "../../backtesting-common-frontend/http-utilities/http-utilities/transformations/transformations-backend.service";
import { ImpreemMethodDTO, MethodDTO, TimeSeriesDTO } from "../../backtesting-common-frontend/methods";
import { isSameMethod } from "../../backtesting-common-frontend/methods/methods-helper";
import { cloneDeep } from "../../backtesting-common-frontend/shared/utilites/object.utilities";
import { StatusDisplayDTO } from "../../backtesting-common-frontend/status/error-handling";
import { DisplayService, graphActive } from "../../backtesting-common-frontend/timeseries/display/display.service";
import { setLoading, updateMessage } from "../../store/backtests/backtests";
import { bulkAddAndOverwriteGraphItemRedux, deleteGraphItemRedux } from "../../store/graphitems/graphitems";
import { MethodsResultsManager } from "../methods/methods-manager";
import { isSameTimeSeries } from "./timeseries-helper";

export class TimeSeriesResultsManager {

    private displayService: DisplayService = new DisplayService();

    public static isCompanyTimeSeries(x: ImpreemMethodDTO | TimeSeriesDTO): boolean {
        if (this.isTimeSeries(x)) {
            return x.transformationKey.includes("companies") || x.collectionInDb === CCollectionTypes.newsSentiments || x.collectionInDb === CCollectionTypes.newsMentions;
        }
        return x.timeseries.some(x => x.transformationKey.includes("companies") || x.collectionInDb === CCollectionTypes.newsSentiments || x.collectionInDb === CCollectionTypes.newsMentions);
    }

    //#region utilities
    // check if item is a TimeSeriesDTO
    public static isTimeSeries(item: TimeSeriesDTO | ImpreemMethodDTO | MethodDTO | unknown): item is TimeSeriesDTO {
        return item != null && (item as TimeSeriesDTO).transformationKey !== undefined;
    }

    public static isSameMethodOrTimeSeries(x: TimeSeriesDTO | ImpreemMethodDTO | MethodDTO, item: TimeSeriesDTO | ImpreemMethodDTO | MethodDTO): unknown {
        return this.isTimeSeries(x) && this.isTimeSeries(item) && isSameTimeSeries(x, item)
            || MethodsResultsManager.isMethod(x) && MethodsResultsManager.isMethod(item) && isSameMethod(x, item);
    }
    //#endregion

    //#region graph

    public getGraphTheoryItems(graphItems: GraphItem[], theoryActive: string[] | null | undefined) {
        if (graphItems == null || graphItems?.length === 0 || theoryActive == null) return [];
        return graphItems?.filter(t => theoryActive.includes(t._id));
    }

    public getEditGraphItems(graphItems: GraphItem[], editActive: string | null | undefined) {
        if (graphItems == null || graphItems?.length === 0) return undefined;
        return graphItems.find(t => t._id === editActive);
    }

    public getGraphItems(graphItems: GraphItem[]) {
        if (graphItems == null || graphItems.length === 0) return undefined;
        return cloneDeep(graphItems.filter( t => (t.groupId as graphActive) !== "selection-criteria" && (t.groupId as graphActive) !== "modern-portfolio-theory"));
    }

    public async addGraphItem(
        symbols: string[],
        dispatch: Dispatch<AnyAction>,
        item: TimeSeriesDTO,
        backtest: Backtest,
        graphItems: GraphItem[],
        callback: (backtest: Backtest) => void = () => { // empty
        },
        returnType?: string,
        groupId?: string,
        shouldNavigateOnly = false,
        displayType: graphActive | null | undefined = null,
        saveAnyWay = false,
        originalGraphItem: GraphItem | null = null
    ) {
        let results: GraphItem[] | null = null;
        try {
            // dispatch(updateBacktestStore(backtest));
            if (!TimeSeriesResultsManager.isTimeSeries(item)) {
                console.log("Här");
                console.log(item);
                return;
            }

            if (shouldNavigateOnly) {
                return;
            }

            dispatch(setLoading(true));
            results = await this.getGraphHelper(dispatch, item, backtest, graphItems, returnType, groupId, displayType, saveAnyWay, originalGraphItem, symbols).finally(() => {
                dispatch(setLoading(false));
            });
        } catch (error) {
            console.log(error);
        } finally {
            // empty
        }
        callback(backtest);
        return results;
    }

    public removeGraphItem(graphItems: GraphItem[], item: (TimeSeriesDTO | ImpreemMethodDTO), currentBacktest: Backtest, isLoading: boolean, dispatch: Dispatch<AnyAction>) {
        if (isLoading) {
            return;
        }
        if (TimeSeriesResultsManager.isTimeSeries(item)) {
            dispatch(setLoading(true));
            try {
                
                const graphItem = graphItems.find(x => TimeSeriesResultsManager.isSameMethodOrTimeSeries(x.timeSeries, item));
                if(graphItem){
                    deleteGraphItem(graphItem).then(() => {
                        dispatch(deleteGraphItemRedux(graphItem));
                    }).finally(() => {
                        setTimeout(() => {
                            dispatch(setLoading(false));
                        }, 1000);
                    });
                }
                
            } catch (error) {
                // empty
            }
        }

        setTimeout(() => {
            updateBacktest(currentBacktest);
        }, 1000);
    }

    public async getGraphHelper(
        dispatch: Dispatch<AnyAction>,
        item: TimeSeriesDTO, 
        currentBacktest: Backtest, 
        graphItems: GraphItem[], 
        returnType: string | null | undefined, 
        groupId: string | null = null, 
        displayType: graphActive | null | undefined = null,
        saveAnyWay = false,
        originalGraphItem: GraphItem | null = null,
        symbols: string[] = []) {
        return await this.getTimeSeriesFromBackend(dispatch, item, currentBacktest, graphItems, returnType, groupId, displayType, saveAnyWay, originalGraphItem, symbols);
    }

    //#endregion

    //#endregion

    //#region helper
    public setDefaultTimeSeries(item: TimeSeriesDTO, useSameTransformation = false) {
        item.parameters = {};
        item.categorizeType = null;
        item.normalization = "none";
        const indexOfFirstDash = item.transformationKey.indexOf('-');
        const timeseriesKey = item.transformationKey.substring(indexOfFirstDash + 1);
        item.transformationKey = !useSameTransformation ? 'none-' + timeseriesKey : item.transformationKey;
        const indexOfFirstDashDisplay = item.display.indexOf('-');
        const display = indexOfFirstDashDisplay > -1 ? item.display.substring(0, indexOfFirstDashDisplay) : item.display;
        item.display = display;
        return item;
    }

    //#endregion

    private async getTimeSeriesFromBackend(
        dispatch: Dispatch<AnyAction>,
        item: TimeSeriesDTO, 
        currentBacktest: Backtest, 
        graphItems: GraphItem[], 
        returnType: string | null | undefined, 
        groupId: string | null = null, 
        displayType: graphActive | null | undefined = null,
        saveAnyWay = false,
        originalGraphItem: GraphItem | null = null,
        symbols: string[] = []
    ): Promise<GraphItem[] | null> {

        if (graphItems == null) {
            console.log(2);
            console.log(graphItems);
            return null;
        }
        try {

            const copy = cloneDeep(item) as TimeSeriesDTO;
            copy.normalization = copy.normalization ?? "none";
            if(copy.transformationKey == null){
                const status = new StatusDisplayDTO("Could not find transformationKey", "error");
                dispatch(updateMessage(status));
                return null;
            }
            let exists = null;
            if(item?.normalization != null){
                exists = item?.normalization != null ? graphItemsExists(item.normalization, item.transformationKey, graphItems) : null;
            }
            let result: GraphItem[] = [];
            if (exists == null || saveAnyWay) {
                const res = await getPreviewTransformations(symbols, currentBacktest, copy, returnType, groupId, displayType);
                if(res != null){
                    result = res;
                }
            } else {
                result.push(exists);
            }

            if((!exists || saveAnyWay) && result?.length > 0){
                let newGraphItem = graphItems.concat(result);
                if (displayType === "theory") {
                    const remove = this.displayService.handleGraphActive("theory", newGraphItem, result);
                    const promise = remove.map((item) => deleteGraphItem(item as GraphItem));
                    await Promise.all(promise);
                }
                if(originalGraphItem != null){
                    newGraphItem = newGraphItem.filter((item) => item._id == null && item.groupId === originalGraphItem.groupId ? false: true);
                }
                dispatch(bulkAddAndOverwriteGraphItemRedux(newGraphItem));
                setActiveGraphProperties(newGraphItem, dispatch);
            }

            return result;

        } catch (error) {
            // empty
        }

        return null;
    }

}