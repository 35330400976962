import moment from 'moment';
import * as XLSX from 'xlsx';
import { TimeSeriesDTO } from '../../methods';
import { TimeSeriesHelperValue } from "../../timeseries/timeseries-models";
import { createId } from '../utilites/string.utilities';

export function downloadExcel(ts: TimeSeriesDTO, timeSeriesData: TimeSeriesHelperValue[], addBuy = false) {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert your data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(timeSeriesData.map(data => (addBuy ? {
        Value: data.value,
        Date: data.date,
        Buy: data.buy ? 1 : 0,
    } : {
        Value: data.value,
        Date: data.date,
    })));

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Time Series');

    // Generate an XLSX file
    XLSX.writeFile(workbook, ts.display + createId().substring(0, 2) + moment().format("YYYY-MM-DD") + '.xlsx');
}

export function downloadExcelFundamentalData(ts: TimeSeriesDTO, data: TimeSeriesHelperValue[]) {
    const timeSeriesDataArray = [ {
        ts: ts,
        data: data,
    } ];

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Prepare data for the worksheet
    const headerRow: string[] = [];
    const dateColumn: string[] = [ 'Date' ];
    const rows: { [key: string]: (string | number)[] } = {};

    timeSeriesDataArray.forEach((timeSeriesData) => {
        const { ts, data } = timeSeriesData;
        headerRow.push(ts.display, 'Symbol', 'Value'); // Title row with symbol and value columns
        dateColumn.push('', ''); // Placeholder for the date column

        data.forEach((item, rowIndex) => {
            if (!rows[rowIndex]) {
                rows[rowIndex] = [];
            }
            rows[rowIndex].push(item.date, item.symbol, item.value); // Include symbol and value
        });
    });

    // Combine the header row, date column, and data rows
    const worksheetData: (string | number)[][] = [ headerRow, dateColumn ];
    Object.keys(rows).forEach(rowIndex => {
        worksheetData.push([ ...(rows[rowIndex]) ]);
    });

    // Convert the data to a worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Time Series');

    // Generate an XLSX file
    XLSX.writeFile(workbook, ts?.display + '_' + createId().substring(0, 2) + moment().format("YYYY-MM-DD") + '.xlsx');
}