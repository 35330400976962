import { Tab } from "../../components/tabs";

export function getPeriodicityTabsOptions() {
    const weekPeriodicity = new Tab();
    weekPeriodicity.Id = 'week';
    weekPeriodicity.Name = 'Weekly';
    const monthPeriodicity = new Tab();
    monthPeriodicity.Id = 'month';
    monthPeriodicity.Name = 'Monthly';
    const dayPeriodicity = new Tab();
    dayPeriodicity.Id = 'day';
    dayPeriodicity.Name = 'Daily';
    return [ monthPeriodicity ];
}