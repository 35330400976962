/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, Divider, Flex, Grid, GridItem, HStack, Image, Text } from "@chakra-ui/react";
import { MathJax } from "better-react-mathjax";
import { RangeDatepicker } from "chakra-dayzed-datepicker";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RenderIfVisible from 'react-render-if-visible';
import { Backtest } from "../../backtesting-common-frontend";
import { TimeSeriesDTO } from "../../backtesting-common-frontend/methods";
import { round } from "../../backtesting-common-frontend/shared/utilites/math.utilities";
import { cloneDeep } from "../../backtesting-common-frontend/shared/utilites/object.utilities";
import { TimeSeriesHelperValue } from "../../backtesting-common-frontend/timeseries/timeseries-models";
import ProcessDisplay from "../../components/backtests/progress.display";
import { RunBacktestButton } from "../../components/backtests/run-backtest-button";
import StatisticalComponent from "../../components/backtests/statistics-summary/statistical-summary.component";
import SummaryComponent from "../../components/backtests/summary-backtest";
import BacktestChart from "../../components/graphs/backtest-chart";
import LineChartPretty from "../../components/graphs/line-chart-pretty";
import LazyLoading from "../../components/lazy-loading/lazy-loding";
import { ColorsDark } from "../../components/shared/constants/Colors";
import { ExplainerInfo } from "../../components/shared/explainer/info";
import StrategyTable from "../../components/strategies/strategies";
import { ColumnDTO, RowTableDTO } from "../../components/table";
import { Tab } from "../../components/tabs";
import { Warnings } from "../../components/warnings/warnings";
import { setSelectedSymbol } from "../../store/backtests/backtests";
import { AppState } from "../../store/store";
import { updateNavigationTo } from "../../store/tabs/tabs";

export default function BacktestScreen() {
    const dispatch = useDispatch();
    const [ tabs, setTabs ] = useState<Tab[]>([]);
    const [ currentTab, setCurrentTab ] = useState<Tab>(tabs[0] || new Tab());
    const currentBacktest = useSelector(
        (state: AppState) => state.backtests.backtest
    );
    const [ timeSeries, setTimeSeries ] = useState<TimeSeriesDTO[]>([]);
    const [ filteredBoughtCompanies, setFilteredBoughtCompanies ] = useState<RowTableDTO[]>([]);
    const [ colors ] = useState(new ColorsDark());
    const [ removedZerosBacktest, setRemovedZerosBacktest ] = useState<Backtest | null>(null);
    const [ selectedDates, setSelectedDates ] = useState<Date[]>([ null, null ]);

    React.useEffect(() => {
        const liveTab = new Tab();
        liveTab.Name = 'Live';
        liveTab.Id = 'live' as any;
        liveTab.isActive = true;
        const dateTab = new Tab();
        dateTab.Name = 'At date';
        dateTab.Id = 'at-date' as any;
        dateTab.isActive = false;
        const removeTab = new Tab();
        removeTab.Name = 'Remove unbought days (soft removal)';
        removeTab.Id = 'remove-zero' as any;
        removeTab.isActive = false;
        setCurrentTab(liveTab);
        setTabs([ liveTab, dateTab, removeTab ]);
    }, []);

    React.useEffect(() => {
        if(currentTab.Id === "remove-zero" as any){
            const backtestCloned = cloneDeep(currentBacktest);
            if(backtestCloned){
                const indexes: number[] = [];
                backtestCloned.historicalR = backtestCloned.historicalR.filter(x => {
                    if(x.R === 0){
                        indexes.push(backtestCloned.historicalR.indexOf(x));
                    }
                    return x.R !== 0;
                }).filter(e => e.R !== 0);
                backtestCloned.comparisonSeries = backtestCloned.comparisonSeries.map((s) => {
                    s.R = s.R.filter((x, index) => {
                        if(indexes.includes(index)){
                            return false;
                        }
                        return true;
                    });
                    return s;
                });
                setRemovedZerosBacktest(backtestCloned);
            }
        }
    }, [ currentBacktest, currentTab ]);

    React.useEffect(() => {
        if (currentBacktest != null && currentBacktest) {
            const ts: TimeSeriesDTO[] = [];
            if (
                currentBacktest.historicalR &&
				currentBacktest.historicalR.length > 0
            ) {
                const t: TimeSeriesHelperValue[] = [];
                currentBacktest.historicalR.forEach((r) => {
                    if(r?.date?.date == null) return;
                    const m = new TimeSeriesHelperValue();
                    m.d = r.date.date;
                    m.date = r.date.label;
                    m.value = r.R;
                    t.push(m);
                });
                const ts1 = new TimeSeriesDTO();
                ts1.display = currentBacktest.name;
                ts1.graphValue = t;
                ts.push(ts1);
                const q: TimeSeriesHelperValue[][] = [];
                currentBacktest.comparisonSeries.forEach((s, index) => {
                    s.R.forEach((r) => {
                        if(r?.date?.date == null) return;
                        const m = new TimeSeriesHelperValue();
                        m.d = r.date.date;
                        m.date = r.date.label;
                        m.value = r.R;
                        if (q[index] == null) {
                            q[index] = [];
                        }
                        q[index].push(m);
                    });
                });
                q.forEach((s, index) => {
                    const ts1 = new TimeSeriesDTO();
                    ts1.display = currentBacktest.comparisonSeries[index].name;
                    ts1.graphValue = s;
                    ts.push(ts1);
                });
                setTimeSeries(ts);
            }
        }
    }, [ currentBacktest ]);

    React.useEffect(() => {
        if (currentBacktest && currentBacktest.historicalR?.length > 0) {
            // eslint-disable-next-line max-len
            const historicalPortfolio = currentBacktest.historicalPortfolios.filter(x => selectedDates[0] != null && selectedDates[1] != null ? new Date(x.date.label).getTime() >= selectedDates[0].getTime() && new Date(x.date.label).getTime() <= selectedDates[1].getTime() : true);
            const rows: RowTableDTO[] = [];
            if (historicalPortfolio) {
                historicalPortfolio.forEach((item, i) => {
                    const dateColumn = new ColumnDTO();
                    const companiesColumn = new ColumnDTO();
                    const returnValue = new ColumnDTO();
                    const numberOfCompanies = new ColumnDTO();
                    if(i === 0){
                        dateColumn.title = "Date"; 
                        companiesColumn.title = "Companies";
                        returnValue.title = "Return";
                        numberOfCompanies.title = "Number of companies";
                    }
                    numberOfCompanies.value = <p>{item.companies.length}</p>;
                    dateColumn.value = <p>{item.date.label}</p>;
                    companiesColumn.value = (
                        <Box overflowY='scroll' maxH='400px' /* Adjust max height as needed */>
                            <Grid templateColumns='repeat(20, 1fr)' gap={4}>
                                {item.companies.map((company, i) => (
                                    <GridItem key={i + 'companies'} w='100%' h='auto'>
                                        <Button onClick={() => { 
                                            dispatch(setSelectedSymbol(company.symbol));
                                            dispatch(updateNavigationTo("profile"));
                                        }}>
                                            <Image src={company.image} h={10} />
                                            <Text>{company.symbol}</Text>
                                        </Button>
                                    </GridItem>
                                ))}
                            </Grid>
                            <Divider />
                        </Box>
                    );
                    returnValue.value = <p>{round(currentBacktest.historicalR[i].R * 100, 1) + "%"}</p>;
                    const newRow = new RowTableDTO();
                    newRow.columns = [ dateColumn, returnValue, numberOfCompanies, companiesColumn ];
                    rows.push(newRow);
                });
                setFilteredBoughtCompanies(rows);
            }
        }
    }, [ currentBacktest, dispatch, selectedDates ]);

    React.useEffect(() => {
        if(currentBacktest && currentBacktest.historicalPortfolios?.length > 0){
            const fromDate = new Date(currentBacktest.historicalPortfolios[0].date.label);
            const toDate = new Date(currentBacktest.historicalPortfolios[currentBacktest.historicalPortfolios.length - 1].date.label);
            setSelectedDates([ fromDate, toDate ]);
        }
    }, [ currentBacktest ]);

    return (
        <div style={{ width: '100%', height: '100%', overflow: 'scroll' }}>
            <Warnings />
            <Box p={5}>
                {currentBacktest && <SummaryComponent
                    backtestResults={currentBacktest}
                />}
                <LazyLoading>
                    <>
                        <Box display="flex" justifyContent="space-between" mb={5}>
                            <Box
                                bg={'whiteAlpha.100'}
                                borderRadius="md"
                                boxShadow="md"
                                p={4}
                                width="calc(50% - 5px)"
                            >
                                <div style={{ width: '100%' }}>
                                    {tabs && tabs.length > 0 && <>
                                        <HStack>
                                            {tabs.map((tab, index) => {
                                                return (
                                                    <Button key={index} onClick={() => {
                                                        setCurrentTab(tab);
                                                    }} colorScheme={tab.isActive ? "blue" : "gray"}>{tab.Name}</Button>
                                                );
                                            })}
                                        </HStack>
                                    </>}
                                </div>
                                {currentBacktest != null && currentBacktest ? (
                                    <div>
                                        <div style={{ width: '100%', padding: 20 }}>
                                            <RunBacktestButton />
                                            <Divider mt={5} mb={5} />
                                            <ExplainerInfo.Information 
                                                title='How does the backtest work?'
                                                text={
                                                    <>
                                                        <p>
                The backtest is a simulation of a strategy used to evaluate its performance. It involves applying the strategy to historical data to see how it would have performed in the past. This simulation uses historical data to calculate the returns that would have been achieved.
                                                        </p>
                                                        <br></br>
                                                        <br></br>
                                                        <h3>Okay, but how does it work technically?</h3>
                                                        <p>
                1. You define your sample.
                                                        </p>
                                                        <p>This is typically an industry, commodity, or index, filtered by your screening methods. Each asset's return is calculated using the regression model:</p>
                                                        <MathJax>
                                                            {`
                    \\[
                        R^{a_i}_{\\scriptsize\\substack{t+n}} = (D1_t*D2_t...) * \\beta_t
                    \\]
                `}
                                                        </MathJax>
                                                        <p>
                Where 
                                                            <MathJax>
                                                                {`
                        \\[
                            R^{a_i}_{\\scriptsize\\substack{t+n}}
                        \\]
                    `}
                                                            </MathJax> 
                is the future return of asset 
                                                            <MathJax>
                                                                {`
                        \\[
                            a
                        \\]
                    `}
                                                            </MathJax> 
                at time 
                                                            <MathJax>
                                                                {`
                        \\[
                            t+n
                        \\]
                    `}
                                                            </MathJax>, calculated by applying the explanatory variables (dummy variables such as absolute, outliers, etc.) at time 
                                                            <MathJax>
                                                                {`
                        \\[
                            t
                        \\]
                    `}
                                                            </MathJax>. The portfolio return is the average of each asset's return:
                                                        </p>
                                                        <MathJax>
                                                            {`
                    \\[
                        P_{t+n} = \\frac{1}{N} \\sum_{a=1}^{N} R^{a_i}_{\\scriptsize\\substack{t+n}}
                    \\]
                `}
                                                        </MathJax>
                                                        <p>
                Where 
                                                            <MathJax>
                                                                {`
                        \\[
                            P_{t+n}
                        \\]
                    `}
                                                            </MathJax> 
                is the portfolio return at time 
                                                            <MathJax>
                                                                {`
                        \\[
                            t+n
                        \\]
                    `}
                                                            </MathJax>, which is the average return of all assets in the portfolio. Here, 
                                                            <MathJax>
                                                                {`
                        \\[
                            n
                        \\]
                    `}
                                                            </MathJax> 
                is the periodicity, representing the future time period (e.g., days, weeks, months).
                                                        </p>
                                                        <br></br>
                                                        <br></br>
                                                        <p>
                2. You define your explanatory variables.
                                                        </p>
                                                        <p>Your methods are explanatory variables, which are dummy variables that either occurred or did not occur, represented as 1 or 0. These explanatory variables are applied to all assets' regression models to calculate their future returns.</p>
                                                        <MathJax>
                                                            {`
                    \\[
                        D_{it}
                    \\]
                `}
                                                        </MathJax>
                                                        <p>
                Where 
                                                            <MathJax>
                                                                {`
                        \\[
                            D_{it}
                        \\]
                    `}
                                                            </MathJax> 
                represents the binary dummy variables (0 or 1) indicating different categorical influences, and 
                                                            <MathJax>
                                                                {`
                        \\[
                            t
                        \\]
                    `}
                                                            </MathJax> 
                is the time representing the day, week, or month when the variable is measured. Essentially, 
                                                            <MathJax>
                                                                {`
                        \\[
                            t+n
                        \\]
                    `}
                                                            </MathJax> 
                refers to the next step (day, week, or month) when the selling decision was executed and the next buying decision is made.
                                                        </p>
                                                        <p>
                When displaying the values in the graph, we only use the observations when all explanatory variables are 1. You could potentially add the intercept.
                                                        </p>
                                                        <br></br>
                                                        <br></br>
                                                        <p>
                3. You simulate your strategy.
                                                        </p>
                                                        <p>
                Using the historical data, you apply your strategy to calculate the portfolio returns at each time step. The backtest calculates returns based on the defined sample and explanatory variables, and evaluates the performance metrics such as return, risk, Sharpe ratio, etc.
                                                        </p>
                                                    </>
                                                } 
                                            />
                                            {currentBacktest?.historicalR.length > 0 ? (
                                                <div>
                                                    <ProcessDisplay
                                                        backtestResults={currentBacktest}
                                                    />
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            paddingTop: 0,
                                                        }}
                                                    >
                                                        {currentTab.Id === ('live' as any) ? (
                                                            <BacktestChart backtestResults={currentBacktest} />
                                                        ) :
                                                            currentTab.Id === ('remove-zero' as any) ? 
                                                                <BacktestChart removeHelperSeries={true} backtestResults={removedZerosBacktest} />
                                                                :
                                                                (
                                                                    <LineChartPretty
                                                                        TimeSeriesDTOs={timeSeries}
                                                                        shouldDisplayPreviewMethod={false}
                                                                        transform={true}
                                                                    />
                                                                )
                                                        }
                                                        <div
                                                            style={{
                                                                width: '100%',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                marginTop: -10,
                                                            }}
                                                        >
                                                            <div>
                                                                <p
                                                                    style={{
                                                                        fontWeight: '700',
                                                                        fontSize: 15,
                                                                        color: colors.blue,
                                                                        marginBottom: 10,
                                                                    }}
                                                                >
                                                                             At date:{' '}
                                                                    {currentBacktest?.atDate != null
                                                                        ? currentBacktest?.atDate
                                                                        : '-'}{' '}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                ) : null}
                            </Box>
                            <Box bg={'whiteAlpha.100'}
                                borderRadius="md"
                                boxShadow="md"
                                p={4}
                                width="calc(50% - 5px)">
                                <div style={{ width: '100%', padding: 20, display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ flex: 1 }}>
                                        {currentBacktest && <StatisticalComponent backtest={currentBacktest} evaluation={currentBacktest.statisticsSummary?.evaluation} />}
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        {currentBacktest && <StrategyTable />}
                                    </div>
                                </div>
                            </Box>
                        </Box>
                    </>
                </LazyLoading>
                <Box justifyContent="space-between">

                    <Box bg={'whiteAlpha.100'} borderRadius="md" boxShadow="md" p={4} width="100%">
                        <p>Choose date range</p>
                        <RangeDatepicker
                            selectedDates={selectedDates}
                            onDateChange={setSelectedDates} />
                    </Box>

                    <Box bg={'whiteAlpha.100'} borderRadius="md" boxShadow="md" p={4} width="100%">
                        <Grid templateColumns="repeat(4, 1fr)" gap={6}>
                            <GridItem>
                                <Box p={4} >
            Date
                                </Box>
                            </GridItem>
                            <GridItem>
                                <Box p={4}>
            Return
                                </Box>
                            </GridItem>
                            <GridItem>
                                <Box p={4}>
            Number of companies
                                </Box>
                            </GridItem>
                            <GridItem>
                                <Box p={4}>
            Companies
                                </Box>
                            </GridItem>
                            {filteredBoughtCompanies.map((x, i) => {
                                return x.columns.map((e, y) => (
                                    <RenderIfVisible key={y + "bC"} defaultHeight={65}>
                                        <GridItem key={y + "bC"}>
                                            <Flex>
                                                {e.value}
                                            </Flex>
                                        </GridItem>
                                    </RenderIfVisible>
                                ));
                            })}
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </div>
    );
}
