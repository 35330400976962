import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { BacktestingSetting, GraphItem } from "../../backtesting-common-frontend";
import { AiNews } from "../../backtesting-common-frontend/ai-news/ai-news-model";
import { editMethod } from "../../backtesting-common-frontend/http-utilities/http-utilities/methods/methods-backend.service";
import { getStrategy } from "../../backtesting-common-frontend/http-utilities/http-utilities/strategy/strategy";
import { addTimeseries, getTimeSeriesById, removeTimeSeries } from "../../backtesting-common-frontend/http-utilities/http-utilities/time-series/time-series-backend.service";
import { TimeSeriesManager } from "../../backtesting-common-frontend/managers/time-series-manager";
import { APIMethodSettings, ImpreemMethodDTO, TimeSeriesDTO, methodKey } from "../../backtesting-common-frontend/methods";
import { cloneDeep } from "../../backtesting-common-frontend/shared/utilites/object.utilities";
import { StatusDisplayDTO } from "../../backtesting-common-frontend/status/error-handling";
import { StrategyDTO } from "../../backtesting-common-frontend/strategies/strategy";
import { CriteriaBoardMethod } from "../../backtesting-common-frontend/techniques";
import { deepClone } from "../../backtesting-common-frontend/worker/transformations/transformation-service";
import { Tab } from "../../components/tabs";
import { MethodsResultsManager } from "../../managers/methods/methods-manager";
import { TabsManager } from "../../managers/tabs/tabs-manager";
import { isSameTimeSeries } from "../../managers/time-series/timeseries-helper";
import { setLoading, updateMessage } from "../../store/backtests/backtests";

export class MethodAddUpdateExtras{
    public apiMethodSettings?: APIMethodSettings;
    public aiNews?: AiNews;
}

export async function createOrEditNewMehtodOrCurrent(
    dispatch:  Dispatch<AnyAction>,
    test: BacktestingSetting | null | undefined,
    currentTab: Tab,
    methodManager: MethodsResultsManager,
    workInProgress: CriteriaBoardMethod | null | undefined,
    name: string,
    mainTs: GraphItem | null,
    newTimeSeries: TimeSeriesDTO[],
    strategy: StrategyDTO | null,
    addMethodKey?: string | null,
    extras?: MethodAddUpdateExtras,
    forceNew?: boolean,
    overwriteMethodKey?: methodKey | null) {
    if (test == null || strategy == null) return;
    dispatch(setLoading(true));
    const current = forceNew ? null : MethodsResultsManager.getCurrentMethod(test, currentTab);
    const method = cloneDeep(methodManager.create(workInProgress, current, name, currentTab, extras, overwriteMethodKey));
    if (method == null) {
        const status = new StatusDisplayDTO("Could not find method", "error");
        dispatch(updateMessage(status));
        return null;
    }
    const removeTs: TimeSeriesDTO[] = [];
    newTimeSeries.forEach(p => {
        const exits = method.timeseries.some(x => isSameTimeSeries(x, p));
        if (exits) {
            removeTs.push(p);
        }
    });
    const promiseAll: Promise<void>[] = [];
    if (removeTs.length > 0) {
        const anyId = removeTs.filter(x => x._id != null);
        if (anyId.length > 0) {
            anyId.forEach(x => {
                promiseAll.push(removeTimeSeries(x));
                const extraTs = x.timeseries;
                extraTs.forEach(e => {
                    if(TimeSeriesManager.isTimeSeries(e)){
                        promiseAll.push(removeTimeSeries(e));
                    }
                });
            });
        }
    }
    await Promise.all(promiseAll);
    // check if paramters exists
    if (method.parameters == null) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        method.parameters = {} as CriteriaBoardMethod;
    }
    method.parameters["main"] = mainTs ? mainTs.timeSeries.transformationKey : undefined;
    const methodKey = overwriteMethodKey ?? TabsManager.convertTabIdToMethodKey(currentTab.Id);
    const newMethod = await methodManager.add(method, current, test, methodKey, addMethodKey) as ImpreemMethodDTO;
    // add time series
    const ts = deepClone(newTimeSeries);
    const addPromises: Promise<TimeSeriesDTO | null>[] = [];
    for(let p = 0; p < ts.length; p++){
        const tsElement = deepClone(ts[p]);
        if (ts[p].main) {
            tsElement._id = undefined;
            tsElement.main = undefined;
        }
        let extraTs = tsElement?.timeseries?.filter(x => TimeSeriesManager.isTimeSeries(x)) ?? [];
        const allOthers = (tsElement?.timeseries?.filter(x => !TimeSeriesManager.isTimeSeries(x)) ?? []) as unknown as string[];
        const tsAllOthers = await Promise.all(allOthers.map(c => getTimeSeriesById(c)));
        extraTs = extraTs.concat(tsAllOthers.filter(x => x != null).map(x => x as TimeSeriesDTO));
        if(extraTs?.length > 0){
            extraTs = extraTs.map(x => {
                x.graphValue = [];
                return x;
            });
            const allTs = await Promise.all(extraTs.map(ts => addTimeseries(ts, newMethod, newMethod.methodKey, test?._id, false)));
            tsElement.timeseries = allTs.map(x => x._id) as any;
        }
        addPromises.push(addTimeseries(tsElement, newMethod, newMethod.methodKey, test?._id));
    }
    const tses = await Promise.all(addPromises);
    newMethod.timeseries = tses.map(x => x._id) as any;
    await editMethod(newMethod, null);
    await getStrategy(strategy);
    dispatch(setLoading(false));
    return newMethod;
}
