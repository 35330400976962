import { GraphItem, OverallMethodCategory, Portfolio, StatisticalProperties } from "..";
import { AiNews } from "../ai-news/ai-news-model";
import { ImpreemDate } from "../date/impreem-date";
import { SelectionFilterMenu } from "../filters";
import { Periodicity } from "../shared/periodicity";
import { createId } from "../shared/utilites/string.utilities";
import { Criteria, CriteriaBoardMethod, criteriaSign } from "../techniques";
import { PieChartDTO } from "../timeseries/models/timeseries.models";
import { TimeSeriesHelperValue } from "../timeseries/timeseries-models";
import { TransformationTypeKey } from "../transformations/transformations-dtos";

export type methodKey =
    "AiNews" | "API-methods" | "ForecastBeta" | "MarketCondition" | "ProbabilityModel" | "SelectionCriteria" | "SharpeRatioModel" | "Theory" | "UnivariateForecast" | "ModernPortfolioTheory";
export type MethodKey = "accerleration" | "belowAbove" | "beta" | "cointegration" | "diff" | "funnel" | "growth" | "intersect" | "log_growth" | "reg_growth" | "t.test";

export type MethodCategory = "method" | "return";

export type GrowthCriteriaKey = "log_growth" | "reg_growth";

export type GrowthCriteriaCategory = "Absolute value" | "Acceleration" | "Cointegration" | "Comparison" | "Regression fit";

export type TransformationCategory = "Technical Indicators";

export type SpecificCriteriaMethodKey = "sma";

export type SpecificCriteriaMethodCategory = "TA";

export type UtilityMethodKey = "evaluation" | "getSymbolsMeta";

export type UtilityMethodCategory = "evaluation" | "utilities";

export type CompanyRelatedTimeseries = "eps" | "price";

export class MethodDTO {

    public _id: string;

    public id: string;

    public key: MethodKey | methodKey | string;

    public name: string;

    public display: string;

    public category: OverallMethodCategory;

    public subcategoy: GrowthCriteriaCategory | MethodCategory | SpecificCriteriaMethodCategory | TransformationCategory | UtilityMethodCategory;

    public methods: (ImpreemMethodDTO | MethodDTO)[] = [];

    public methodPath: string = null;

    public timeseries: TimeSeriesDTO[] = [];

    public shouldValidate = false;

    public active = false;
    
    public editActive = false;

    public frontendId: string = createId();

}

export type betaCategorizeType = "sublist" | "absolute" | "interval";

export class GeneralCategorize {
    
    public key: betaCategorizeType = "sublist";
    
    public categorizeType: (string | number)[] | null = null;

    public intervalYear = 5;

    public symbols: string[] = [];

    public sign: criteriaSign = "up";

    public interval: number[] = [ 0, 1 ];

}

export class AlphaCategorize {
    
    public signifiance?: number | null;

    public performance?: number | null;

    public intervalYear = 5;

}

export type categorizeType = "beta" | "alpha";

export class CategorizeType {
    
    public key: categorizeType = "beta";
    
    public meta: GeneralCategorize | AlphaCategorize | null = null;

    public isOnFly = false;
}

export type timeSeriesScaleType = "none" | "normal" | "std";

export class TimeSeriesDTO {

    public _id?: string;

    public collectionInDb: string;

    public field: string;

    public column: string;

    public date: string;

    public ID: string;

    public transformationKey: string;

    public transformations: TransformationTypeKey[] = [];

    public timeSeriesKey: string;

    public graphValue: (TimeSeriesHelperValue)[];

    public display: string;

    public periodicity: Periodicity;

    public parameters: any;

    public category: string;

    public categorizeType: CategorizeType | null = null;

    public normalization: timeSeriesScaleType | undefined = "none";

    public multiply: number | null = null;

    public stationary: boolean | null = false;

    public main? = false;

    public clientId: string;

    public timeseries: TimeSeriesDTO[] = [];

    // helper
    public skip = false;

}

export type sign = "down" | "up";

export type mlType = "all" | "per-company";

export type mlMethodType = "probability" | "sharpe-ratio";

export class ImpreemMethodDTO extends MethodDTO {
    public screeningCategorizeType: CategorizeType | null = null;

    public screeningTransformations: TransformationTypeKey[] = [];

    public _id: string;

    public isMultipleSeriesMethod: boolean;

    public frontendId: string = createId();

    public timeSeriesGraphResults: GraphItem;

    public previewBacktestIds: string[] = [];

    public comparisionKey: string;

    public selectionFilters: SelectionFilterMenu[] = [];

    public mlType: mlType;

    public mlMethodType: mlMethodType;

    public methodKey: methodKey;

    public parameters: Criteria | CriteriaBoardMethod | ModernPortfolioSelectionSettings | CriteriaBoardMethod | null | AiNews;

    public methodType: mlMethodType;

    public firstModelh5Url: string[] = [];

    public firstModel: object;

    public all: mlType;

    public results: any;

    public date: Date;

    public clientId: string;

    public testId: string;

    public inProgress: boolean;

    public apiMethodSettings: APIMethodSettings;

    public screeningResults: Portfolio[] = [];
}

export class APIMethodSettings {
    public useTimeSeries: boolean;

    public timeSeries: TimeSeriesDTO[] = [];

    public endpoint: string;
}

export class APIMethodSendDTO {
    public timeSeriesValues: TimeSeriesHelperValue[] = [];

    public date: ImpreemDate;

    public symbols: (number | string)[] = [];
}

export class APIMethodReceiveDTO {
    public symbols: (number | string)[] = [];
}

export class AndConditionDTO {
    public method: MethodDTO;

    public blockNumber: number;

    public initOrConditionStep: OrConditionDTO;
}

export class OrConditionDTO {
    public id: string = createId();

    public andConditions: AndConditionDTO[] = [];

    public results: MethodDTO[][] = [];

    public isInit = false;
}

export class StepsDTO {
    public orConditions: OrConditionDTO[] = [];

    public stepNumber: number;

    public type: string;
}

export class ModernPortfolioSelectionSettings {
    public pieChart: PieChartDTO; 
    public evaluationMetrics: StatisticalProperties[];
    public timeSeries: TimeSeriesDTO[];
    public allocationMethod: string;
    public criteriaBoard: CriteriaBoardMethod;
}
