import { SunIcon } from '@chakra-ui/icons';
import { List, ListIcon, ListItem } from '@chakra-ui/react';
import { Backtest, StatisticalProperties } from '../../../backtesting-common-frontend';
import { round } from '../../../backtesting-common-frontend/shared/utilites/math.utilities';
import { okNumber } from '../../../backtesting-common-frontend/shared/utilites/number.utilities';
import { transformReturns, transformToPrecent } from '../../../backtesting-common-frontend/shared/utilites/transform';
import { ColumnDTO, RowTableDTO } from '../../table';

export default function StatisticalComponent({ backtest, evaluation, onlyTopRelevant }: { backtest?: Backtest, evaluation: StatisticalProperties[], onlyTopRelevant?: boolean}) {
    const columns: ColumnDTO[] = [];
    // create columns for each backtestResults.statisticsSummary?.evaluation
    if (evaluation) {
        evaluation.forEach((item) => {
            columns.push({
                title: item.display,
                value: round(okNumber(item.value) ? item.value as number : 0, 7) + " (" + item.type + ")",
            });
        });
    }

    const rows: RowTableDTO[] = [];
    // create rows for every % 3 columns
    if (columns.length > 0) {
        for (let i = 0; i < columns.length; i += 1) {
            rows.push({
                columns: columns.slice(i, i + 1),
            });
        }
    }

    function lazyCalcReturn(totalReturn: number, numberOfYears: number): number {
        // Convert the total return to a multiplier
        const totalMultiplier = (totalReturn / 100) + 1;
    
        // Calculate the nth root of the total multiplier for the given number of years
        const annualMultiplier = Math.pow(totalMultiplier, 1 / numberOfYears);
    
        // Subtract 1 to find the annual return, then convert it to a percentage
        const annualReturn = (annualMultiplier - 1) * 100;
    
        // Format the result to 1 decimal place and return
        return annualReturn;
    }

    let annualReturn = 0;
    if(backtest){
        const numberOfObservations = backtest?.statisticsSummary?.evaluation?.find(x => x.display === 'Number of observations')?.value as number | null;
        if(numberOfObservations != null){
            const numberOfYears = numberOfObservations / 252;
            if(numberOfYears >= 4) {
                const totalReturn = backtest.summary.portfolioReturn;
                annualReturn = lazyCalcReturn(totalReturn, numberOfYears);
            }
        }
    }

    const tTest = evaluation?.find(x => x.display === 'T-test')?.value as number | null;
    const ttestLessthan = evaluation?.find(x => x.display === 'T-test (less than)')?.value as number | null;
    const returns = backtest != null && backtest?.historicalR?.length > 0 ? transformToPrecent(transformReturns(backtest.historicalR.map(r => r.R))) : [];
    const comparisionReturns = backtest != null && backtest?.comparisonSeries?.length > 0 ? transformToPrecent(transformReturns(backtest.comparisonSeries[0].R.map(r => r.R))) : [];

    return (
        <div style={{ width: '100%' }}>
            <List spacing={3}>
                {!onlyTopRelevant && rows.map(s => {
                    if(s?.columns?.[0]?.title == null || s?.columns?.[0]?.value == null) return;
                    return (
                        <>
                            <ListItem>
                                <ListIcon as={SunIcon} color='green.500' />
                                {s.columns[0].title}: {s.columns[0].value}
                            </ListItem>
                        </>
                    );
                })}
                {!onlyTopRelevant && annualReturn != null && annualReturn !== 0 && <ListItem>
                    <ListIcon as={SunIcon} color='green.500' />
                    Annual Return: {round(annualReturn, 3)}%
                </ListItem>}
                {onlyTopRelevant && <>
                    <ListItem>
                        <ListIcon as={SunIcon} color='green.500' />
                    T-test: {round(tTest, 5)}
                    </ListItem>
                    <ListItem>
                        <ListIcon as={SunIcon} color='green.500' />
                        T-test (less than): {round(ttestLessthan, 5)}
                    </ListItem>
                    <ListItem>
                        <ListIcon as={SunIcon} color='green.500' />
                        Returns: {returns[returns.length - 1]}
                    </ListItem>
                    <ListItem>
                        <ListIcon as={SunIcon} color='green.500' />
                        Comparison Returns: {comparisionReturns[comparisionReturns.length - 1]}
                    </ListItem>
                    <ListItem>
                        <ListIcon as={SunIcon} color='green.500' />
                        Observation count: {backtest.buySignals?.length > 0 ? backtest.buySignals.length : 0}
                    </ListItem>
                </>
                }
                <ListItem>
                    <ListIcon as={SunIcon} color='green.500' />
                    Latest backtest date: {backtest?.buySignals?.length > 0 ? backtest.buySignals[backtest.buySignals.length - 1].label : 'N/A'}
                </ListItem>
            </List>
        </div>
    );
}