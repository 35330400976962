
import { Backtest, BacktestResultsDTO, BacktestingMetaDTO, BacktestingSetting } from "../../..";
import { Periodicity } from "../../../shared/periodicity";
import { StatusDisplayDTO } from "../../../status/error-handling";
import { sendPost } from "../frontend/http-utilities";

let preventDuplicate = false;
export async function getBacktests(searchValue?: string, seatchForTags?: boolean, searchForName?: boolean, searchForDate?: boolean, searchForTTest?: boolean): Promise<Backtest[]> {
    const data = {
        search: searchValue,
        searchForTags: seatchForTags,
        searchForName: searchForName,
        searchForDate: searchForDate,
        searchForTTest: searchForTTest,
    };
    if (preventDuplicate) {
        return [];
    }
    preventDuplicate = true;
    setTimeout(() => {
        preventDuplicate = false;
    }, 1000);
    return sendPost("backtests/get-backtests", data)
        .then(e => {
            return e.data as Backtest[];
        })
        .catch((e) => {
            return null;
        });
}

export async function getBacktest(ids: string[]): Promise<Backtest[]> {
    const data = {
        ids: ids,
    };
    return sendPost("backtests/get-backtest", data)
        .then(e => (e.data as Backtest[]))
        .catch((e) => {
            return null;
        });
}

export async function createNewBacktest(b: BacktestingSetting, periodicity: Periodicity, name: string, tags: string[]): Promise<Backtest> {
    const data = {
        backtest: { periodicity, name, test: b, tags },
    };
    return sendPost("backtests/create-backtest/", data).then(e => {
        return e.data as Backtest;
    });
}

export async function shortIt(backtest: Backtest): Promise<Backtest> {
    const data = {
        backtestResults: backtest,
    };
    return sendPost("backtests/short/", data).then(e => {
        return e.data as Backtest;
    });
}

export async function removeUnboughtDays(backtest: Backtest): Promise<Backtest> {
    const data = {
        backtestResults: backtest,
    };
    return sendPost("backtests/remove-unbought-days/", data).then(e => {
        return e.data as Backtest;
    });
}

export async function runBacktest(backtestId: string, meta?: BacktestingMetaDTO, queue?: boolean, returnValues?: boolean): Promise<BacktestResultsDTO | void> {
    const data = {
        meta: meta,
        backtestId: backtestId,
        queue: queue,
        returnValues: returnValues,
    };
    return sendPost("backtests/run-backtest", data)
        .then(e => {
            return e.data as BacktestResultsDTO;
        })
        .catch(() => {
            return null;
        });
}

export async function runNewsBacktests(term: string, impact: string, tags: string[], sample: string): Promise<void> {
    const data = {
        term: term,
        impact: impact,
        tags: tags,
        sample: sample,
    };
    return sendPost("strategies/run-news-backtests", data)
        .then(() => {
            return;
        })
        .catch(() => {
            return;
        });
}

export async function runParalellBacktests(backtestId: string, meta?: BacktestingMetaDTO): Promise<void> {
    const data = {
        meta: meta,
        backtestId: backtestId,
    };
    return sendPost("backtests/run-backtest-paralell", data)
        .catch(() => {
            return;
        });
}

export async function updateBacktest(backtest: Backtest, anyState: any = null, setActive?: boolean): Promise<Backtest[]> {
    if (anyState) {
        anyState(backtest);
    }
    const data = {
        backtest: backtest,
        setActive,
    };
    return sendPost("backtests/update-backtest", data)
        .then(e => (e.data as Backtest[]))
        .catch((e) => {
            return null;
        });
}

export async function deleteBacktest(backtest: Backtest, anyState: any = null, newLoading: StatusDisplayDTO = null): Promise<Backtest[]> {
    if (anyState) {
        anyState(null);
    }
    const data = {
        backtest: backtest,
        statusDisplay: newLoading,
    };
    return sendPost("backtests/delete-backtest", data)
        .then(e => (e.data as Backtest[]))
        .catch((e) => {
            return null;
        });
}

export async function getBacktestSample(backtest: Backtest): Promise<string[]> {
    const data = {
        backtest: backtest,
    };
    return sendPost("backtests/get-backtest-sample", data).then(e => {
        return e.data as string[];
    });
}

export async function createDummyBacktest(backtest: Backtest): Promise<Backtest> {
    const data = {
        backtest: backtest,
    };
    return sendPost("backtests/create-dummy-backtest", data).then(e => {
        return e.data as Backtest;
    });
}

export async function createBacktestUtilities(backtest: Backtest, symbols: string[]): Promise<Backtest> {
    const data = {
        backtest: backtest,
        symbols: symbols,
    };
    return sendPost("backtests/create-backtest-utilities", data).then(e => {
        return e.data as Backtest;
    });
}

export async function joinBacktest(backtestId: string, otherBacktestsIds: string[]): Promise<Backtest> {
    const data = {
        backtestId: backtestId,
        otherBacktestsIds: otherBacktestsIds,
    };
    return sendPost("backtests/join-backtest", data).then(e => {
        return e.data as Backtest;
    });
}

export async function updateDummyBacktest(backtest: Backtest): Promise<void> {
    return sendPost("backtests/update-dummy", backtest);
}

export async function deleteBelowTtestThreshold(): Promise<void> {
    return sendPost("backtests/delete-below-ttest-threshold", {});
}