import { Box, Button, Divider, Flex, FormControl, FormLabel, Heading, Image, List, ListItem, Switch, Text } from '@chakra-ui/react';
import moment from 'moment';
import React, { useState } from 'react';
import { FaCheck, FaMinusCircle, FaPlusCircle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Backtest, Portfolio, Series, StatisticalProperties } from '../../backtesting-common-frontend';
import { ImpreemDate } from '../../backtesting-common-frontend/date/impreem-date';
import { TimeSeriesDTO } from '../../backtesting-common-frontend/methods';
import { generateDateArray } from '../../backtesting-common-frontend/shared/utilites/dates.utilities';
import { round } from '../../backtesting-common-frontend/shared/utilites/math.utilities';
import { cloneDeep } from '../../backtesting-common-frontend/shared/utilites/object.utilities';
import { StatusDisplayDTO } from '../../backtesting-common-frontend/status/error-handling';
import { PieChartDTO } from '../../backtesting-common-frontend/timeseries/models/timeseries.models';
import { TimeSeriesHelperValue } from '../../backtesting-common-frontend/timeseries/timeseries-models';
import { TimeSeriesService } from '../../backtesting-common-frontend/timeseries/timeseries.service';
import { SelectBacktests } from '../../components/backtests/select-backtests';
import LineChartPretty from '../../components/graphs/line-chart-pretty';
import { PieChart, pieChartColors } from '../../components/graphs/pie-chart';
import LazyLoading from '../../components/lazy-loading/lazy-loding';
import { ColorsLight } from '../../components/shared/constants/Colors';
import { ExplainerInfo } from '../../components/shared/explainer/info';
import { RowTableDTO } from '../../components/table';
import TableComponent from '../../components/table/table';
import { setLoading, updateMessage } from '../../store/backtests/backtests';
import { AppState } from '../../store/store';

export default function PortfolioAllocationScreen() {
    const dispatch = useDispatch();
    const graphItems = useSelector((state: AppState) => state.graphItems.graphItems);
    const backtest = useSelector((state: AppState) => state.backtests.backtest);
    const menuCategory = useSelector((state: AppState) => state.backtests.menuCategory);
    const [ selectedBacktests, setSelectedBacktests ] = React.useState<Backtest[]>([]);
    const [ portfolioBacktests, setPortfolioBacktests ] = React.useState<Backtest[]>([]);
    const [ timeSeriesService ] = React.useState(new TimeSeriesService());
    const [ timeSeries, setTimeSeries ] = useState<TimeSeriesDTO[]>([]);
    const [ pieChartInformation, setPieChartInformation ] = useState<PieChartDTO>(null);
    const [ evaluationMetrics, setEvaluationMetrics ] = useState<StatisticalProperties[]>([]);
    const [ methodSelected, setMethodSelected ] = useState<string>("");
    const [ shortedBacktests, setShortedBacktests ] = useState<Backtest[]>([]);
    const [ colors ] = useState<ColorsLight>(new ColorsLight());
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ tTest, setTTest ] = useState<number>(0);
    const [ appendTs, setAppend ] = useState<string[]>([]);
    const [ isRelative, setIsRelative ] = useState(false);

    React.useEffect(() => {
        const status = new StatusDisplayDTO("Please wait...", "info");
        dispatch(updateMessage(status));
        dispatch(setLoading(isLoading));
    }, [ dispatch, isLoading ]);

    const selectedBacktestsRow: RowTableDTO[] = [];
    for(let i = 0; i < selectedBacktests.length; i++) {
        selectedBacktestsRow.push({
            columns: [
                {
                    title: 'Name',
                    value: selectedBacktests[i].name.substring(0, 20) + '...',
                },
                {
                    title: 'Is short',
                    value:
                    shortedBacktests.find(x => x._id === selectedBacktests[i]._id) ? 
                        <FaCheck size={20} 
                            color={shortedBacktests.find(x => x._id === selectedBacktests[i]._id) ? colors.green : colors.blue} 
                            onClick={() => {
                                // remove if already shorted
                                if(shortedBacktests.find(x => x._id === selectedBacktests[i]._id)) {
                                    setShortedBacktests(shortedBacktests.filter(t => t._id !== selectedBacktests[i]._id));
                                    return;
                                }
                                setShortedBacktests(res => [ ...res, selectedBacktests[i] ]);
                            }} />
                        :
                        <FaPlusCircle size={20} 
                            color={shortedBacktests.find(x => x._id === selectedBacktests[i]._id) ? colors.green : colors.blue} 
                            onClick={() => {
                                // remove if already shorted
                                if(shortedBacktests.find(x => x._id === selectedBacktests[i]._id)) {
                                    setShortedBacktests(shortedBacktests.filter(t => t._id !== selectedBacktests[i]._id));
                                    return;
                                }
                                setShortedBacktests(res => [ ...res, selectedBacktests[i] ]);
                            }} />,
                },
                {
                    title: 'Append',
                    value: <>
                        <FaPlusCircle size={20} 
                            color={appendTs.find(x => x === selectedBacktests[i]._id) ? colors.green : colors.blue} 
                            onClick={() => {
                                setAppend(res => {
                                    if(res.find(x => x === selectedBacktests[i]._id)) {
                                        return res.filter(t => t !== selectedBacktests[i]._id);
                                    }
                                    return [ ...res, selectedBacktests[i]._id ];
                                
                                });
                            }} />
                    </>,
                },
                {
                    title: 'Remove',
                    value: <FaMinusCircle name="minuscircleo" size={20} color="black" onClick={() => {
                        setSelectedBacktests(selectedBacktests.filter(t => t._id !== selectedBacktests[i]._id));
                    }} />,
                },
            ],
        });
    }

    const convertSeriesIfShort = (series: Series[], isShort: boolean) => {
        if(isShort) {
            return series.map(x => {
                return {
                    ...x,
                    R: -1*x.R,
                };
            });
        }
        return series;
    };

    const handleToggle = () => {
        setIsRelative(!isRelative);
    };

    return (
        <div style={{  height: '100vh' }}>

            <Flex mt={4} p={2}>
                <Box flex="1" p="4"   bg={'whiteAlpha.100'}
                    borderRadius="md"
                    marginInlineEnd={10}
                    boxShadow="md">
                    <LazyLoading>
                        <>
                            <Heading size="md">Available backtests</Heading>
                            <SelectBacktests
                                useNiceBacktestDisplay={true}
                                selectedBacktests={selectedBacktests}
                                onSelect={(backtest) => {
                                    // check if not already selected
                                    if(selectedBacktests.find(x => x._id === backtest._id)) {
                                        return;
                                    }
                                    const status = new StatusDisplayDTO("Successfully selected backtest", "success");
                                    dispatch(updateMessage(status));
                                    setSelectedBacktests(res => [ ...res, backtest ]);
                                }}
                            />
                        </>
                    </LazyLoading>
                </Box>

                <Box flex="2" p="4"   bg={'whiteAlpha.100'}
                    borderRadius="md"
                    marginInlineEnd={10}
                    boxShadow="md">
                    <LazyLoading>
                        <>
                            <Box bg='tomato' w='100%' p={4} color='white' mt={4} mb={4}>
                                <strong>PAST PERFORMANCE IS NO GUARANTEE OF FUTURE RESULTS.</strong>
                            </Box>
                            <ExplainerInfo.Information 
                                title="What is Portfolio Allocation?"
                                text={
                                    <div>
                                        <p>Portfolio allocation is the process of selecting the optimal weights of assets to be held in a portfolio, and is a key step in the portfolio management process. The goal of portfolio allocation is to maximize the expected return of a portfolio for a given level of risk, and to reduce the risk for a given expected return.</p>
                                        <p>On our platform, you can use your backtesting results to weight your portfolio. The portfolio allocation is done per strategy, allowing you to tailor the asset weights based on the performance and risk profile of each strategy.</p>
                                        <div>
                                            <br />
                                            <p>In portfolio allocation, time can often be neglected when selecting strategies with a positive outlook from today into the next N steps into the future. This is because we assume that the strategies occur in the present and will play out similarly to how they have in the historical data.</p>
                                            <p>Despite the historical data for these strategies being from different time periods, the assumption is that their past performance is indicative of their future performance. This approach allows for the selection of strategies based on their expected positive outcomes, regardless of their specific historical time frames.</p>
                                            <p>However, it is always best to select strategies that have a large number of observations and are as up-to-date as possible. More recent and frequent data points increase the reliability and relevance of the strategies' performance, leading to more informed and effective portfolio allocation decisions.</p>
                                            <strong>PAST PERFORMANCE IS NO GUARANTEE OF FUTURE RESULTS.</strong>
                                        </div>
                                    </div>
                                }
                            />

                            <FormControl display="flex" alignItems="center" mb={4}>
                                <FormLabel htmlFor="relative-toggle" mb="0">
                                        Normalize & divide each portfolio's return by comparison series (at the strategy time t) when allocating
                                </FormLabel>
                                <Switch id="relative-toggle" isChecked={isRelative} onChange={handleToggle} />
                            </FormControl>
                            {modernPortfolioAllocationButtons(
                                selectedBacktests.map(x => x.historicalR).map((x, i) => convertSeriesIfShort(x, shortedBacktests.some(t => t._id === selectedBacktests[i]._id))),
                                selectedBacktests.map(x => x.name),
                                selectedBacktests.map(x => x.comparisonSeries.map(e => e.R)).flat())}
                            <div>
                                <Button onClick={() => {
                                    calculatePortfolioReturn();
                                }}>Calculate portfolio return</Button>
                                {timeSeries?.length > 0 && <>
                                    <Heading size="md">Time series</Heading>
                                    <LineChartPretty TimeSeriesDTOs={timeSeries} shouldDisplayPreviewMethod={true} transform={true} xaxisType='numeric' />
                                </>}
                                <div style={{ width: '100%', flexDirection: 'row' }}>
                                    <div style={{ flex: 1 }}>
                                        {pieChartInformation && <>
                                            <Heading size="md">Pie chart</Heading>
                                            <PieChart pieChart={pieChartInformation} />
                                        </>}
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        {pieChartInformation && <>
                                            <Heading size="md">Your portfolio:</Heading>
                                            <>
                                                {pieChartInformation.values.map((x, i) => {
                                                    if(x === 0){
                                                        return null;
                                                    }
                                                    return <>
                                                        <Divider/>
                                                        <Flex>
                                                            <Box flex="4"  p={4}>
                                                                <List spacing={3}>
                                                                    {[ portfolioBacktests[i] ].map((backtest) =>
                                                                        backtest.historicalPortfolios[backtest.historicalPortfolios.length - 1].companies.map((company, index) => (
                                                                            <ListItem key={index}>
                                                                                <Flex align="center">
                                                                                    <Image src={company.image} h={10} />
                                                                                    <Box>
                                                                                        <Text fontWeight="bold">{company.symbol}</Text>
                                                                                        <Text>{company.fullName}</Text>
                                                                                    </Box>
                                                                                </Flex>
                                                                            </ListItem>
                                                                        ))
                                                                    )}
                                                                </List>
                                                            </Box>
                                                            <Box flex="1" bg={pieChartColors[i]} p={4}>
                                                                <Heading size="md" color={'black'}>{pieChartInformation.labels[i]}</Heading>
                                                                <Heading size="md" color={'black'}>{round(pieChartInformation.values[i]*100, 2)}%</Heading>
                                                            </Box>
                                                        </Flex>
                                                    </>;
                                                })}
                                            </>
                                        </>}
                                    </div>
                                </div>
                            </div>
                        </>
                    </LazyLoading>
                </Box>

                <Box flex="1" p="4"   bg={'whiteAlpha.100'}
                    borderRadius="md"
                    marginInlineEnd={10}
                    boxShadow="md">
                    <LazyLoading>
                        <>
                            <Heading size="md">Selected backtests</Heading>
                            <TableComponent rows={selectedBacktestsRow} />
                            <ExplainerInfo.Information 
                                title='What is append?'
                                text={
                                    <p>
                                        This will append the selected portfolios' returns horizontally into a single, extended time series.
                                    </p>
                                } 
                            />
                            <br></br>
                            <Button onClick={() => {
                                setSelectedBacktests(res => {
                                    res = res.filter(t => !appendTs.includes(t._id));
                                    const newBacktest = cloneDeep(selectedBacktests.find(t => appendTs.includes(t._id)));
                                    const allBacktestMerge = selectedBacktests.filter(t => appendTs.includes(t._id));
                                    const lastPortfolios = allBacktestMerge.map(e => e.historicalPortfolios[e.historicalPortfolios.length - 1]);
                                    const lastPortfolio = new Portfolio(lastPortfolios.map(e => e.companies).flat(), lastPortfolios[0].date);
                                    newBacktest.historicalPortfolios = allBacktestMerge.map(t => t.historicalPortfolios).flat();
                                    newBacktest.historicalPortfolios[newBacktest.historicalPortfolios.length - 1] = lastPortfolio;
                                    newBacktest.historicalR = allBacktestMerge.map(t => t.historicalR).flat();
                                    newBacktest.comparisonSeries = allBacktestMerge.map(t => t.comparisonSeries).flat();
                                    return [ ...res, newBacktest ];
                                });
                                setAppend([]);
                            }}>Append</Button>
                            <Divider/>
                            <Heading size="md" mt={10} mb={-10}>Recent news backtests</Heading>
                            <SelectBacktests
                                useNiceBacktestDisplay={true}
                                selectedBacktests={selectedBacktests.filter(b => !appendTs.includes(b._id))}
                                useNewsBacktests={true}
                                onSelect={(backtest) => {
                                // check if not already selected
                                    if(selectedBacktests.find(x => x._id === backtest._id)) {
                                        return;
                                    }
                                    const status = new StatusDisplayDTO("Successfully selected backtest", "success");
                                    dispatch(updateMessage(status));
                                    setSelectedBacktests(res => [ ...res, backtest ]);
                                }}
                            />
                        </>
                    </LazyLoading>
                </Box>
            </Flex>

        </div>
    );

    function calculatePortfolioReturn(){
        const allBacktests = selectedBacktests;
        const allHistoricalR = allBacktests
            .map(x => {
                if(shortedBacktests.map(d => d._id).includes(x._id)){
                    x.historicalR = x.historicalR.map(e => {
                        return {
                            ...e,
                            R: -1*e.R,
                        };
                    });
                }
                return x;
            })
            .map(x => x.historicalR);
        const allDates = allHistoricalR.map(x => x.map(e => new Date(e.date.label))).flat();
        const maxDate = new Date(Math.max(...allDates.map(x => Math.min(x.getTime()))));
        const minDate = new Date(Math.min(...allDates.map(x => Math.max(x.getTime()))));
        // make all time series the same length add a empty Series object
        // make a array of dates between min and max date
        let rangeDates = generateDateArray(maxDate, minDate, "day");
        rangeDates = rangeDates.sort((a, b) => a.getTime() - b.getTime());
        const allNewHistoricalR: Series[][] = [];
        for(let i = 0; i < allHistoricalR.length; i++) {
            for(let j = 0; j < rangeDates.length; j++) {
                if(allNewHistoricalR[i] == null){
                    allNewHistoricalR[i] = [];
                }
                const labelDate = moment(rangeDates[j]).format("YYYY-MM-DD");
                const obs = allHistoricalR[i].find(x => x.date.label === labelDate);
                if(obs == null) {
                    const d = new ImpreemDate(-1, labelDate);
                    allNewHistoricalR[i][j] = new Series(d, null);
                }else{
                    allNewHistoricalR[i][j] = obs;
                }
            }
        }
        const portfolioLongTermR: number[] = [];
        for(let i = 0; i < allNewHistoricalR[0].length; i++) {
            const stepHistorical = i - 1;
            const stepNow = i;
            if(stepNow <= 21){
                continue;
            }
            const allTimeSeriesThatShouldBeIncluded: number[] = [];
            for(let n = 0; n < allNewHistoricalR.length; n++) {
                const cleanedSeries = allNewHistoricalR[n].slice(0, stepHistorical).map(x => x.R).filter(x => x != null);
                let ok = true;
                if(cleanedSeries.length < 21){
                    ok = false;
                }
                const todayValue = allNewHistoricalR[n][stepNow].R;
                if(todayValue == null){
                    ok = false;
                }
                if(ok){
                    allTimeSeriesThatShouldBeIncluded.push(n);
                }
            }
            let historicalSeries = allNewHistoricalR.filter((x, index) => allTimeSeriesThatShouldBeIncluded.includes(index)).map(x => x.slice(0, stepHistorical)).map(x => x.filter(e => e.R != null));
            // make historical series the same length
            const minLen = Math.min(...historicalSeries.map(x => x.length));
            historicalSeries = historicalSeries.map(x => x.slice(x.length - minLen));
            if(historicalSeries.length > 0){
                const weights = timeSeriesService.allocate(historicalSeries, methodSelected.length === 0 ? 'SHARPE' : methodSelected);
                const futureStepR = allNewHistoricalR.filter((x, index) => allTimeSeriesThatShouldBeIncluded.includes(index)).map(x => x[stepNow].R != null ? x[stepNow].R : 0);
                const portfolioR = futureStepR.map((x, i) => {
                    const n = x;
                    const weight = weights.weights[i];
                    return n * weight;
                }).reduce((a, b) => a + b, 0);
                portfolioLongTermR.push(portfolioR);
            }
        }
        // create a fake timeseriesDto and setTimeseries
        const timeSeriesDto: TimeSeriesDTO = new TimeSeriesDTO();
        timeSeriesDto.graphValue = portfolioLongTermR.map((x, i) => {
            const dto = new TimeSeriesHelperValue();
            dto.d = rangeDates[i];
            dto.date = moment(rangeDates[i]).format("YYYY-MM-DD");
            dto.value = x;
            return dto;
        });
        setTimeSeries([ timeSeriesDto ]);
        return portfolioLongTermR;
    }
    
    function modernPortfolioAllocationButtons(
        timeSeries: Series[][],
        timeSeriesNameLabels: string[],
        comparisonSeries: Series[][],

    ) {
        const allocationMethods = [
            { name: "MVO", displayName: "Mean Variance Optimization" },
            { name: "GMV", displayName: "Global Minimum Variance" },
            { name: "SHARPE", displayName: "Maximum Sharpe Ratio" },
        ];

        const handleButtonClick = async(method) => {
            if(selectedBacktests.length < 2) {
                const status = new StatusDisplayDTO("Cannot run portfolio allocation, please select at least 2 backtests", "error");
                dispatch(updateMessage(status));
                return;
            }
    
            setIsLoading(true);
            setTimeout(async() => {
                try {
                    setMethodSelected(method);
                    const res = await timeSeriesService.getAllocations(method, timeSeries, timeSeriesNameLabels, comparisonSeries, undefined, undefined, isRelative);
                    setPortfolioBacktests(cloneDeep(selectedBacktests));
                    setPieChartInformation(res.pieChartDto);
                    setEvaluationMetrics(res.evaluationMetrics);
                    // const series = menuCategory.find(t => t.categoryTitle === "Indexes").items.find(x => x.transformationKey.includes(backtest.comparisonIndex[0]));
                    // const comparisionSeries = cloneDeep(await getTimeSeries([], series, new TimeSeriesResultsManager(), dispatch, graphItems, backtest, undefined, true));
                    // const g = growth(comparisionSeries[0].timeSeries.graphValue.map(x => x.value));
                    // const len = res.timeSeries[0].graphValue.length;
                    // comparisionSeries[0].timeSeries.graphValue = comparisionSeries[0].timeSeries.graphValue.map((x, i) => {
                    //     if(comparisionSeries[0].timeSeries.graphValue.length - i > len){
                    //         return null;
                    //     }
                    //     x.value = g[i];
                    //     return x;
                    // }).filter(x => x != null);
                    // setTimeSeries([ res.timeSeries[0] as any, comparisionSeries[0].timeSeries ]);
                    // const ttestValue = await getTtest([ res.timeSeries[0], comparisionSeries[0].timeSeries ]);
                    // setTTest(ttestValue);
                } catch (error) {
                    // error handling
                    console.log(error);
                } finally {
                    setIsLoading(false);
                }
            }, 1500);
        };
    
        return (
            <>
                <Heading size="md" mt={3} mb={3}>Pick a portfolio allocation method</Heading>
                {allocationMethods.map(({ name, displayName }) => (
                    <Button
                        key={name}
                        mr={2}
                        color={methodSelected?.includes(name) ? "grey" : undefined}
                        onClick={() => handleButtonClick(name)}
                    >
                        {displayName}
                    </Button>
                ))}
            </>
        );
    }
    
}

