import {
    Button,
    Container,
    Tooltip,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { PiCheckCircle, PiX } from 'react-icons/pi';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveGraphProperties } from '../../action-creators/graph-items/graph-items';
import { Backtest, GraphItem } from '../../backtesting-common-frontend';
import { isBacktestLicense } from '../../backtesting-common-frontend/http-utilities/auth/auth-http-utilities';
import { readGraphItems } from '../../backtesting-common-frontend/http-utilities/http-utilities/graph-items/graph-items.http';
import { calculateDickeyFullerTest, updateTimeseries } from '../../backtesting-common-frontend/http-utilities/http-utilities/time-series/time-series-backend.service';
import { cloneDeep } from '../../backtesting-common-frontend/shared/utilites/object.utilities';
import { TimeSeriesResultsManager } from '../../managers/time-series/time-series-manager';
import { setLoading } from '../../store/backtests/backtests';
import { bulkAddAndOverwriteGraphItemRedux, setEditActive, setTheoryActive } from '../../store/graphitems/graphitems';
import { AppState } from '../../store/store';
import { updateNavigationTo } from '../../store/tabs/tabs';
import { ColorsLight } from '../shared/constants/Colors';
import { RowTableDTO } from '../table';
import TableComponent from '../table/table';

export default function SelectedTimeSeries({ isOnTheory }: {isOnTheory?: boolean}) {
    const dispatch = useDispatch();
    const selectedSymbols = useSelector(
        (state: AppState) => state.symbols.selectedSymbols
    );
    const theoryActiveCtx = useSelector((state: AppState) => state.graphItems.theoryActive);
    const graphItems = useSelector((state: AppState) => state.graphItems.graphItems);
    const backtestCtx = useSelector((state: AppState) => state.backtests.backtest);
    const [ backtest, setBacktest ] = useState<Backtest | null>(null);
    const [ timeSeriesResultsManager ] = useState<TimeSeriesResultsManager>(new TimeSeriesResultsManager());
    const [ theoryActive, setTheoryActiveState ] = useState<string[]>([]);
    const [ colors ] = useState<ColorsLight>(new ColorsLight());

    useEffect(() => {
        setBacktest(cloneDeep(backtestCtx));
    }, [ backtestCtx ]);

    useEffect(() => {
        setTheoryActiveState(cloneDeep(theoryActiveCtx));
    }, [ theoryActiveCtx ]);

    const setDickeyFullerTest = async(graphItem: GraphItem) => {
        dispatch(setLoading(true));
        calculateDickeyFullerTest({ timeseries: graphItem.timeSeries.graphValue.map(e => e.value).slice(-200) }).then((res) => {
            if(res != null){
                updateTimeseries(graphItem.timeSeries, [ { name: "stationary", value: res <= 0.05 } ]).then(() => {
                    dispatch(setLoading(true));
                    readGraphItems().then(res => {
                        if(res){
                            dispatch(bulkAddAndOverwriteGraphItemRedux(res));
                            setActiveGraphProperties(res, dispatch);
                        }
                    }).catch(() => {
                        // empty
                    }).finally(() => {
                        dispatch(setLoading(false));
                    });
                });
            }
        }).finally(() => {
            dispatch(setLoading(false));
        });
    };

    const rows: RowTableDTO[] = [];
    for(let i = 0; i < graphItems.length; i++){
        if(!backtest){
            continue;
        }
        rows.push({
            columns: [
                isOnTheory ? {
                    title: i === 0 ? "View" : null,
                    value: <>
                        {graphItems[i]._id == null && isBacktestLicense() ? <Button size="xs" colorScheme="teal" mr={2} onClick={() => {
                            timeSeriesResultsManager.addGraphItem(
                                selectedSymbols,
                                dispatch,
                                graphItems[i].timeSeries,
                                backtest,
                                graphItems,
                                () => {
                                    // empty
                                },
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                true,
                                graphItems[i]
                            );
                        }}>Save</Button> : null}
                        {graphItems[i]._id != null || !isBacktestLicense() ? <Button size="xs" colorScheme={!theoryActive.includes(graphItems[i]._id) ? 'blue' : undefined}
                            color={theoryActive.includes(graphItems[i]._id) ? graphItems[i]?.color ?? 'black' : 'black'} onClick={() => {
                                const active = cloneDeep(theoryActive) ?? [];
                                // check if already exists then remove it
                                if(active.includes(graphItems[i]._id)){
                                    const index = active.indexOf(graphItems[i]._id);
                                    active.splice(index, 1);
                                    dispatch(setTheoryActive(active));
                                    return;
                                }
                                active.push(graphItems[i]._id);
                                // remove duplicates
                                const unique = [ ...new Set(active) ];
                                dispatch(setTheoryActive(unique));
                            }}>{theoryActive.includes(graphItems[i]._id) ? 'Close' : 'View'}</Button> : null}
                    </>,
                } : undefined,
                {
                    title: i === 0 ? "Name" : null,
                    value: <>
                        {graphItems[i].timeSeries.display}
                    </>,
                },
                {
                    title: i === 0 ? "Transformation" : null,
                    value: <Button size="xs" colorScheme="blue" onClick={() => {
                        dispatch(setEditActive(graphItems[i]._id));
                        dispatch(updateNavigationTo("/transformations"));
                    }}>Transform</Button>,
                },
                {
                    title: i === 0 ? "Stationary": null,
                    value: <Tooltip label={'Run Dickey Fuller Test'}>
                        <Button size="xs"  onClick={() => {
                            setDickeyFullerTest(graphItems[i]);
                        }}>{graphItems[i].timeSeries.stationary ? <PiCheckCircle color={colors.green} size={25} /> : <PiX size={25} />}</Button>
                    </Tooltip>,
                },
                {
                    title: i === 0 ? "Remove" : null,
                    value: <Button size="xs" colorScheme="red" onClick={() => {
                        if(backtest){
                            timeSeriesResultsManager.removeGraphItem(graphItems, graphItems[i].timeSeries, backtest, false, dispatch);
                        }
                    }}>Remove</Button>,
                },
            ],
        });
    }

    return (
        <Container width={'full'} p={3}>
            <TableComponent rows={rows} />
        </Container>
    );
}
