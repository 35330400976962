import { Box, Checkbox, Container, Divider, Flex, HStack, Heading, Input, Stack, Text, chakra, useColorModeValue } from "@chakra-ui/react";
import React, { useState } from "react";
import { FaCalendar } from "react-icons/fa";
import { ImpreemDate } from "../../backtesting-common-frontend/date/impreem-date";
import { env } from "../../backtesting-common-frontend/env";
import { getLastDate } from "../../backtesting-common-frontend/http-utilities/http-utilities/dates/dates.http.service";

const routerUrl = env.routerUrl;

export default function DatesController({ title,
    onDateChange,
    useTest,
    defaultFrom,
    defaultTo,
    defaultTestFrom,
    defaultTestTo,
    onTestDateChange,
    defaultUseLatest,
    defaultUseLatestTest,
}: {
    title: string,
    onDateChange: (from: string, to: string, useLatest: boolean) => void,
    onTestDateChange?: (from: string, to: string, useLatest: boolean) => void,
    useTest?: boolean,
    defaultFrom: string,
    defaultTo: string,
    defaultTestFrom?: string,
    defaultTestTo?: string,
    defaultUseLatest: boolean,
    defaultUseLatestTest?: boolean
}) {
    const [ from, setFrom ] = useState<string>(defaultFrom);
    const [ to, setTo ] = useState<string>(defaultTo);
    const [ fromTest, setFromTest ] = useState<string>(defaultTestFrom);
    const [ toTest, setToTest ] = useState<string>(defaultTestTo);
    const [ useLatest, setUseLatest ] = useState<boolean>(defaultUseLatest);
    const [ useLatestTest, setUseLatestTest ] = useState<boolean>(defaultUseLatestTest ?? false);
    const [ latestDate, setLatestDate ] = useState<ImpreemDate | null>(null);

    const getLatestDateCb = React.useCallback(async() => {
        const latest = await getLastDate(routerUrl);
        setLatestDate(latest);
    }, []);

    React.useEffect(() => {
        setFrom(defaultFrom);
        setTo(defaultTo);
        setFromTest(defaultTestFrom);
        setToTest(defaultTestTo);
        setUseLatest(defaultUseLatest);
        setUseLatestTest(defaultUseLatestTest);
    }, [ defaultFrom, defaultTo, defaultTestFrom, defaultTestTo, defaultUseLatest, defaultUseLatestTest ]);

    React.useEffect(() => {
        getLatestDateCb();
    }, [ getLatestDateCb ]);

    const getToComponent = (title: string, isTest: boolean) => {
        return (
            <div style={{ width: '100%', flexDirection: 'row' }}>
                <div style={{ flex: 1 / 4 }}>
                    <chakra.h1 fontSize="xl" fontWeight="bold">{title}
                    </chakra.h1>
                </div>
                <div style={{ flex: 3 / 4 }}>
                    <Flex alignItems="center">
                        <Text fontSize="sm" mr={3}>Use latest date available</Text>
                        <Checkbox
                            isChecked={!isTest ? useLatest : useLatestTest}
                            onChange={() => {
                                if (!isTest) {
                                    setUseLatest(!useLatest);
                                    onDateChange(from, to, !useLatest);
                                } else {
                                    setUseLatestTest(!useLatestTest);
                                    onTestDateChange(fromTest, to, !useLatestTest);
                                }
                            }}
                        />
                    </Flex>
                </div>
            </div>
        );
    };

    return (
        <Box width="100%" alignItems={'center'} justifyContent={'center'}>
            <Container maxW="5xl" p={{ base: 5, md: 6 }}>
                <Stack
                    w="17rem"
                    spacing={2}
                    p={4}
                    border="1px solid"
                    borderColor={useColorModeValue('gray.400', 'gray.600')}
                    rounded="md"
                    margin="0 auto"
                    _hover={{
                        boxShadow: useColorModeValue(
                            '0 4px 6px rgba(160, 174, 192, 0.6)',
                            '0 4px 6px rgba(9, 17, 28, 0.4)'
                        ),
                    }}
                >
                    <Heading as="h1" size="lg" mb={4} alignItems={'center'} justifyContent={'center'}>
                        {title}
                    </Heading>
                    <HStack justifyContent="space-between" alignItems="baseline">
                        <Box pos="relative">
                        </Box>
                        <FaCalendar size={20} color={useColorModeValue('black', 'white')} />
                    </HStack>
                    <chakra.h1 fontSize="xl" fontWeight="bold">
                        {useTest ? "Train from" : "From"}
                    </chakra.h1>
                    <Box flex={1}>
                        <Input
                            type="date"
                            value={from}
                            onChange={(e) => {
                                setFrom(e.target.value);
                                onDateChange(e.target.value, to, useLatest);
                            }}
                        />
                    </Box>
                    <Divider />
                    <chakra.h1 fontSize="xl" fontWeight="bold">
                        {useTest ? "Train to" : getToComponent("To", false)}
                    </chakra.h1>
                    <Box flex={1}>
                        <Input
                            type="date"
                            value={to}
                            onChange={(e) => {
                                let uL = useLatest;
                                if(e.target.value !== latestDate.label){
                                    uL = false;
                                    setUseLatest(false);
                                }
                                setTo(e.target.value);
                                onDateChange(from, e.target.value, uL);
                            }}
                        />
                    </Box>
                </Stack>
            </Container>
    
            {useTest && (
                <>
                    <Flex width="100%" flexDirection="row" alignItems="center" mb={4}>
                        <Heading as="h2" size="md" mr={2}>
                Test from
                        </Heading>
                        <Box flex={1}>
                            <Input
                                type="date"
                                value={fromTest}
                                onChange={(e) => {
                                    setFrom(e.target.value);
                                    onDateChange(e.target.value, to, useLatest);
                                }}
                            />
                        </Box>
                    </Flex>
                    <Flex width="100%" flexDirection="row" alignItems="center" mb={4}>
                        <Heading as="h2" size="md" mr={2}>
                Test to
                        </Heading>
                        <Box flex={1}>
                            <Input
                                type="date"
                                value={toTest}
                                onChange={(e) => {
                                    setTo(e.target.value);
                                    onDateChange(from, e.target.value, useLatest);
                                }}
                            />
                        </Box>
                    </Flex>
                </>
            )}
        </Box>
    );

}
