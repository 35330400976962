import {
    Button,
    Checkbox,
    Container,
    Heading,
    Input,
    VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StopLossTakeProfit } from '../../backtesting-common-frontend/database/stoplosstakeprofits';
import { StopLossTakeProfitHttp } from '../../backtesting-common-frontend/http-utilities/http-utilities/stoplosstakeprofits/stoplosstakeprofits.http';
import { AppState } from '../../store/store';

export default function StopLossTakeProfitMethod() {
    const dispatch = useDispatch();
    const backtestCtx = useSelector((state: AppState) => state.backtests.backtest);
    const [ stopLoss, setStopLoss ] = useState<string>("5");
    const [ takeProfit, setTakeProfit ] = useState<string>("20");
    const [ isStopLossEnabled, setIsStopLossEnabled ] = useState<boolean>(false);
    const [ isTakeProfitEnabled, setIsTakeProfitEnabled ] = useState<boolean>(false);

    const add = () => {
        if (isStopLossEnabled || isTakeProfitEnabled) {
            const model = new StopLossTakeProfit();
            if (isStopLossEnabled) {
                model.stopLossProcentageChange = parseFloat(stopLoss);
            }
            if (isTakeProfitEnabled) {
                model.takeProfitProcentageChange = parseFloat(takeProfit);
            }
            model.backtestId = backtestCtx._id as any;
            StopLossTakeProfitHttp.create(model, dispatch).then(() => {
                alert("Stop loss and/or take profit added successfully");
                setStopLoss("5");
                setTakeProfit("20");
            });
        } else {
            alert("Please enable either stop loss or take profit settings before adding.");
        }
    };

    return (
        <Container width={'full'} p={3}>
            <Heading as="h3" size="md">Stop Loss / Take Profit</Heading>
            <p>Stop loss and take profit are applied to each asset in the portfolio.</p>
            <VStack spacing={4} align="stretch">
                <Checkbox 
                    isChecked={isStopLossEnabled} 
                    onChange={(e) => setIsStopLossEnabled(e.target.checked)}
                >
                    Enable Stop Loss
                </Checkbox>
                {isStopLossEnabled && (
                    <>
                        <span>Stop loss (percentage change %)</span>
                        <Input 
                            type="string" 
                            value={stopLoss} 
                            margin={2} 
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                if (/^-?\d*\.?\d*$/.test(inputValue)) {
                                    setStopLoss(inputValue);
                                }
                            }} 
                        />
                    </>
                )}
                <Checkbox 
                    isChecked={isTakeProfitEnabled} 
                    onChange={(e) => setIsTakeProfitEnabled(e.target.checked)}
                >
                    Enable Take Profit
                </Checkbox>
                {isTakeProfitEnabled && (
                    <>
                        <span>Take profit (percentage change %)</span>
                        <Input 
                            type="string" 
                            value={takeProfit} 
                            margin={2} 
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                if (/^-?\d*\.?\d*$/.test(inputValue)) {
                                    setTakeProfit(inputValue);
                                }
                            }} 
                        />
                    </>
                )}
            </VStack>
            <Button colorScheme={'blue'} onClick={add}>Add</Button>
        </Container>
    );
}
